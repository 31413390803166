import {Button} from "@mui/material";
import {Link} from "react-router-dom";
import "./styles.scss";
import Emergence from "../Welcome/components/Emergence";

export default function Help() {
    return (
        <div className={"page-help"}>
            <h1>Precisa de ajuda?</h1>

            <Button component={Link} to={"https://api.whatsapp.com/send?1=pt_BR&phone=5585985695536&text=Oi,+preciso+de+ajuda"} fullWidth size={"large"} variant={"contained"} color={"success"}>Falar com minha fisioterapeuta</Button>
            <Button component={Link} to={"https://maps.app.goo.gl/EXxzTnN5HNGcT3g46"} fullWidth size={"large"} variant={"contained"} color={"primary"}>Ir pro Hospital</Button>
            <Button component={Link} to={"tel:190"} fullWidth size={"large"} variant={"contained"} color={"error"}>Ligar pra emergencia</Button>

            <Emergence/>
        </div>
    )
}