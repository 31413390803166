import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {Route, Routes} from "react-router";
import {tutorialIsViewed} from "../storage/Tutorial";
import Welcome from "../pages/Welcome";
import Help from "../pages/Help";
import Paper from "@mui/material/Paper";
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import {Home as HomeIcon, Person} from "@mui/icons-material";
import HelpIcon from "@mui/icons-material/Help";
import Box from "@mui/material/Box";

const About = React.lazy(() => import("../pages/About/"));
const Blog = React.lazy(() => import("../pages/Blog/"));
const Contact = React.lazy(() => import("../pages/Contact/"));
const Faq = React.lazy(() => import("../pages/Faq/"));
const Home = React.lazy(() => import("../pages/Home/"));
const Login = React.lazy(() => import("../pages/Login/"));
const References = React.lazy(() => import("../pages/References/"));
const Tutorial = React.lazy(() => import("../pages/Tutorial/"));
const Testimonials = React.lazy(() => import("../pages/Testimonials/"));
const Profile = React.lazy(() => import("../pages/Profile/"));
const FootCare = React.lazy(() => import("../pages/FootCare/"));
const MichiganForm = React.lazy(() => import("../pages/MichiganForm/"));
const MichiganResult = React.lazy(() => import("../pages/MichiganForm/Result/"));
const Quiz = React.lazy(() => import("../pages/Quiz/"));
const Register = React.lazy(() => import("../pages/Register/"));

export default function AppRoutes(props) {
    const location = useLocation();
    const navigate = useNavigate();
    const [value, setValue] = React.useState(location.pathname === '/' ? 0 : 1);

    if (true !== tutorialIsViewed() && '/tutorial' !== location.pathname) {
        return (
            <Routes>
                <Route path={"/"} exact element={<Tutorial/>}/>
            </Routes>
        )
    }

    return (
        <Box sx={{ width: '100%' }}>
            {(value === 1 || location.pathname !== '/') && <Routes>
                <Route path={"/"} exact element={<Home/>}/>
                <Route path={"/faq"} exact element={<Faq/>}/>
                <Route path={"/tutorial"} exact element={<Tutorial/>}/>
                <Route path={"/referencias"} exact element={<References/>}/>
                <Route path={"/quiz"} exact element={<Quiz/>}/>
                <Route path={"/depoimentos"} exact element={<Testimonials/>}/>
                <Route path={"/blog"} exact element={<Blog/>}/>
                <Route path={"/sobre"} exact element={<About/>}/>
                <Route path={"/contato"} exact element={<Contact/>}/>
                <Route path={"/login"} exact element={<Login/>}/>
                <Route path={"/perfil"} exact element={<Profile/>}/>
                <Route path={"/cuidados"} exact element={<FootCare/>}/>
                <Route path={"/michigan-form"} exact element={<MichiganForm/>}/>
                <Route path={"/michigan-form/resultado"} exact element={<MichiganResult/>}/>
                <Route path={"/cadastro"} exact element={<Register/>}/>
            </Routes>}
            {value === 0 && location.pathname === '/' && <Welcome/>}
            {value === 2 && location.pathname === '/' && <Help/>}

            <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }} elevation={3}>
                <BottomNavigation
                    showLabels
                    value={value}
                    onChange={(event, newValue) => {
                        navigate('/');
                        setValue(newValue);
                    }}
                >
                    <BottomNavigationAction label="Inicio" icon={<HomeIcon />} />
                    <BottomNavigationAction label="Meu Espaço" icon={<Person />} />
                    <BottomNavigationAction label="Ajuda" icon={<HelpIcon />} />
                </BottomNavigation>
            </Paper>
        </Box>

    )
}
