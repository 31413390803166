import React, {Suspense} from 'react';
import {BrowserRouter} from "react-router-dom";
import { ThemeProvider } from '@mui/material/styles';
import AppRoutes from "./config/AppRoutes";
import {theme} from "./config/Theme";
import Loading from "./components/Loading";

function App() {
    return (
      <ThemeProvider theme={theme}>
        <BrowserRouter>
          <Suspense fallback={<Loading/>}>
            <AppRoutes/>
          </Suspense>
        </BrowserRouter>
      </ThemeProvider>
    );
}

export default App;
