import {format} from "date-fns";

export default function RegisterStorage() {

}

export const save = (data) => {
    data.createdAt = format(new Date(), "dd/MM/Y HH:mm:ss");

    localStorage.setItem('pedabete_account', JSON.stringify(data));
}

export const hasLoggedUser = () => {
    return localStorage.getItem('pedabete_account');
};

export const user = () => {
    let data = localStorage.getItem('pedabete_account');

    return JSON.parse(data);
}

export const logout = () => {
    localStorage.removeItem('pedabete_account');
    localStorage.removeItem('michigan');
    localStorage.removeItem('quiz');
}