import React from "react";

import "./styles.scss";

export default function Medical01() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            id="freepik_stories-medicine"
            className="animated"
            version="1.1"
            viewBox="0 0 500 500"
        >
            <g
                id="freepik--background-complete--inject-3"
                style={{
                    WebkitTransformOrigin: 250,
                    MsTransformOrigin: 250,
                    transformOrigin: 250,
                }}
                className="animable animator-hidden"
            >
                <path
                    style={{
                        WebkitTransformOrigin: 250,
                        MsTransformOrigin: 250,
                        transformOrigin: 250,
                    }}
                    id="elredr000td9g"
                    fill="#EBEBEB"
                    d="M0 382.4H500V382.65H0z"
                    className="animable"
                ></path>
                <path
                    style={{
                        WebkitTransformOrigin: 161.63,
                        MsTransformOrigin: 161.63,
                        transformOrigin: 161.63,
                    }}
                    id="el4da2irb6eeb"
                    fill="#EBEBEB"
                    d="M150.04 388.78H173.22V389.03H150.04z"
                    className="animable"
                ></path>
                <path
                    style={{
                        WebkitTransformOrigin: 430.435,
                        MsTransformOrigin: 430.435,
                        transformOrigin: 430.435,
                    }}
                    id="el7x4hxnes59n"
                    fill="#EBEBEB"
                    d="M402.1 394.14H458.77000000000004V394.39H402.1z"
                    className="animable"
                ></path>
                <path
                    style={{
                        WebkitTransformOrigin: 71.055,
                        MsTransformOrigin: 71.055,
                        transformOrigin: 71.055,
                    }}
                    id="elf3h1k3m8em"
                    fill="#EBEBEB"
                    d="M42.72 394.14H99.39V394.39H42.72z"
                    className="animable"
                ></path>
                <path
                    d="M237 337.8H43.91a5.71 5.71 0 01-5.7-5.71V60.66a5.71 5.71 0 015.7-5.66H237a5.71 5.71 0 015.71 5.71v271.38a5.71 5.71 0 01-5.71 5.71zM43.91 55.2a5.46 5.46 0 00-5.45 5.46v271.43a5.46 5.46 0 005.45 5.46H237a5.48 5.48 0 005.46-5.46V60.66A5.48 5.48 0 00237 55.2z"
                    style={{
                        WebkitTransformOrigin: 140.46,
                        MsTransformOrigin: 140.46,
                        transformOrigin: 140.46,
                    }}
                    id="el9i8i358y4df"
                    fill="#EBEBEB"
                    className="animable"
                ></path>
                <path
                    d="M453.31 337.8h-193.1a5.73 5.73 0 01-5.71-5.71V60.66a5.72 5.72 0 015.71-5.66h193.1a5.71 5.71 0 015.69 5.66v271.43a5.7 5.7 0 01-5.69 5.71zM260.21 55.2a5.48 5.48 0 00-5.46 5.46v271.43a5.48 5.48 0 005.46 5.46h193.1a5.48 5.48 0 005.46-5.46V60.66a5.48 5.48 0 00-5.46-5.46z"
                    style={{
                        WebkitTransformOrigin: 356.75,
                        MsTransformOrigin: 356.75,
                        transformOrigin: 356.75,
                    }}
                    id="elldzyef4r3he"
                    fill="#EBEBEB"
                    className="animable"
                ></path>
                <path
                    style={{
                        WebkitTransformOrigin: 143.775,
                        MsTransformOrigin: 143.775,
                        transformOrigin: 143.775,
                    }}
                    id="eld5dy2j63rdn"
                    fill="#E0E0E0"
                    d="M73.39 241.63H214.16000000000003V382.4H73.39z"
                    className="animable"
                ></path>
                <path
                    style={{
                        WebkitTransformOrigin: 124.54,
                        MsTransformOrigin: 124.54,
                        transformOrigin: 124.54,
                    }}
                    id="el4g8p9cfvq2g"
                    fill="#EBEBEB"
                    d="M59.9 241.63H189.18V382.4H59.9z"
                    className="animable"
                ></path>
                <path
                    style={{
                        WebkitTransformOrigin: 124.46,
                        MsTransformOrigin: 124.46,
                        transformOrigin: 124.46,
                    }}
                    id="el869j50t4xha"
                    fill="#F5F5F5"
                    d="M65.11 249.25H183.81V287.69H65.11z"
                    className="animable"
                ></path>
                <path
                    style={{
                        WebkitTransformOrigin: 124.46,
                        MsTransformOrigin: 124.46,
                        transformOrigin: 124.46,
                    }}
                    id="eli1lwscbot3n"
                    fill="#F5F5F5"
                    d="M65.11 293.74H183.81V332.18H65.11z"
                    className="animable"
                ></path>
                <path
                    style={{
                        WebkitTransformOrigin: 124.46,
                        MsTransformOrigin: 124.46,
                        transformOrigin: 124.46,
                    }}
                    id="el95xhg6xy85i"
                    fill="#F5F5F5"
                    d="M65.11 336.35H183.81V374.79H65.11z"
                    className="animable"
                ></path>
                <path
                    d="M125.72 266.34a4.2 4.2 0 11-4.2-4.2 4.2 4.2 0 014.2 4.2z"
                    style={{
                        WebkitTransformOrigin: 121.52,
                        MsTransformOrigin: 121.52,
                        transformOrigin: 121.52,
                    }}
                    id="ell4wot66q2vf"
                    fill="#FAFAFA"
                    className="animable"
                ></path>
                <path
                    d="M124.54 351.68a3 3 0 11-3-3 3 3 0 013 3z"
                    style={{
                        WebkitTransformOrigin: 121.54,
                        MsTransformOrigin: 121.54,
                        transformOrigin: 121.54,
                    }}
                    id="el8fdspzxlbd9"
                    fill="#FAFAFA"
                    className="animable"
                ></path>
                <path
                    d="M124.54 312.38a3 3 0 11-3-3 3 3 0 013 3z"
                    style={{
                        WebkitTransformOrigin: 121.54,
                        MsTransformOrigin: 121.54,
                        transformOrigin: 121.54,
                    }}
                    id="elb37ypsubwoe"
                    fill="#FAFAFA"
                    className="animable"
                ></path>
                <path
                    style={{
                        WebkitTransformOrigin: 149.93,
                        MsTransformOrigin: 149.93,
                        transformOrigin: 149.93,
                    }}
                    id="elaku1xyfqct8"
                    fill="#E0E0E0"
                    d="M68.1 68.69H231.76V176.56H68.1z"
                    className="animable"
                ></path>
                <path
                    style={{
                        WebkitTransformOrigin: 146.77,
                        MsTransformOrigin: 146.77,
                        transformOrigin: 146.77,
                    }}
                    id="elsf7lh4189p8"
                    fill="#F0F0F0"
                    d="M64.94 68.69H228.6V176.56H64.94z"
                    className="animable"
                ></path>
                <path
                    style={{
                        WebkitTransformOrigin: 146.11,
                        MsTransformOrigin: 146.11,
                        transformOrigin: 146.11,
                    }}
                    id="elttm6m4kr72"
                    fill="#FAFAFA"
                    d="M69.64 73.83H222.57999999999998V172.26999999999998H69.64z"
                    className="animable"
                ></path>
                <path
                    style={{
                        WebkitTransformOrigin: 186.725,
                        MsTransformOrigin: 186.725,
                        transformOrigin: 186.725,
                    }}
                    id="el2dhz7p4wndq"
                    fill="#FFF"
                    d="M217.42 75.23L173.91 172.27 156.03 172.27 197.88 75.23 217.42 75.23z"
                    className="animable"
                ></path>
                <path
                    style={{
                        WebkitTransformOrigin: 149.195,
                        MsTransformOrigin: 149.195,
                        transformOrigin: 149.195,
                    }}
                    id="el39h6yayu6py"
                    fill="#FFF"
                    d="M181.77 75.23L137.58 172.27 116.62 172.27 162.82 75.23 181.77 75.23z"
                    className="animable"
                ></path>
                <path
                    style={{
                        WebkitTransformOrigin: 148.24,
                        MsTransformOrigin: 148.24,
                        transformOrigin: 148.24,
                    }}
                    id="el3illb65todr"
                    fill="#E0E0E0"
                    d="M64.94 68.69H231.54V75.23H64.94z"
                    className="animable"
                ></path>
                <path
                    style={{
                        WebkitTransformOrigin: 148.24,
                        MsTransformOrigin: 148.24,
                        transformOrigin: 148.24,
                    }}
                    id="el1hf9i1fhj9d"
                    fill="#E0E0E0"
                    d="M64.94 76.92H231.54V83.46000000000001H64.94z"
                    className="animable"
                ></path>
                <path
                    style={{
                        WebkitTransformOrigin: 148.24,
                        MsTransformOrigin: 148.24,
                        transformOrigin: 148.24,
                    }}
                    id="elpnxb95z85mk"
                    fill="#E0E0E0"
                    d="M64.94 85.15H231.54V91.69000000000001H64.94z"
                    className="animable"
                ></path>
                <path
                    style={{
                        WebkitTransformOrigin: 148.24,
                        MsTransformOrigin: 148.24,
                        transformOrigin: 148.24,
                    }}
                    id="elm8b11h4m2tg"
                    fill="#E0E0E0"
                    d="M64.94 93.37H231.54V99.91000000000001H64.94z"
                    className="animable"
                ></path>
                <path
                    style={{
                        WebkitTransformOrigin: 148.24,
                        MsTransformOrigin: 148.24,
                        transformOrigin: 148.24,
                    }}
                    id="eliws0y8twbgg"
                    fill="#E0E0E0"
                    d="M64.94 101.6H231.54V108.14H64.94z"
                    className="animable"
                ></path>
                <path
                    style={{
                        WebkitTransformOrigin: 148.94,
                        MsTransformOrigin: 148.94,
                        transformOrigin: 148.94,
                    }}
                    id="elsdp6rtd1g3"
                    fill="#E0E0E0"
                    d="M61.37 176.56H236.51V184.7H61.37z"
                    className="animable"
                ></path>
                <path
                    style={{
                        WebkitTransformOrigin: 366.025,
                        MsTransformOrigin: 366.025,
                        transformOrigin: 366.025,
                    }}
                    id="elggcqnyed79n"
                    fill="#E0E0E0"
                    d="M439.17 298.58L439.17 382.4 409.55 382.4 407.97 374.01 292.88 374.01 292.88 298.58 439.17 298.58z"
                    className="animable"
                ></path>
                <path
                    style={{
                        WebkitTransformOrigin: 345.735,
                        MsTransformOrigin: 345.735,
                        transformOrigin: 345.735,
                    }}
                    id="el19dy0k9yl3v"
                    fill="#EBEBEB"
                    d="M418.88 298.58L418.88 382.4 409.55 382.4 407.97 374.01 290.59 374.01 288.73 382.4 272.59 382.4 272.59 298.58 418.88 298.58z"
                    className="animable"
                ></path>
                <path
                    style={{
                        WebkitTransformOrigin: 345.325,
                        MsTransformOrigin: 345.325,
                        transformOrigin: 345.325,
                    }}
                    id="ellodk0cy2g"
                    fill="none"
                    stroke="#FAFAFA"
                    strokeMiterlimit="10"
                    d="M280.02 304.46H410.63V333.34H280.02z"
                    className="animable"
                ></path>
                <path
                    style={{
                        WebkitTransformOrigin: 345.325,
                        MsTransformOrigin: 345.325,
                        transformOrigin: 345.325,
                    }}
                    id="elfctp53xooks"
                    fill="none"
                    stroke="#FAFAFA"
                    strokeMiterlimit="10"
                    d="M280.02 339.09H410.63V368.26H280.02z"
                    className="animable"
                ></path>
                <path
                    style={{
                        WebkitTransformOrigin: 407.175,
                        MsTransformOrigin: 407.175,
                        transformOrigin: 407.175,
                    }}
                    id="eledonpe5mskn"
                    fill="#E0E0E0"
                    d="M377.81 64.43H436.54V143.86H377.81z"
                    className="animable"
                ></path>
                <path
                    style={{
                        WebkitTransformOrigin: 404.235,
                        MsTransformOrigin: 404.235,
                        transformOrigin: 404.235,
                    }}
                    id="eler8qk94ygnn"
                    fill="#E6E6E6"
                    d="M374.87 64.38H433.6V143.81H374.87z"
                    className="animable"
                ></path>
                <path
                    style={{
                        WebkitTransformOrigin: 404.18,
                        MsTransformOrigin: 404.18,
                        transformOrigin: 404.18,
                    }}
                    id="el1lka4o1pnxg"
                    fill="#FAFAFA"
                    d="M377.81 67.36H430.55V140.95H377.81z"
                    className="animable"
                ></path>
                <path
                    style={{
                        WebkitTransformOrigin: 405.015,
                        MsTransformOrigin: 405.015,
                        transformOrigin: 405.015,
                    }}
                    id="elins4523ae7"
                    fill="#E0E0E0"
                    d="M384.86 124.98H425.17V126.7H384.86z"
                    className="animable"
                ></path>
                <path
                    style={{
                        WebkitTransformOrigin: 405.015,
                        MsTransformOrigin: 405.015,
                        transformOrigin: 405.015,
                    }}
                    id="elnvrdabtcjd"
                    fill="#E0E0E0"
                    d="M384.86 129.37H425.17V131.09H384.86z"
                    className="animable"
                ></path>
                <path
                    style={{
                        WebkitTransformOrigin: 405.015,
                        MsTransformOrigin: 405.015,
                        transformOrigin: 405.015,
                    }}
                    id="el17ba6thrm1i"
                    fill="#E0E0E0"
                    d="M384.86 133.76H425.17V135.48H384.86z"
                    className="animable"
                ></path>
                <path
                    d="M419.41 94.58A14.39 14.39 0 11405 80.19a14.39 14.39 0 0114.41 14.39z"
                    style={{
                        WebkitTransformOrigin: 405.02,
                        MsTransformOrigin: 405.02,
                        transformOrigin: 405.02,
                    }}
                    id="elm411tvnbg5p"
                    fill="none"
                    stroke="#E0E0E0"
                    strokeMiterlimit="10"
                    className="animable"
                ></path>
                <path
                    style={{
                        WebkitTransformOrigin: 388.475,
                        MsTransformOrigin: 388.475,
                        transformOrigin: 388.475,
                    }}
                    id="elz2wiepgf0bg"
                    fill="#E0E0E0"
                    d="M363.4 164.37H413.54999999999995V214.52H363.4z"
                    className="animable"
                ></path>
                <path
                    style={{
                        WebkitTransformOrigin: 384.185,
                        MsTransformOrigin: 384.185,
                        transformOrigin: 384.185,
                    }}
                    id="el3esgzkv3wer"
                    fill="#EBEBEB"
                    d="M359.11 164.37H409.26V214.52H359.11z"
                    className="animable"
                ></path>
                <path
                    style={{
                        WebkitTransformOrigin: 384.19,
                        MsTransformOrigin: 384.19,
                        transformOrigin: 384.19,
                    }}
                    id="el2b18v69f3cp"
                    fill="#FAFAFA"
                    d="M362.58 167.84H405.79999999999995V211.06H362.58z"
                    className="animable"
                ></path>
                <path
                    style={{
                        WebkitTransformOrigin: 384.19,
                        MsTransformOrigin: 384.19,
                        transformOrigin: 384.19,
                    }}
                    id="elgzrvwtamd0v"
                    fill="#E0E0E0"
                    d="M372.73 177.98H395.65000000000003V200.89999999999998H372.73z"
                    className="animable"
                ></path>
            </g>
            <g
                id="freepik--Shadow--inject-3"
                style={{
                    WebkitTransformOrigin: 248.08,
                    MsTransformOrigin: 248.08,
                    transformOrigin: 248.08,
                }}
                className="animable"
            >
                <ellipse
                    cx="248.08"
                    cy="440.07"
                    fill="#F5F5F5"
                    rx="167.16"
                    ry="12.64"
                    style={{
                        WebkitTransformOrigin: 248.08,
                        MsTransformOrigin: 248.08,
                        transformOrigin: 248.08,
                    }}
                    id="elybbq3czhpg"
                    className="animable"
                ></ellipse>
            </g>
            <g
                id="freepik--Box--inject-3"
                style={{
                    WebkitTransformOrigin: 152.215,
                    MsTransformOrigin: 152.215,
                    transformOrigin: 152.215,
                }}
                className="animable"
            >
                <path
                    style={{
                        WebkitTransformOrigin: 129.74,
                        MsTransformOrigin: 129.74,
                        transformOrigin: 129.74,
                    }}
                    id="el6fl1xp0l83t"
                    fill="#578AD6"
                    d="M98.98 373.21H160.5V442.64H98.98z"
                    className="animable"
                ></path>
                <path
                    style={{
                        WebkitTransformOrigin: 160.5,
                        MsTransformOrigin: 160.5,
                        transformOrigin: 160.5,
                    }}
                    id="elt8s3dzw2gnb"
                    fill="#578AD6"
                    d="M129.74 373.21H191.26000000000002V442.64H129.74z"
                    className="animable"
                ></path>
                <g id="eln6rmdz9hnif">
                    <path
                        style={{
                            WebkitTransformOrigin: 129.74,
                            MsTransformOrigin: 129.74,
                            transformOrigin: 129.74,
                        }}
                        id="elb6o5w7p0ie6"
                        fill="#FFF"
                        d="M98.98 373.21H160.5V442.64H98.98z"
                        className="animable"
                        opacity="0.7"
                    ></path>
                </g>
                <g id="el5ciwipe26lk">
                    <path
                        style={{
                            WebkitTransformOrigin: 175.84,
                            MsTransformOrigin: 175.84,
                            transformOrigin: 175.84,
                        }}
                        id="ellxm12h0vaxm"
                        fill="#FFF"
                        d="M160.43 373.21H191.25V442.64H160.43z"
                        className="animable"
                        opacity="0.2"
                    ></path>
                </g>
                <g id="elfwjizllyim">
                    <ellipse
                        id="elswvw0u3sgq"
                        cx="174.86"
                        cy="408.28"
                        fill="#FFF"
                        rx="7.83"
                        ry="13.52"
                        style={{
                            WebkitTransformOrigin: 174.86,
                            MsTransformOrigin: 174.86,
                            transformOrigin: 174.86,
                        }}
                        className="animable"
                        transform="rotate(-1.34)"
                    ></ellipse>
                </g>
                <path
                    style={{
                        WebkitTransformOrigin: 174.86,
                        MsTransformOrigin: 174.86,
                        transformOrigin: 174.86,
                    }}
                    id="elsla9ndqy9k"
                    fill="#578AD6"
                    d="M179.6 405.99L176.26 406.07 176.13 400.41 173.55 400.47 173.68 406.13 170.01 406.22 170.11 410.63 173.78 410.54 173.92 416.15 176.5 416.09 176.37 410.48 179.71 410.4 179.6 405.99z"
                    className="animable"
                ></path>
                <path
                    style={{
                        WebkitTransformOrigin: 131,
                        MsTransformOrigin: 131,
                        transformOrigin: 131,
                    }}
                    id="eletnw892nxgg"
                    fill="#F5F5F5"
                    d="M124.77 373.21H137.23V385.66999999999996H124.77z"
                    className="animable"
                ></path>
                <path
                    style={{
                        WebkitTransformOrigin: 131,
                        MsTransformOrigin: 131,
                        transformOrigin: 131,
                    }}
                    id="el1svs176wbnb"
                    fill="#F5F5F5"
                    d="M124.77 398.1H137.23V442.64000000000004H124.77z"
                    className="animable"
                ></path>
                <path
                    style={{
                        WebkitTransformOrigin: 154.975,
                        MsTransformOrigin: 154.975,
                        transformOrigin: 154.975,
                    }}
                    id="eliajyr0mhnd"
                    fill="#578AD6"
                    d="M129.74 316.26H180.21V373.23H129.74z"
                    className="animable"
                ></path>
                <path
                    style={{
                        WebkitTransformOrigin: 180.215,
                        MsTransformOrigin: 180.215,
                        transformOrigin: 180.215,
                    }}
                    id="elhch4pph8hoh"
                    fill="#578AD6"
                    d="M154.98 316.26H205.45V373.23H154.98z"
                    className="animable"
                ></path>
                <g id="ell9781aeob6k">
                    <path
                        style={{
                            WebkitTransformOrigin: 151.04,
                            MsTransformOrigin: 151.04,
                            transformOrigin: 151.04,
                        }}
                        id="elk23wjpmiomj"
                        fill="#FFF"
                        d="M129.74 316.26H172.34V373.23H129.74z"
                        className="animable"
                        opacity="0.7"
                    ></path>
                </g>
                <g id="elltirv6xf81b">
                    <path
                        style={{
                            WebkitTransformOrigin: 188.895,
                            MsTransformOrigin: 188.895,
                            transformOrigin: 188.895,
                        }}
                        id="elwrkoxhbjkhj"
                        fill="#FFF"
                        d="M172.34 316.26H205.45V373.23H172.34z"
                        className="animable"
                        opacity="0.2"
                    ></path>
                </g>
                <path
                    d="M195.31 344.89c.15 6.12-2.61 11.16-6.16 11.24s-6.54-4.82-6.68-10.94 2.62-11.19 6.16-11.19 6.54 4.76 6.68 10.89z"
                    style={{
                        WebkitTransformOrigin: 188.89,
                        MsTransformOrigin: 188.89,
                        transformOrigin: 188.89,
                    }}
                    id="elomia5g4i9bc"
                    fill="#FFF"
                    className="animable"
                ></path>
                <path
                    style={{
                        WebkitTransformOrigin: 188.89,
                        MsTransformOrigin: 188.89,
                        transformOrigin: 188.89,
                    }}
                    id="elch4134fiju7"
                    fill="#578AD6"
                    d="M192.79 343.16L190.05 343.22 189.94 338.57 187.82 338.62 187.93 343.27 184.91 343.34 185 346.96 188.01 346.89 188.12 351.5 190.24 351.44 190.13 346.84 192.87 346.78 192.79 343.16z"
                    className="animable"
                ></path>
                <path
                    style={{
                        WebkitTransformOrigin: 145.785,
                        MsTransformOrigin: 145.785,
                        transformOrigin: 145.785,
                    }}
                    id="el79lop7hfo1q"
                    fill="#F5F5F5"
                    d="M140.67 316.26H150.89999999999998V373.23H140.67z"
                    className="animable"
                ></path>
            </g>
            <g
                id="freepik--Character--inject-3"
                style={{
                    WebkitTransformOrigin: 245.019,
                    MsTransformOrigin: 245.019,
                    transformOrigin: 245.019,
                }}
                className="animable"
            >
                <path
                    d="M238.39 239.1s-14.73 69.76-16 92.9 4.77 81.29 4.77 81.29l18.82 3 3.59-74.21 8.25-56.57s8.64 46 12.36 56.77 21.47 42 36.13 66.95l12.6-9.23s-23.55-67.34-27.57-80.49-1.3-68.9-13.7-84.73z"
                    style={{
                        WebkitTransformOrigin: 270.562,
                        MsTransformOrigin: 270.562,
                        transformOrigin: 270.562,
                    }}
                    id="elw7ex3z3b6f"
                    fill="#578AD6"
                    className="animable"
                ></path>
                <g id="el4dzqj2jd8qb">
                    <path
                        d="M238.39 239.1s-14.73 69.76-16 92.9 4.77 81.29 4.77 81.29l18.82 3 3.59-74.21 8.25-56.57s8.64 46 12.36 56.77 21.47 42 36.13 66.95l12.6-9.23s-23.55-67.34-27.57-80.49-1.3-68.9-13.7-84.73z"
                        style={{
                            WebkitTransformOrigin: 270.562,
                            MsTransformOrigin: 270.562,
                            transformOrigin: 270.562,
                        }}
                        id="el7ddltwhjir"
                        fill="#FFF"
                        className="animable"
                        opacity="0.4"
                    ></path>
                </g>
                <path
                    d="M241.89 432.6c-.09.56-9.85-1.56-9.85-1.56l-.66-9.64-.26-3.78 10-.88s.17 2.88.35 6.18c.2 4.32.53 9.35.42 9.68z"
                    style={{
                        WebkitTransformOrigin: 236.516,
                        MsTransformOrigin: 236.516,
                        transformOrigin: 236.516,
                    }}
                    id="elwmuy9fklnx"
                    fill="#B55B52"
                    className="animable"
                ></path>
                <g id="elz2nclb6byme">
                    <path
                        d="M241.43 422.92l-10.05-1.52-.26-3.78 10-.88s.13 2.88.31 6.18z"
                        style={{
                            WebkitTransformOrigin: 236.275,
                            MsTransformOrigin: 236.275,
                            transformOrigin: 236.275,
                        }}
                        id="elq2y28zie3a"
                        className="animable"
                        opacity="0.2"
                    ></path>
                </g>
                <path
                    d="M224.89 434.43l-.4-.55c-.12-.17-3-4.2-2.58-6.18a1.33 1.33 0 01.68-.91.91.91 0 01.94 0c1.43.87 1.38 6.35 1.37 7zm-1.8-7.32a.64.64 0 00-.29.08.92.92 0 00-.45.61c-.31 1.36 1.31 4 2.1 5.22 0-1.93-.26-5.31-1.15-5.85a.4.4 0 00-.21-.06z"
                    style={{
                        WebkitTransformOrigin: 223.385,
                        MsTransformOrigin: 223.385,
                        transformOrigin: 223.385,
                    }}
                    id="eldw31x8il43j"
                    fill="#578AD6"
                    className="animable"
                ></path>
                <path
                    d="M223.88 434.49l-.58-.24c-.62-.25-6.06-2.52-6.4-4.47a1.15 1.15 0 01.41-1.07 1.41 1.41 0 011.24-.35c2.2.38 4.92 5.36 5 5.57zm-5.61-5.71a.92.92 0 00-.65.27.68.68 0 00-.27.66c.22 1.28 3.62 3 5.5 3.86-.78-1.33-2.8-4.49-4.38-4.77z"
                    style={{
                        WebkitTransformOrigin: 220.383,
                        MsTransformOrigin: 220.383,
                        transformOrigin: 220.383,
                    }}
                    id="elxkuwxck3u1"
                    fill="#578AD6"
                    className="animable"
                ></path>
                <path
                    d="M241.57 432.06l-10.1-1.59a2.55 2.55 0 00-1.8.39 21.12 21.12 0 01-7.75 3.22c-4.32.61-8.16-.24-8.52 1a3.71 3.71 0 001.86 4.19c1.42.74 18.82 3.44 26.16 4.56a2.56 2.56 0 002.93-2.77l-.63-6.7a2.58 2.58 0 00-2.15-2.3z"
                    style={{
                        WebkitTransformOrigin: 228.824,
                        MsTransformOrigin: 228.824,
                        transformOrigin: 228.824,
                    }}
                    id="elznsbb2ahvk"
                    fill="#222221"
                    className="animable"
                ></path>
                <path
                    style={{
                        WebkitTransformOrigin: 236.065,
                        MsTransformOrigin: 236.065,
                        transformOrigin: 236.065,
                    }}
                    id="eljaapi54nc8"
                    fill="#578AD6"
                    d="M224.67 410.98L226.25 419.3 246.85 421.26 247.46 412.84 224.67 410.98z"
                    className="animable"
                ></path>
                <path
                    d="M325.24 410.44c.28.5-8.68 4.91-8.68 4.91l-5.94-6.53-3.11-3.43 3.16-3 4.1-3.87s.42.48 1.11 1.24l3.64 4.1c2.7 3.06 5.58 6.33 5.72 6.58z"
                    style={{
                        WebkitTransformOrigin: 316.378,
                        MsTransformOrigin: 316.378,
                        transformOrigin: 316.378,
                    }}
                    id="elpg2gsf0f1qi"
                    fill="#B55B52"
                    className="animable"
                ></path>
                <g id="el30v6r7rjucx">
                    <path
                        d="M319.52 403.87l-8.9 4.95-3.11-3.43 3.16-3 5.21-2.63z"
                        style={{
                            WebkitTransformOrigin: 313.515,
                            MsTransformOrigin: 313.515,
                            transformOrigin: 313.515,
                        }}
                        id="elv972f9tped"
                        className="animable"
                        opacity="0.2"
                    ></path>
                </g>
                <path
                    d="M313.65 419.34l-.52-.24c-.19-.08-4.58-2.09-5-3.86a1.11 1.11 0 01.28-1 1.23 1.23 0 011.15-.46c1.78.29 3.63 4.56 3.84 5.05zm-4.25-5.14a.8.8 0 00-.62.32.67.67 0 00-.17.63c.24 1.12 2.72 2.56 4.15 3.28-.69-1.48-2.11-4-3.24-4.22z"
                    style={{
                        WebkitTransformOrigin: 310.877,
                        MsTransformOrigin: 310.877,
                        transformOrigin: 310.877,
                    }}
                    id="el55vh5vjmqom"
                    fill="#578AD6"
                    className="animable"
                ></path>
                <path
                    d="M307.07 421.44a3.8 3.8 0 01-2.55-.65 1.42 1.42 0 01-.33-1.48 2 2 0 011.17-1.4c2.61-1.18 8.44 1.79 8.69 1.92l.5.26-.54.16a30.93 30.93 0 01-6.94 1.19zm-.19-3.36a3.27 3.27 0 00-1.34.24 1.56 1.56 0 00-.91 1.1 1 1 0 00.2 1c1.2 1.16 6.09.15 8.48-.49-1.22-.53-4.31-1.85-6.43-1.85z"
                    style={{
                        WebkitTransformOrigin: 309.328,
                        MsTransformOrigin: 309.328,
                        transformOrigin: 309.328,
                    }}
                    id="el69bu6gh8su5"
                    fill="#578AD6"
                    className="animable"
                ></path>
                <path
                    d="M324.66 410.08l-8.9 5a2.5 2.5 0 00-1.17 1.42 21 21 0 01-4.08 7.33c-3 3.17-6.54 4.89-6.05 6.08a3.69 3.69 0 004.05 2.13c1.57-.3 16.89-9 23.34-12.66a2.56 2.56 0 00.57-4l-4.65-4.87a2.58 2.58 0 00-3.11-.43z"
                    style={{
                        WebkitTransformOrigin: 318.776,
                        MsTransformOrigin: 318.776,
                        transformOrigin: 318.776,
                    }}
                    id="elcjhioweulz4"
                    fill="#222221"
                    className="animable"
                ></path>
                <path
                    style={{
                        WebkitTransformOrigin: 310.79,
                        MsTransformOrigin: 310.79,
                        transformOrigin: 310.79,
                    }}
                    id="elughyacn53e8"
                    fill="#578AD6"
                    d="M298.9 404.93L305.31 410.49 322.68 399.23 317.93 392.25 298.9 404.93z"
                    className="animable"
                ></path>
                <path
                    d="M259.28 152c.55-.31 12-.83 23 1.48s-8.6 15.84-8.6 15.84l-6.14 24.58-11.25.1-1.36-34.9z"
                    style={{
                        WebkitTransformOrigin: 270.281,
                        MsTransformOrigin: 270.281,
                        transformOrigin: 270.281,
                    }}
                    id="elthurh042nro"
                    fill="#EBEBEB"
                    className="animable"
                ></path>
                <path
                    d="M282.27 153.51l-23.86 37.65 2.64-39.48s-8.67.22-11.36 1-5 7.06-6.93 15.66-4.86 29.22-4.86 29.22l-13.71 115s46.87 10 68.95 2.23c0 0-8.3-103.29-6.93-109.67s5.8-29.72 5.8-29.72 6.14-11.65 3.47-15.08-13.21-6.81-13.21-6.81z"
                    style={{
                        WebkitTransformOrigin: 260.171,
                        MsTransformOrigin: 260.171,
                        transformOrigin: 260.171,
                    }}
                    id="elharwrm3h7le"
                    fill="#578AD6"
                    className="animable"
                ></path>
                <path
                    style={{
                        WebkitTransformOrigin: 255.84,
                        MsTransformOrigin: 255.84,
                        transformOrigin: 255.84,
                    }}
                    id="elallktlag1l8"
                    fill="none"
                    stroke="#FFF"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="0.75"
                    d="M254.14 225.95L257.54 317.21"
                    className="animable"
                ></path>
                <g id="el4hhtsf0eva5">
                    <path
                        style={{
                            WebkitTransformOrigin: 220.295,
                            MsTransformOrigin: 220.295,
                            transformOrigin: 220.295,
                        }}
                        id="elh2m6jpkra2k"
                        fill="#578AD6"
                        d="M176.6 167.01H263.99V231.58999999999997H176.6z"
                        className="animable"
                        transform="rotate(-18.82)"
                    ></path>
                </g>
                <path
                    style={{
                        WebkitTransformOrigin: 204.07,
                        MsTransformOrigin: 204.07,
                        transformOrigin: 204.07,
                    }}
                    id="ely1h5snh6u5a"
                    fill="#578AD6"
                    d="M251.24 154.64L189.35 243.96 177.73 238.69 156.9 177.57 239.62 149.38 251.24 154.64z"
                    className="animable"
                ></path>
                <g id="el6xcwm24q508">
                    <path
                        style={{
                            WebkitTransformOrigin: 204.07,
                            MsTransformOrigin: 204.07,
                            transformOrigin: 204.07,
                        }}
                        id="el32nnz8qvvbs"
                        fill="#FFF"
                        d="M251.24 154.64L168.52 182.83 189.35 243.96 177.73 238.69 156.9 177.57 239.62 149.38 251.24 154.64z"
                        className="animable"
                        opacity="0.7"
                    ></path>
                </g>
                <g id="elrxsx9y4ji5">
                    <path
                        style={{
                            WebkitTransformOrigin: 220.295,
                            MsTransformOrigin: 220.295,
                            transformOrigin: 220.295,
                        }}
                        id="elumaecewbtn"
                        fill="#FFF"
                        d="M176.6 167.01H263.99V231.58999999999997H176.6z"
                        className="animable"
                        opacity="0.46"
                        transform="rotate(-18.82)"
                    ></path>
                </g>
                <g id="elf3yi8xe8n7d">
                    <circle
                        id="elvnl7hi12fn9"
                        cx="220.29"
                        cy="199.3"
                        r="23.45"
                        style={{
                            WebkitTransformOrigin: 220.29,
                            MsTransformOrigin: 220.29,
                            transformOrigin: 220.29,
                        }}
                        fill="#FAFAFA"
                        className="animable"
                        transform="rotate(-39.29)"
                    ></circle>
                </g>
                <path
                    style={{
                        WebkitTransformOrigin: 220.31,
                        MsTransformOrigin: 220.31,
                        transformOrigin: 220.31,
                    }}
                    id="elz31b0wm1y1"
                    fill="#578AD6"
                    d="M232.78 191.24L223.27 194.35 220.21 185.01 212.86 187.42 215.92 196.76 205.46 200.18 207.83 207.45 218.3 204.02 221.33 213.28 228.68 210.87 225.65 201.61 235.16 198.5 232.78 191.24z"
                    className="animable"
                ></path>
                <path
                    d="M261 103.14V88.59s15.93-7.17 31.16 7.47l-7 14.12z"
                    style={{
                        WebkitTransformOrigin: 276.58,
                        MsTransformOrigin: 276.58,
                        transformOrigin: 276.58,
                    }}
                    id="eludoetzld3yc"
                    fill="#578AD6"
                    className="animable"
                ></path>
                <g id="el2l3swknqo6y">
                    <path
                        style={{
                            WebkitTransformOrigin: 274.86,
                            MsTransformOrigin: 274.86,
                            transformOrigin: 274.86,
                        }}
                        id="elk3vjamby6hc"
                        fill="#FFF"
                        d="M273.94 90.47H275.78V96.1H273.94z"
                        className="animable"
                        transform="rotate(11.17)"
                    ></path>
                </g>
                <g id="eld0vzznjvue">
                    <path
                        style={{
                            WebkitTransformOrigin: 274.86,
                            MsTransformOrigin: 274.86,
                            transformOrigin: 274.86,
                        }}
                        id="el0iae9z2eantp"
                        fill="#FFF"
                        d="M273.94 90.47H275.78V96.1H273.94z"
                        className="animable"
                        transform="rotate(-78.83)"
                    ></path>
                </g>
                <path
                    d="M284 130c-2.91 4.19-5.58 3.61-5.58 3.61a16 16 0 01-3.37 4.23c-.48 13.07 3.37 16.6 3.37 16.6-4 6.17-8.94 6-13.94 4.2s-5.26-5.72-5.26-5.72c4.52-2.22 4.58-5.7 3.93-8.08a10.5 10.5 0 00-1.14-2.62c-8.49-2.33-10.94-10.47-10.36-18.15a41.51 41.51 0 013.38-13.5l5-7.57 19.55 4 1.05 10.36-4 7.68c7.05-5.21 10.31.72 7.37 4.96z"
                    style={{
                        WebkitTransformOrigin: 268.325,
                        MsTransformOrigin: 268.325,
                        transformOrigin: 268.325,
                    }}
                    id="elckarx3jmwz"
                    fill="#B55B52"
                    className="animable"
                ></path>
                <path
                    d="M261 129.9a9.4 9.4 0 01-1.42-.1.35.35 0 11.12-.69s5 .87 7.15-2.83a.35.35 0 11.61.35c-1.65 2.82-4.7 3.27-6.46 3.27z"
                    style={{
                        WebkitTransformOrigin: 263.398,
                        MsTransformOrigin: 263.398,
                        transformOrigin: 263.398,
                    }}
                    id="el1eszfhr7tz5"
                    fill="#263238"
                    className="animable"
                ></path>
                <path
                    d="M259.63 125.33c.21 0 1.06-6.38 1.06-6.38l-4.54 5.46a4 4 0 003.48.92z"
                    style={{
                        WebkitTransformOrigin: 258.42,
                        MsTransformOrigin: 258.42,
                        transformOrigin: 258.42,
                    }}
                    id="eludffvmasjyr"
                    fill="#A02724"
                    className="animable"
                ></path>
                <path
                    d="M266.66 119.08c-.11.79-.6 1.38-1.09 1.31s-.8-.77-.69-1.56.6-1.39 1.09-1.32.8.77.69 1.57z"
                    style={{
                        WebkitTransformOrigin: 265.77,
                        MsTransformOrigin: 265.77,
                        transformOrigin: 265.77,
                    }}
                    id="el5mjedn6stq4"
                    fill="#263238"
                    className="animable"
                ></path>
                <path
                    d="M257 118.2c-.11.79-.6 1.38-1.09 1.31s-.8-.76-.69-1.56.6-1.38 1.09-1.31.85.76.69 1.56z"
                    style={{
                        WebkitTransformOrigin: 256.118,
                        MsTransformOrigin: 256.118,
                        transformOrigin: 256.118,
                    }}
                    id="el7xpoul8ri1"
                    fill="#263238"
                    className="animable"
                ></path>
                <path
                    d="M270.58 115.5a.47.47 0 01-.42-.27c-1.19-2.53-2.87-2.2-2.94-2.19a.47.47 0 01-.22-.91c.1 0 2.48-.56 4 2.7a.47.47 0 01-.23.63.45.45 0 01-.19.04z"
                    style={{
                        WebkitTransformOrigin: 268.863,
                        MsTransformOrigin: 268.863,
                        transformOrigin: 268.863,
                    }}
                    id="elmvi9yolurw"
                    fill="#263238"
                    className="animable"
                ></path>
                <path
                    d="M253.93 113.82a.45.45 0 01-.32-.13.46.46 0 010-.66c.12-.12 2.8-2.86 6.18-1.51a.47.47 0 01.27.61.48.48 0 01-.62.26c-2.81-1.12-5.12 1.26-5.15 1.29a.44.44 0 01-.36.14z"
                    style={{
                        WebkitTransformOrigin: 256.781,
                        MsTransformOrigin: 256.781,
                        transformOrigin: 256.781,
                    }}
                    id="elmdnbk732nwf"
                    fill="#263238"
                    className="animable"
                ></path>
                <g id="el4ybsusbq367">
                    <path
                        d="M266.37 141.69a8.85 8.85 0 01-3.19 3.1 10.5 10.5 0 00-1.14-2.62 10.62 10.62 0 004.33-.48z"
                        style={{
                            WebkitTransformOrigin: 264.205,
                            MsTransformOrigin: 264.205,
                            transformOrigin: 264.205,
                        }}
                        id="eljehmn324e9d"
                        className="animable"
                        opacity="0.2"
                    ></path>
                </g>
                <path
                    d="M278.65 107.88s-16.87 4.51-23.59 2.12-4.37-10.65-3.46-11.43c1.24-1.06 3.94 3.32 3.94 3.32s-2.21-4.69-.48-5.72 2 6 8.3 5.26 23.76-5.49 24.79 5.49a39 39 0 01-3.09 19.26s-.93-6-8.36-1.23c0 0-2.47-1.76 0-4.18 4.3-4.19 2.8-9.7 1.95-12.89z"
                    style={{
                        WebkitTransformOrigin: 269.387,
                        MsTransformOrigin: 269.387,
                        transformOrigin: 269.387,
                    }}
                    id="el4la5mb9okjb"
                    fill="#263238"
                    className="animable"
                ></path>
                <path
                    d="M276.86 107s-2.93 9.58 2.46 9.12 2.08-10.46 2.08-10.46z"
                    style={{
                        WebkitTransformOrigin: 279.235,
                        MsTransformOrigin: 279.235,
                        transformOrigin: 279.235,
                    }}
                    id="el1vhvpg4k3ht"
                    fill="#263238"
                    className="animable"
                ></path>
                <path
                    d="M262.69 102s-3.52-2.59-2.62-4.47 2.3 2.66 9.31 1.83 15.33-1.46 16.87 3.19-14 1.11-14 1.11l-9.58 2.06z"
                    style={{
                        WebkitTransformOrigin: 273.14,
                        MsTransformOrigin: 273.14,
                        transformOrigin: 273.14,
                    }}
                    id="elup8zkqk3xlh"
                    fill="#263238"
                    className="animable"
                ></path>
                <path
                    d="M261.47 216.46l-6.77-1.91s-8-.49-8.67 2.56-1 8.31 2.18 8.73 13.08-.2 13.08-.2z"
                    style={{
                        WebkitTransformOrigin: 253.528,
                        MsTransformOrigin: 253.528,
                        transformOrigin: 253.528,
                    }}
                    id="el120s4f2n1gn"
                    fill="#B55B52"
                    className="animable"
                ></path>
                <path
                    d="M284.57 170l10.91-9.74s13.51 30 14.27 41.53-8.15 16.71-15.7 18.65-32.57 7.11-32.57 7.11l-1.35-13.92s26.65-4.55 36.91-9.26c-.04.03-9.27-26.82-12.47-34.37z"
                    style={{
                        WebkitTransformOrigin: 284.963,
                        MsTransformOrigin: 284.963,
                        transformOrigin: 284.963,
                    }}
                    id="el6e366n3bgas"
                    fill="#578AD6"
                    className="animable"
                ></path>
                <g id="elmu8kyfg8pi">
                    <path
                        style={{
                            WebkitTransformOrigin: 274.125,
                            MsTransformOrigin: 274.125,
                            transformOrigin: 274.125,
                        }}
                        id="elr9dnfgjj6n"
                        d="M261.48 227.58L286.7 222.19 286.77 225.99 261.48 227.58z"
                        className="animable"
                        opacity="0.1"
                    ></path>
                </g>
                <path
                    d="M264.5 158.59l2.87 7.58a81.3 81.3 0 0012.52-11.41l-4.16-6.93s-3.81 7.58-11.23 10.76z"
                    style={{
                        WebkitTransformOrigin: 272.195,
                        MsTransformOrigin: 272.195,
                        transformOrigin: 272.195,
                    }}
                    id="elp8etpvv05q"
                    fill="#FAFAFA"
                    className="animable"
                ></path>
                <path
                    d="M262.4 150.3a19.65 19.65 0 002.1 8.29l-3.13 7a15.21 15.21 0 01-4.3-12.31z"
                    style={{
                        WebkitTransformOrigin: 260.742,
                        MsTransformOrigin: 260.742,
                        transformOrigin: 260.742,
                    }}
                    id="eleetvufkcllb"
                    fill="#FAFAFA"
                    className="animable"
                ></path>
            </g>
            <defs>
                <filter id="active" height="200%">
                    <feMorphology
                        in="SourceAlpha"
                        operator="dilate"
                        radius="2"
                        result="DILATED"
                    ></feMorphology>
                    <feFlood
                        floodColor="#32DFEC"
                        floodOpacity="1"
                        result="PINK"
                    ></feFlood>
                    <feComposite
                        in="PINK"
                        in2="DILATED"
                        operator="in"
                        result="OUTLINE"
                    ></feComposite>
                    <feMerge>
                        <feMergeNode in="OUTLINE"></feMergeNode>
                        <feMergeNode in="SourceGraphic"></feMergeNode>
                    </feMerge>
                </filter>
                <filter id="hover" height="200%">
                    <feMorphology
                        in="SourceAlpha"
                        operator="dilate"
                        radius="2"
                        result="DILATED"
                    ></feMorphology>
                    <feFlood floodColor="red" floodOpacity="0.5" result="PINK"></feFlood>
                    <feComposite
                        in="PINK"
                        in2="DILATED"
                        operator="in"
                        result="OUTLINE"
                    ></feComposite>
                    <feMerge>
                        <feMergeNode in="OUTLINE"></feMergeNode>
                        <feMergeNode in="SourceGraphic"></feMergeNode>
                    </feMerge>
                    <feColorMatrix values="0 0 0 0 0 0 1 0 0 0 0 0 0 0 0 0 0 0 1 0"></feColorMatrix>
                </filter>
            </defs>
        </svg>
    );
}
