import React from "react";

import "./styles.scss";

export default function Running() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            id="freepik_stories-healthy-habit"
            className="animated"
            version="1.1"
            viewBox="0 0 500 500"
        >
            <g
                id="freepik--background-complete--inject-3"
                style={{
                    WebkitTransformOrigin: 250,
                    MsTransformOrigin: 250,
                    transformOrigin: 250,
                }}
                className="animable animator-hidden"
            >
                <path
                    style={{
                        WebkitTransformOrigin: 340.18,
                        MsTransformOrigin: 340.18,
                        transformOrigin: 340.18,
                    }}
                    id="elnkse9nwt79"
                    fill="#EBEBEB"
                    d="M464.68 244L464.68 357.32 215.68 357.32 215.68 266.83 237.28 266.83 237.28 293.07 242.84 293.07 242.84 247.22 270.31 247.22 270.31 290.74 273.94 290.74 273.94 235.54 297.81 235.54 297.81 250.69 310.06 250.69 310.06 269.54 313.76 269.54 313.76 257.69 335.47 257.69 335.47 293.06 341.81 293.06 341.81 264.31 361.66 264.31 361.66 242.46 375.16 242.46 375.16 230.03 390.77 230.03 390.77 285.19 396.02 285.19 396.02 262.17 421.74 262.17 421.74 295.13 425.3 295.13 425.3 268.23 451.38 268.23 451.38 244 464.68 244z"
                    className="animable"
                ></path>
                <path
                    style={{
                        WebkitTransformOrigin: 220.145,
                        MsTransformOrigin: 220.145,
                        transformOrigin: 220.145,
                    }}
                    id="elyn0voka0ik"
                    fill="#FFF"
                    d="M218.32 270.13H221.97V275.15H218.32z"
                    className="animable"
                ></path>
                <path
                    style={{
                        WebkitTransformOrigin: 226.485,
                        MsTransformOrigin: 226.485,
                        transformOrigin: 226.485,
                    }}
                    id="el5kf7ednbnfo"
                    fill="#FFF"
                    d="M224.66 270.13H228.31V275.15H224.66z"
                    className="animable"
                ></path>
                <path
                    style={{
                        WebkitTransformOrigin: 232.815,
                        MsTransformOrigin: 232.815,
                        transformOrigin: 232.815,
                    }}
                    id="el53vbabvcnxw"
                    fill="#FFF"
                    d="M230.99 270.13H234.64000000000001V275.15H230.99z"
                    className="animable"
                ></path>
                <path
                    style={{
                        WebkitTransformOrigin: 220.145,
                        MsTransformOrigin: 220.145,
                        transformOrigin: 220.145,
                    }}
                    id="elsx1l0crcdq"
                    fill="#FFF"
                    d="M218.32 277.68H221.97V282.7H218.32z"
                    className="animable"
                ></path>
                <path
                    style={{
                        WebkitTransformOrigin: 226.485,
                        MsTransformOrigin: 226.485,
                        transformOrigin: 226.485,
                    }}
                    id="elkhawkvd0hi"
                    fill="#FFF"
                    d="M224.66 277.68H228.31V282.7H224.66z"
                    className="animable"
                ></path>
                <path
                    style={{
                        WebkitTransformOrigin: 232.815,
                        MsTransformOrigin: 232.815,
                        transformOrigin: 232.815,
                    }}
                    id="elgqh55gm5xm"
                    fill="#FFF"
                    d="M230.99 277.68H234.64000000000001V282.7H230.99z"
                    className="animable"
                ></path>
                <path
                    style={{
                        WebkitTransformOrigin: 220.145,
                        MsTransformOrigin: 220.145,
                        transformOrigin: 220.145,
                    }}
                    id="el2bhe95c2nmn"
                    fill="#FFF"
                    d="M218.32 285.23H221.97V290.25H218.32z"
                    className="animable"
                ></path>
                <path
                    style={{
                        WebkitTransformOrigin: 226.485,
                        MsTransformOrigin: 226.485,
                        transformOrigin: 226.485,
                    }}
                    id="elbji6j3re3x6"
                    fill="#FFF"
                    d="M224.66 285.23H228.31V290.25H224.66z"
                    className="animable"
                ></path>
                <path
                    style={{
                        WebkitTransformOrigin: 232.815,
                        MsTransformOrigin: 232.815,
                        transformOrigin: 232.815,
                    }}
                    id="elmeftfiudjt"
                    fill="#FFF"
                    d="M230.99 285.23H234.64000000000001V290.25H230.99z"
                    className="animable"
                ></path>
                <path
                    style={{
                        WebkitTransformOrigin: 220.145,
                        MsTransformOrigin: 220.145,
                        transformOrigin: 220.145,
                    }}
                    id="el6cb0mkeyvu9"
                    fill="#FFF"
                    d="M218.32 292.77H221.97V297.78999999999996H218.32z"
                    className="animable"
                ></path>
                <path
                    style={{
                        WebkitTransformOrigin: 226.485,
                        MsTransformOrigin: 226.485,
                        transformOrigin: 226.485,
                    }}
                    id="elz8bbynweyia"
                    fill="#FFF"
                    d="M224.66 292.77H228.31V297.78999999999996H224.66z"
                    className="animable"
                ></path>
                <path
                    style={{
                        WebkitTransformOrigin: 232.815,
                        MsTransformOrigin: 232.815,
                        transformOrigin: 232.815,
                    }}
                    id="el7bfkuny7idl"
                    fill="#FFF"
                    d="M230.99 292.77H234.64000000000001V297.78999999999996H230.99z"
                    className="animable"
                ></path>
                <path
                    style={{
                        WebkitTransformOrigin: 346.685,
                        MsTransformOrigin: 346.685,
                        transformOrigin: 346.685,
                    }}
                    id="el0nfwc1oeogr"
                    fill="#FFF"
                    d="M344.86 267.89H348.51V272.07H344.86z"
                    className="animable"
                ></path>
                <path
                    style={{
                        WebkitTransformOrigin: 353.025,
                        MsTransformOrigin: 353.025,
                        transformOrigin: 353.025,
                    }}
                    id="elqcb09b10wl"
                    fill="#FFF"
                    d="M351.2 267.89H354.84999999999997V272.07H351.2z"
                    className="animable"
                ></path>
                <path
                    style={{
                        WebkitTransformOrigin: 359.355,
                        MsTransformOrigin: 359.355,
                        transformOrigin: 359.355,
                    }}
                    id="elvbyu5np03f"
                    fill="#FFF"
                    d="M357.53 267.89H361.17999999999995V272.07H357.53z"
                    className="animable"
                ></path>
                <path
                    style={{
                        WebkitTransformOrigin: 346.685,
                        MsTransformOrigin: 346.685,
                        transformOrigin: 346.685,
                    }}
                    id="elfqus0nhcqok"
                    fill="#FFF"
                    d="M344.86 274.18H348.51V278.36H344.86z"
                    className="animable"
                ></path>
                <path
                    style={{
                        WebkitTransformOrigin: 353.025,
                        MsTransformOrigin: 353.025,
                        transformOrigin: 353.025,
                    }}
                    id="elopv0500yprd"
                    fill="#FFF"
                    d="M351.2 274.18H354.84999999999997V278.36H351.2z"
                    className="animable"
                ></path>
                <path
                    style={{
                        WebkitTransformOrigin: 359.355,
                        MsTransformOrigin: 359.355,
                        transformOrigin: 359.355,
                    }}
                    id="elledxgbssv78"
                    fill="#FFF"
                    d="M357.53 274.18H361.17999999999995V278.36H357.53z"
                    className="animable"
                ></path>
                <path
                    style={{
                        WebkitTransformOrigin: 346.685,
                        MsTransformOrigin: 346.685,
                        transformOrigin: 346.685,
                    }}
                    id="elvve6c5shfk"
                    fill="#FFF"
                    d="M344.86 280.48H348.51V284.66H344.86z"
                    className="animable"
                ></path>
                <path
                    style={{
                        WebkitTransformOrigin: 353.025,
                        MsTransformOrigin: 353.025,
                        transformOrigin: 353.025,
                    }}
                    id="el2l8rdy4t7lk"
                    fill="#FFF"
                    d="M351.2 280.48H354.84999999999997V284.66H351.2z"
                    className="animable"
                ></path>
                <path
                    style={{
                        WebkitTransformOrigin: 359.355,
                        MsTransformOrigin: 359.355,
                        transformOrigin: 359.355,
                    }}
                    id="elm469wqdksbc"
                    fill="#FFF"
                    d="M357.53 280.48H361.17999999999995V284.66H357.53z"
                    className="animable"
                ></path>
                <path
                    style={{
                        WebkitTransformOrigin: 346.685,
                        MsTransformOrigin: 346.685,
                        transformOrigin: 346.685,
                    }}
                    id="el1x12495mk8uh"
                    fill="#FFF"
                    d="M344.86 286.77H348.51V290.95H344.86z"
                    className="animable"
                ></path>
                <path
                    style={{
                        WebkitTransformOrigin: 353.025,
                        MsTransformOrigin: 353.025,
                        transformOrigin: 353.025,
                    }}
                    id="elpxcwz031ini"
                    fill="#FFF"
                    d="M351.2 286.77H354.84999999999997V290.95H351.2z"
                    className="animable"
                ></path>
                <path
                    style={{
                        WebkitTransformOrigin: 359.355,
                        MsTransformOrigin: 359.355,
                        transformOrigin: 359.355,
                    }}
                    id="el3moc31036gq"
                    fill="#FFF"
                    d="M357.53 286.77H361.17999999999995V290.95H357.53z"
                    className="animable"
                ></path>
                <path
                    style={{
                        WebkitTransformOrigin: 346.685,
                        MsTransformOrigin: 346.685,
                        transformOrigin: 346.685,
                    }}
                    id="el4459bl4i80a"
                    fill="#FFF"
                    d="M344.86 293.23H348.51V297.41H344.86z"
                    className="animable"
                ></path>
                <path
                    style={{
                        WebkitTransformOrigin: 353.025,
                        MsTransformOrigin: 353.025,
                        transformOrigin: 353.025,
                    }}
                    id="eloa4pe40q49a"
                    fill="#FFF"
                    d="M351.2 293.23H354.84999999999997V297.41H351.2z"
                    className="animable"
                ></path>
                <path
                    style={{
                        WebkitTransformOrigin: 359.355,
                        MsTransformOrigin: 359.355,
                        transformOrigin: 359.355,
                    }}
                    id="elofmpri3hmc"
                    fill="#FFF"
                    d="M357.53 293.23H361.17999999999995V297.41H357.53z"
                    className="animable"
                ></path>
                <path
                    style={{
                        WebkitTransformOrigin: 346.685,
                        MsTransformOrigin: 346.685,
                        transformOrigin: 346.685,
                    }}
                    id="el8w5ati4b8xs"
                    fill="#FFF"
                    d="M344.86 299.68H348.51V303.86H344.86z"
                    className="animable"
                ></path>
                <path
                    style={{
                        WebkitTransformOrigin: 353.025,
                        MsTransformOrigin: 353.025,
                        transformOrigin: 353.025,
                    }}
                    id="el61mvgoqi0wy"
                    fill="#FFF"
                    d="M351.2 299.68H354.84999999999997V303.86H351.2z"
                    className="animable"
                ></path>
                <path
                    style={{
                        WebkitTransformOrigin: 359.355,
                        MsTransformOrigin: 359.355,
                        transformOrigin: 359.355,
                    }}
                    id="el6fuyyqp2q64"
                    fill="#FFF"
                    d="M357.53 299.68H361.17999999999995V303.86H357.53z"
                    className="animable"
                ></path>
                <path
                    style={{
                        WebkitTransformOrigin: 346.685,
                        MsTransformOrigin: 346.685,
                        transformOrigin: 346.685,
                    }}
                    id="eloth4u5tlmi"
                    fill="#FFF"
                    d="M344.86 306.13H348.51V310.31H344.86z"
                    className="animable"
                ></path>
                <path
                    style={{
                        WebkitTransformOrigin: 353.025,
                        MsTransformOrigin: 353.025,
                        transformOrigin: 353.025,
                    }}
                    id="els586ybhlw1m"
                    fill="#FFF"
                    d="M351.2 306.13H354.84999999999997V310.31H351.2z"
                    className="animable"
                ></path>
                <path
                    style={{
                        WebkitTransformOrigin: 359.355,
                        MsTransformOrigin: 359.355,
                        transformOrigin: 359.355,
                    }}
                    id="elxjty6h6itm"
                    fill="#FFF"
                    d="M357.53 306.13H361.17999999999995V310.31H357.53z"
                    className="animable"
                ></path>
                <path
                    style={{
                        WebkitTransformOrigin: 346.685,
                        MsTransformOrigin: 346.685,
                        transformOrigin: 346.685,
                    }}
                    id="eltogmzp551r"
                    fill="#FFF"
                    d="M344.86 312.58H348.51V316.76H344.86z"
                    className="animable"
                ></path>
                <path
                    style={{
                        WebkitTransformOrigin: 353.025,
                        MsTransformOrigin: 353.025,
                        transformOrigin: 353.025,
                    }}
                    id="eloohkwhctckc"
                    fill="#FFF"
                    d="M351.2 312.58H354.84999999999997V316.76H351.2z"
                    className="animable"
                ></path>
                <path
                    style={{
                        WebkitTransformOrigin: 359.355,
                        MsTransformOrigin: 359.355,
                        transformOrigin: 359.355,
                    }}
                    id="elrshlsufgpd"
                    fill="#FFF"
                    d="M357.53 312.58H361.17999999999995V316.76H357.53z"
                    className="animable"
                ></path>
                <path
                    style={{
                        WebkitTransformOrigin: 256.48,
                        MsTransformOrigin: 256.48,
                        transformOrigin: 256.48,
                    }}
                    id="elnyvilhninm"
                    fill="#FFF"
                    d="M245.96 250.43H267V253.57H245.96z"
                    className="animable"
                ></path>
                <path
                    style={{
                        WebkitTransformOrigin: 256.48,
                        MsTransformOrigin: 256.48,
                        transformOrigin: 256.48,
                    }}
                    id="elq9gzltlfl6"
                    fill="#FFF"
                    d="M245.96 256.52H267V259.65999999999997H245.96z"
                    className="animable"
                ></path>
                <path
                    style={{
                        WebkitTransformOrigin: 256.48,
                        MsTransformOrigin: 256.48,
                        transformOrigin: 256.48,
                    }}
                    id="elj3i6pin45hk"
                    fill="#FFF"
                    d="M245.96 262.61H267V265.75H245.96z"
                    className="animable"
                ></path>
                <path
                    style={{
                        WebkitTransformOrigin: 256.48,
                        MsTransformOrigin: 256.48,
                        transformOrigin: 256.48,
                    }}
                    id="elu09gkzhr36r"
                    fill="#FFF"
                    d="M245.96 268.69H267V271.83H245.96z"
                    className="animable"
                ></path>
                <path
                    style={{
                        WebkitTransformOrigin: 256.48,
                        MsTransformOrigin: 256.48,
                        transformOrigin: 256.48,
                    }}
                    id="ellyzicthnx9"
                    fill="#FFF"
                    d="M245.96 274.78H267V277.91999999999996H245.96z"
                    className="animable"
                ></path>
                <path
                    style={{
                        WebkitTransformOrigin: 256.48,
                        MsTransformOrigin: 256.48,
                        transformOrigin: 256.48,
                    }}
                    id="elgfvegkmy9wi"
                    fill="#FFF"
                    d="M245.96 280.87H267V284.01H245.96z"
                    className="animable"
                ></path>
                <path
                    style={{
                        WebkitTransformOrigin: 256.48,
                        MsTransformOrigin: 256.48,
                        transformOrigin: 256.48,
                    }}
                    id="el3nyfmqjylmx"
                    fill="#FFF"
                    d="M245.96 286.95H267V290.09H245.96z"
                    className="animable"
                ></path>
                <path
                    style={{
                        WebkitTransformOrigin: 256.48,
                        MsTransformOrigin: 256.48,
                        transformOrigin: 256.48,
                    }}
                    id="el6ub2rnf7zvw"
                    fill="#FFF"
                    d="M245.96 293.04H267V296.18H245.96z"
                    className="animable"
                ></path>
                <path
                    style={{
                        WebkitTransformOrigin: 408.8,
                        MsTransformOrigin: 408.8,
                        transformOrigin: 408.8,
                    }}
                    id="elcw7d56naed5"
                    fill="#FFF"
                    d="M399.13 265.1H418.46999999999997V268.24H399.13z"
                    className="animable"
                ></path>
                <path
                    style={{
                        WebkitTransformOrigin: 408.8,
                        MsTransformOrigin: 408.8,
                        transformOrigin: 408.8,
                    }}
                    id="elyljbr9u2q1i"
                    fill="#FFF"
                    d="M399.13 271.18H418.46999999999997V274.32H399.13z"
                    className="animable"
                ></path>
                <path
                    style={{
                        WebkitTransformOrigin: 408.8,
                        MsTransformOrigin: 408.8,
                        transformOrigin: 408.8,
                    }}
                    id="elj3297s6r1d8"
                    fill="#FFF"
                    d="M399.13 277.27H418.46999999999997V280.40999999999997H399.13z"
                    className="animable"
                ></path>
                <path
                    style={{
                        WebkitTransformOrigin: 408.8,
                        MsTransformOrigin: 408.8,
                        transformOrigin: 408.8,
                    }}
                    id="elygr8wfo28a"
                    fill="#FFF"
                    d="M399.13 283.36H418.46999999999997V286.5H399.13z"
                    className="animable"
                ></path>
                <path
                    style={{
                        WebkitTransformOrigin: 408.8,
                        MsTransformOrigin: 408.8,
                        transformOrigin: 408.8,
                    }}
                    id="ely3zgvp6kwmp"
                    fill="#FFF"
                    d="M399.13 289.44H418.46999999999997V292.58H399.13z"
                    className="animable"
                ></path>
                <path
                    style={{
                        WebkitTransformOrigin: 408.8,
                        MsTransformOrigin: 408.8,
                        transformOrigin: 408.8,
                    }}
                    id="el8ujfix62r6e"
                    fill="#FFF"
                    d="M399.13 295.53H418.46999999999997V298.66999999999996H399.13z"
                    className="animable"
                ></path>
                <path
                    style={{
                        WebkitTransformOrigin: 408.8,
                        MsTransformOrigin: 408.8,
                        transformOrigin: 408.8,
                    }}
                    id="elt89jc9jxsns"
                    fill="#FFF"
                    d="M399.13 301.62H418.46999999999997V304.76H399.13z"
                    className="animable"
                ></path>
                <path
                    style={{
                        WebkitTransformOrigin: 408.8,
                        MsTransformOrigin: 408.8,
                        transformOrigin: 408.8,
                    }}
                    id="el0tx8w0vbfls"
                    fill="#FFF"
                    d="M399.13 307.7H418.46999999999997V310.84H399.13z"
                    className="animable"
                ></path>
                <path
                    style={{
                        WebkitTransformOrigin: 408.8,
                        MsTransformOrigin: 408.8,
                        transformOrigin: 408.8,
                    }}
                    id="el9jmqzdb16yv"
                    fill="#FFF"
                    d="M399.13 313.48H418.46999999999997V316.62H399.13z"
                    className="animable"
                ></path>
                <path
                    style={{
                        WebkitTransformOrigin: 408.8,
                        MsTransformOrigin: 408.8,
                        transformOrigin: 408.8,
                    }}
                    id="elhjlw1vcmp7v"
                    fill="#FFF"
                    d="M399.13 319.27H418.46999999999997V322.40999999999997H399.13z"
                    className="animable"
                ></path>
                <path
                    style={{
                        WebkitTransformOrigin: 408.8,
                        MsTransformOrigin: 408.8,
                        transformOrigin: 408.8,
                    }}
                    id="els5gt7u6s2sh"
                    fill="#FFF"
                    d="M399.13 325.05H418.46999999999997V328.19H399.13z"
                    className="animable"
                ></path>
                <path
                    style={{
                        WebkitTransformOrigin: 408.8,
                        MsTransformOrigin: 408.8,
                        transformOrigin: 408.8,
                    }}
                    id="el8t79k3lfjdq"
                    fill="#FFF"
                    d="M399.13 330.83H418.46999999999997V333.96999999999997H399.13z"
                    className="animable"
                ></path>
                <path
                    style={{
                        WebkitTransformOrigin: 408.8,
                        MsTransformOrigin: 408.8,
                        transformOrigin: 408.8,
                    }}
                    id="elhc05mpge7kl"
                    fill="#FFF"
                    d="M399.13 336.62H418.46999999999997V339.76H399.13z"
                    className="animable"
                ></path>
                <path
                    style={{
                        WebkitTransformOrigin: 439.535,
                        MsTransformOrigin: 439.535,
                        transformOrigin: 439.535,
                    }}
                    id="elphxw203zyo"
                    fill="#FFF"
                    d="M429.32 272.45H449.75V275.59H429.32z"
                    className="animable"
                ></path>
                <path
                    style={{
                        WebkitTransformOrigin: 439.535,
                        MsTransformOrigin: 439.535,
                        transformOrigin: 439.535,
                    }}
                    id="elr58igot30md"
                    fill="#FFF"
                    d="M429.32 278.53H449.75V281.66999999999996H429.32z"
                    className="animable"
                ></path>
                <path
                    style={{
                        WebkitTransformOrigin: 439.535,
                        MsTransformOrigin: 439.535,
                        transformOrigin: 439.535,
                    }}
                    id="el6wa4yz5njbc"
                    fill="#FFF"
                    d="M429.32 284.62H449.75V287.76H429.32z"
                    className="animable"
                ></path>
                <path
                    style={{
                        WebkitTransformOrigin: 439.535,
                        MsTransformOrigin: 439.535,
                        transformOrigin: 439.535,
                    }}
                    id="elioc9zothukh"
                    fill="#FFF"
                    d="M429.32 290.71H449.75V293.84999999999997H429.32z"
                    className="animable"
                ></path>
                <path
                    style={{
                        WebkitTransformOrigin: 439.535,
                        MsTransformOrigin: 439.535,
                        transformOrigin: 439.535,
                    }}
                    id="el3d76mehd0m6"
                    fill="#FFF"
                    d="M429.32 296.79H449.75V299.93H429.32z"
                    className="animable"
                ></path>
                <path
                    style={{
                        WebkitTransformOrigin: 439.535,
                        MsTransformOrigin: 439.535,
                        transformOrigin: 439.535,
                    }}
                    id="eln2v5h3lgbb"
                    fill="#FFF"
                    d="M429.32 302.88H449.75V306.02H429.32z"
                    className="animable"
                ></path>
                <path
                    style={{
                        WebkitTransformOrigin: 439.535,
                        MsTransformOrigin: 439.535,
                        transformOrigin: 439.535,
                    }}
                    id="el8vmvp7yznwy"
                    fill="#FFF"
                    d="M429.32 308.97H449.75V312.11H429.32z"
                    className="animable"
                ></path>
                <path
                    style={{
                        WebkitTransformOrigin: 439.535,
                        MsTransformOrigin: 439.535,
                        transformOrigin: 439.535,
                    }}
                    id="elq593ijr9sap"
                    fill="#FFF"
                    d="M429.32 315.05H449.75V318.19H429.32z"
                    className="animable"
                ></path>
                <path
                    style={{
                        WebkitTransformOrigin: 439.535,
                        MsTransformOrigin: 439.535,
                        transformOrigin: 439.535,
                    }}
                    id="el1b8hb2lnpov"
                    fill="#FFF"
                    d="M429.32 320.5H449.75V323.64H429.32z"
                    className="animable"
                ></path>
                <path
                    style={{
                        WebkitTransformOrigin: 439.535,
                        MsTransformOrigin: 439.535,
                        transformOrigin: 439.535,
                    }}
                    id="elw3qr4qv41fr"
                    fill="#FFF"
                    d="M429.32 325.95H449.75V329.09H429.32z"
                    className="animable"
                ></path>
                <path
                    style={{
                        WebkitTransformOrigin: 439.535,
                        MsTransformOrigin: 439.535,
                        transformOrigin: 439.535,
                    }}
                    id="elsc9aavj18qo"
                    fill="#FFF"
                    d="M429.32 331.4H449.75V334.53999999999996H429.32z"
                    className="animable"
                ></path>
                <path
                    style={{
                        WebkitTransformOrigin: 323.36,
                        MsTransformOrigin: 323.36,
                        transformOrigin: 323.36,
                    }}
                    id="elncptpjobu7g"
                    fill="#FFF"
                    d="M320.11 260.53H326.61V263.55999999999995H320.11z"
                    className="animable"
                ></path>
                <path
                    style={{
                        WebkitTransformOrigin: 323.36,
                        MsTransformOrigin: 323.36,
                        transformOrigin: 323.36,
                    }}
                    id="el1vrzlhqkcw5"
                    fill="#FFF"
                    d="M320.11 266.4H326.61V269.42999999999995H320.11z"
                    className="animable"
                ></path>
                <path
                    style={{
                        WebkitTransformOrigin: 323.36,
                        MsTransformOrigin: 323.36,
                        transformOrigin: 323.36,
                    }}
                    id="eli454iluypt"
                    fill="#FFF"
                    d="M320.11 272.28H326.61V275.30999999999995H320.11z"
                    className="animable"
                ></path>
                <path
                    style={{
                        WebkitTransformOrigin: 323.36,
                        MsTransformOrigin: 323.36,
                        transformOrigin: 323.36,
                    }}
                    id="eltme56gc8kz"
                    fill="#FFF"
                    d="M320.11 278.16H326.61V281.19H320.11z"
                    className="animable"
                ></path>
                <path
                    style={{
                        WebkitTransformOrigin: 323.36,
                        MsTransformOrigin: 323.36,
                        transformOrigin: 323.36,
                    }}
                    id="elvh3qmhnh26r"
                    fill="#FFF"
                    d="M320.11 284.04H326.61V287.07H320.11z"
                    className="animable"
                ></path>
                <path
                    style={{
                        WebkitTransformOrigin: 323.36,
                        MsTransformOrigin: 323.36,
                        transformOrigin: 323.36,
                    }}
                    id="el1oywn62clsa"
                    fill="#FFF"
                    d="M320.11 289.92H326.61V292.95H320.11z"
                    className="animable"
                ></path>
                <path
                    style={{
                        WebkitTransformOrigin: 323.36,
                        MsTransformOrigin: 323.36,
                        transformOrigin: 323.36,
                    }}
                    id="els40dfd0daxd"
                    fill="#FFF"
                    d="M320.11 295.79H326.61V298.82H320.11z"
                    className="animable"
                ></path>
                <path
                    style={{
                        WebkitTransformOrigin: 323.36,
                        MsTransformOrigin: 323.36,
                        transformOrigin: 323.36,
                    }}
                    id="elb5h1ipfk87p"
                    fill="#FFF"
                    d="M320.11 301.67H326.61V304.7H320.11z"
                    className="animable"
                ></path>
                <path
                    style={{
                        WebkitTransformOrigin: 332.2,
                        MsTransformOrigin: 332.2,
                        transformOrigin: 332.2,
                    }}
                    id="el7ant7pged2p"
                    fill="#FFF"
                    d="M328.95 260.53H335.45V263.55999999999995H328.95z"
                    className="animable"
                ></path>
                <path
                    style={{
                        WebkitTransformOrigin: 332.2,
                        MsTransformOrigin: 332.2,
                        transformOrigin: 332.2,
                    }}
                    id="eliqbxe01t32"
                    fill="#FFF"
                    d="M328.95 266.4H335.45V269.42999999999995H328.95z"
                    className="animable"
                ></path>
                <path
                    style={{
                        WebkitTransformOrigin: 332.2,
                        MsTransformOrigin: 332.2,
                        transformOrigin: 332.2,
                    }}
                    id="elxvxrh0j3y7e"
                    fill="#FFF"
                    d="M328.95 272.28H335.45V275.30999999999995H328.95z"
                    className="animable"
                ></path>
                <path
                    style={{
                        WebkitTransformOrigin: 332.2,
                        MsTransformOrigin: 332.2,
                        transformOrigin: 332.2,
                    }}
                    id="elx356tesr13o"
                    fill="#FFF"
                    d="M328.95 278.16H335.45V281.19H328.95z"
                    className="animable"
                ></path>
                <path
                    style={{
                        WebkitTransformOrigin: 332.2,
                        MsTransformOrigin: 332.2,
                        transformOrigin: 332.2,
                    }}
                    id="el9gbw861n5vp"
                    fill="#FFF"
                    d="M328.95 284.04H335.45V287.07H328.95z"
                    className="animable"
                ></path>
                <path
                    style={{
                        WebkitTransformOrigin: 332.2,
                        MsTransformOrigin: 332.2,
                        transformOrigin: 332.2,
                    }}
                    id="elwiau9zcrac"
                    fill="#FFF"
                    d="M328.95 289.92H335.45V292.95H328.95z"
                    className="animable"
                ></path>
                <path
                    style={{
                        WebkitTransformOrigin: 332.2,
                        MsTransformOrigin: 332.2,
                        transformOrigin: 332.2,
                    }}
                    id="el1kn11cqcfq6"
                    fill="#FFF"
                    d="M328.95 295.79H335.45V298.82H328.95z"
                    className="animable"
                ></path>
                <path
                    style={{
                        WebkitTransformOrigin: 332.2,
                        MsTransformOrigin: 332.2,
                        transformOrigin: 332.2,
                    }}
                    id="elynhuki05a6e"
                    fill="#FFF"
                    d="M328.95 301.67H335.45V304.7H328.95z"
                    className="animable"
                ></path>
                <path
                    style={{
                        WebkitTransformOrigin: 280.945,
                        MsTransformOrigin: 280.945,
                        transformOrigin: 280.945,
                    }}
                    id="elrevukidhtia"
                    fill="#FFF"
                    d="M277.56 238.72H284.33V243.5H277.56z"
                    className="animable"
                ></path>
                <path
                    style={{
                        WebkitTransformOrigin: 291.045,
                        MsTransformOrigin: 291.045,
                        transformOrigin: 291.045,
                    }}
                    id="elg6sq0ih61ch"
                    fill="#FFF"
                    d="M287.66 238.72H294.43V243.5H287.66z"
                    className="animable"
                ></path>
                <path
                    style={{
                        WebkitTransformOrigin: 280.945,
                        MsTransformOrigin: 280.945,
                        transformOrigin: 280.945,
                    }}
                    id="elqw3xqakj8nj"
                    fill="#FFF"
                    d="M277.56 245.74H284.33V250.52H277.56z"
                    className="animable"
                ></path>
                <path
                    style={{
                        WebkitTransformOrigin: 291.045,
                        MsTransformOrigin: 291.045,
                        transformOrigin: 291.045,
                    }}
                    id="el6oapijl0sk8"
                    fill="#FFF"
                    d="M287.66 245.74H294.43V250.52H287.66z"
                    className="animable"
                ></path>
                <path
                    style={{
                        WebkitTransformOrigin: 280.945,
                        MsTransformOrigin: 280.945,
                        transformOrigin: 280.945,
                    }}
                    id="elgwhrbnrhw0k"
                    fill="#FFF"
                    d="M277.56 252.76H284.33V257.53999999999996H277.56z"
                    className="animable"
                ></path>
                <path
                    style={{
                        WebkitTransformOrigin: 291.045,
                        MsTransformOrigin: 291.045,
                        transformOrigin: 291.045,
                    }}
                    id="elyloieijhcq7"
                    fill="#FFF"
                    d="M287.66 252.76H294.43V257.53999999999996H287.66z"
                    className="animable"
                ></path>
                <path
                    style={{
                        WebkitTransformOrigin: 280.945,
                        MsTransformOrigin: 280.945,
                        transformOrigin: 280.945,
                    }}
                    id="el3k8wwpdflpv"
                    fill="#FFF"
                    d="M277.56 259.77H284.33V264.54999999999995H277.56z"
                    className="animable"
                ></path>
                <path
                    style={{
                        WebkitTransformOrigin: 291.045,
                        MsTransformOrigin: 291.045,
                        transformOrigin: 291.045,
                    }}
                    id="el1kahpcdfkt7"
                    fill="#FFF"
                    d="M287.66 259.77H294.43V264.54999999999995H287.66z"
                    className="animable"
                ></path>
                <path
                    style={{
                        WebkitTransformOrigin: 280.945,
                        MsTransformOrigin: 280.945,
                        transformOrigin: 280.945,
                    }}
                    id="elo3ppud2jt4k"
                    fill="#FFF"
                    d="M277.56 266.79H284.33V271.57H277.56z"
                    className="animable"
                ></path>
                <path
                    style={{
                        WebkitTransformOrigin: 291.045,
                        MsTransformOrigin: 291.045,
                        transformOrigin: 291.045,
                    }}
                    id="elq736z6ufmaq"
                    fill="#FFF"
                    d="M287.66 266.79H294.43V271.57H287.66z"
                    className="animable"
                ></path>
                <path
                    style={{
                        WebkitTransformOrigin: 280.945,
                        MsTransformOrigin: 280.945,
                        transformOrigin: 280.945,
                    }}
                    id="elltadmortzn"
                    fill="#FFF"
                    d="M277.56 273.81H284.33V278.59H277.56z"
                    className="animable"
                ></path>
                <path
                    style={{
                        WebkitTransformOrigin: 291.045,
                        MsTransformOrigin: 291.045,
                        transformOrigin: 291.045,
                    }}
                    id="elp23hdtsdzdi"
                    fill="#FFF"
                    d="M287.66 273.81H294.43V278.59H287.66z"
                    className="animable"
                ></path>
                <path
                    style={{
                        WebkitTransformOrigin: 280.945,
                        MsTransformOrigin: 280.945,
                        transformOrigin: 280.945,
                    }}
                    id="elblsfr2vtg04"
                    fill="#FFF"
                    d="M277.56 280.83H284.33V285.60999999999996H277.56z"
                    className="animable"
                ></path>
                <path
                    style={{
                        WebkitTransformOrigin: 291.045,
                        MsTransformOrigin: 291.045,
                        transformOrigin: 291.045,
                    }}
                    id="elfyoufw2tcvj"
                    fill="#FFF"
                    d="M287.66 280.83H294.43V285.60999999999996H287.66z"
                    className="animable"
                ></path>
                <path
                    style={{
                        WebkitTransformOrigin: 280.945,
                        MsTransformOrigin: 280.945,
                        transformOrigin: 280.945,
                    }}
                    id="elwoofrob6eq"
                    fill="#FFF"
                    d="M277.56 287.84H284.33V292.61999999999995H277.56z"
                    className="animable"
                ></path>
                <path
                    style={{
                        WebkitTransformOrigin: 291.045,
                        MsTransformOrigin: 291.045,
                        transformOrigin: 291.045,
                    }}
                    id="elgeiu6xpfex7"
                    fill="#FFF"
                    d="M287.66 287.84H294.43V292.61999999999995H287.66z"
                    className="animable"
                ></path>
                <path
                    style={{
                        WebkitTransformOrigin: 303.545,
                        MsTransformOrigin: 303.545,
                        transformOrigin: 303.545,
                    }}
                    id="elrbdg2vzmc8i"
                    fill="#FFF"
                    d="M300.16 252.76H306.93V257.53999999999996H300.16z"
                    className="animable"
                ></path>
                <path
                    style={{
                        WebkitTransformOrigin: 303.545,
                        MsTransformOrigin: 303.545,
                        transformOrigin: 303.545,
                    }}
                    id="ela7xcw47ys2"
                    fill="#FFF"
                    d="M300.16 259.77H306.93V264.54999999999995H300.16z"
                    className="animable"
                ></path>
                <path
                    style={{
                        WebkitTransformOrigin: 303.545,
                        MsTransformOrigin: 303.545,
                        transformOrigin: 303.545,
                    }}
                    id="elp3e65edq1cd"
                    fill="#FFF"
                    d="M300.16 266.79H306.93V271.57H300.16z"
                    className="animable"
                ></path>
                <path
                    style={{
                        WebkitTransformOrigin: 303.545,
                        MsTransformOrigin: 303.545,
                        transformOrigin: 303.545,
                    }}
                    id="elmrdtucyjgt"
                    fill="#FFF"
                    d="M300.16 273.81H306.93V278.59H300.16z"
                    className="animable"
                ></path>
                <path
                    style={{
                        WebkitTransformOrigin: 303.545,
                        MsTransformOrigin: 303.545,
                        transformOrigin: 303.545,
                    }}
                    id="el0wrdi6wwhwc"
                    fill="#FFF"
                    d="M300.16 280.83H306.93V285.60999999999996H300.16z"
                    className="animable"
                ></path>
                <path
                    style={{
                        WebkitTransformOrigin: 303.545,
                        MsTransformOrigin: 303.545,
                        transformOrigin: 303.545,
                    }}
                    id="els6cfbtrybz"
                    fill="#FFF"
                    d="M300.16 287.84H306.93V292.61999999999995H300.16z"
                    className="animable"
                ></path>
                <path
                    style={{
                        WebkitTransformOrigin: 280.945,
                        MsTransformOrigin: 280.945,
                        transformOrigin: 280.945,
                    }}
                    id="elqj34195lulp"
                    fill="#FFF"
                    d="M277.56 294.78H284.33V299.55999999999995H277.56z"
                    className="animable"
                ></path>
                <path
                    style={{
                        WebkitTransformOrigin: 291.045,
                        MsTransformOrigin: 291.045,
                        transformOrigin: 291.045,
                    }}
                    id="el3vb2lmp1fwt"
                    fill="#FFF"
                    d="M287.66 294.78H294.43V299.55999999999995H287.66z"
                    className="animable"
                ></path>
                <path
                    style={{
                        WebkitTransformOrigin: 303.545,
                        MsTransformOrigin: 303.545,
                        transformOrigin: 303.545,
                    }}
                    id="elo0g3y7cypug"
                    fill="#FFF"
                    d="M300.16 294.78H306.93V299.55999999999995H300.16z"
                    className="animable"
                ></path>
                <path
                    style={{
                        WebkitTransformOrigin: 280.945,
                        MsTransformOrigin: 280.945,
                        transformOrigin: 280.945,
                    }}
                    id="eljvf4z9dl0ye"
                    fill="#FFF"
                    d="M277.56 301.71H284.33V306.48999999999995H277.56z"
                    className="animable"
                ></path>
                <path
                    style={{
                        WebkitTransformOrigin: 291.045,
                        MsTransformOrigin: 291.045,
                        transformOrigin: 291.045,
                    }}
                    id="el27pdx5kf6eih"
                    fill="#FFF"
                    d="M287.66 301.71H294.43V306.48999999999995H287.66z"
                    className="animable"
                ></path>
                <path
                    style={{
                        WebkitTransformOrigin: 303.545,
                        MsTransformOrigin: 303.545,
                        transformOrigin: 303.545,
                    }}
                    id="el8s19n8s9c6i"
                    fill="#FFF"
                    d="M300.16 301.71H306.93V306.48999999999995H300.16z"
                    className="animable"
                ></path>
                <path
                    style={{
                        WebkitTransformOrigin: 280.945,
                        MsTransformOrigin: 280.945,
                        transformOrigin: 280.945,
                    }}
                    id="el4bllot18lfe"
                    fill="#FFF"
                    d="M277.56 308.65H284.33V313.42999999999995H277.56z"
                    className="animable"
                ></path>
                <path
                    style={{
                        WebkitTransformOrigin: 291.045,
                        MsTransformOrigin: 291.045,
                        transformOrigin: 291.045,
                    }}
                    id="elfpub7hpnutt"
                    fill="#FFF"
                    d="M287.66 308.65H294.43V313.42999999999995H287.66z"
                    className="animable"
                ></path>
                <path
                    style={{
                        WebkitTransformOrigin: 303.545,
                        MsTransformOrigin: 303.545,
                        transformOrigin: 303.545,
                    }}
                    id="el3l4ouuymcsr"
                    fill="#FFF"
                    d="M300.16 308.65H306.93V313.42999999999995H300.16z"
                    className="animable"
                ></path>
                <path
                    style={{
                        WebkitTransformOrigin: 280.945,
                        MsTransformOrigin: 280.945,
                        transformOrigin: 280.945,
                    }}
                    id="el61012kjgx75"
                    fill="#FFF"
                    d="M277.56 315.58H284.33V320.35999999999996H277.56z"
                    className="animable"
                ></path>
                <path
                    style={{
                        WebkitTransformOrigin: 291.045,
                        MsTransformOrigin: 291.045,
                        transformOrigin: 291.045,
                    }}
                    id="elm7qflvx26sq"
                    fill="#FFF"
                    d="M287.66 315.58H294.43V320.35999999999996H287.66z"
                    className="animable"
                ></path>
                <path
                    style={{
                        WebkitTransformOrigin: 303.545,
                        MsTransformOrigin: 303.545,
                        transformOrigin: 303.545,
                    }}
                    id="elbr8kboruyv8"
                    fill="#FFF"
                    d="M300.16 315.58H306.93V320.35999999999996H300.16z"
                    className="animable"
                ></path>
                <path
                    style={{
                        WebkitTransformOrigin: 280.945,
                        MsTransformOrigin: 280.945,
                        transformOrigin: 280.945,
                    }}
                    id="elrtkpetk29g"
                    fill="#FFF"
                    d="M277.56 322.52H284.33V327.29999999999995H277.56z"
                    className="animable"
                ></path>
                <path
                    style={{
                        WebkitTransformOrigin: 291.045,
                        MsTransformOrigin: 291.045,
                        transformOrigin: 291.045,
                    }}
                    id="eltausuftd14"
                    fill="#FFF"
                    d="M287.66 322.52H294.43V327.29999999999995H287.66z"
                    className="animable"
                ></path>
                <path
                    style={{
                        WebkitTransformOrigin: 303.545,
                        MsTransformOrigin: 303.545,
                        transformOrigin: 303.545,
                    }}
                    id="elvy6l2k4zv1"
                    fill="#FFF"
                    d="M300.16 322.52H306.93V327.29999999999995H300.16z"
                    className="animable"
                ></path>
                <path
                    style={{
                        WebkitTransformOrigin: 366.245,
                        MsTransformOrigin: 366.245,
                        transformOrigin: 366.245,
                    }}
                    id="elncld0pg41jj"
                    fill="#FFF"
                    d="M364.42 245.54H368.07V249.72H364.42z"
                    className="animable"
                ></path>
                <path
                    style={{
                        WebkitTransformOrigin: 372.585,
                        MsTransformOrigin: 372.585,
                        transformOrigin: 372.585,
                    }}
                    id="elv91xsne77os"
                    fill="#FFF"
                    d="M370.76 245.54H374.40999999999997V249.72H370.76z"
                    className="animable"
                ></path>
                <path
                    style={{
                        WebkitTransformOrigin: 366.245,
                        MsTransformOrigin: 366.245,
                        transformOrigin: 366.245,
                    }}
                    id="elocfpul4ptn"
                    fill="#FFF"
                    d="M364.42 251.84H368.07V256.02H364.42z"
                    className="animable"
                ></path>
                <path
                    style={{
                        WebkitTransformOrigin: 372.585,
                        MsTransformOrigin: 372.585,
                        transformOrigin: 372.585,
                    }}
                    id="elfh7wjjb419p"
                    fill="#FFF"
                    d="M370.76 251.84H374.40999999999997V256.02H370.76z"
                    className="animable"
                ></path>
                <path
                    style={{
                        WebkitTransformOrigin: 366.245,
                        MsTransformOrigin: 366.245,
                        transformOrigin: 366.245,
                    }}
                    id="ely8rqckiw8oj"
                    fill="#FFF"
                    d="M364.42 258.13H368.07V262.31H364.42z"
                    className="animable"
                ></path>
                <path
                    style={{
                        WebkitTransformOrigin: 372.585,
                        MsTransformOrigin: 372.585,
                        transformOrigin: 372.585,
                    }}
                    id="el797px60p42j"
                    fill="#FFF"
                    d="M370.76 258.13H374.40999999999997V262.31H370.76z"
                    className="animable"
                ></path>
                <path
                    style={{
                        WebkitTransformOrigin: 366.245,
                        MsTransformOrigin: 366.245,
                        transformOrigin: 366.245,
                    }}
                    id="elf5l6woxpi0i"
                    fill="#FFF"
                    d="M364.42 264.43H368.07V268.61H364.42z"
                    className="animable"
                ></path>
                <path
                    style={{
                        WebkitTransformOrigin: 372.585,
                        MsTransformOrigin: 372.585,
                        transformOrigin: 372.585,
                    }}
                    id="elp8gwl4hakb"
                    fill="#FFF"
                    d="M370.76 264.43H374.40999999999997V268.61H370.76z"
                    className="animable"
                ></path>
                <path
                    style={{
                        WebkitTransformOrigin: 366.245,
                        MsTransformOrigin: 366.245,
                        transformOrigin: 366.245,
                    }}
                    id="eldc0d9s7s5pr"
                    fill="#FFF"
                    d="M364.42 270.88H368.07V275.06H364.42z"
                    className="animable"
                ></path>
                <path
                    style={{
                        WebkitTransformOrigin: 372.585,
                        MsTransformOrigin: 372.585,
                        transformOrigin: 372.585,
                    }}
                    id="elxf68spa2ss"
                    fill="#FFF"
                    d="M370.76 270.88H374.40999999999997V275.06H370.76z"
                    className="animable"
                ></path>
                <path
                    style={{
                        WebkitTransformOrigin: 366.245,
                        MsTransformOrigin: 366.245,
                        transformOrigin: 366.245,
                    }}
                    id="elg0nzj76ayml"
                    fill="#FFF"
                    d="M364.42 277.33H368.07V281.51H364.42z"
                    className="animable"
                ></path>
                <path
                    style={{
                        WebkitTransformOrigin: 372.585,
                        MsTransformOrigin: 372.585,
                        transformOrigin: 372.585,
                    }}
                    id="elz2ub724k1ka"
                    fill="#FFF"
                    d="M370.76 277.33H374.40999999999997V281.51H370.76z"
                    className="animable"
                ></path>
                <path
                    style={{
                        WebkitTransformOrigin: 366.245,
                        MsTransformOrigin: 366.245,
                        transformOrigin: 366.245,
                    }}
                    id="elirahd3wpvy"
                    fill="#FFF"
                    d="M364.42 283.78H368.07V287.96H364.42z"
                    className="animable"
                ></path>
                <path
                    style={{
                        WebkitTransformOrigin: 372.585,
                        MsTransformOrigin: 372.585,
                        transformOrigin: 372.585,
                    }}
                    id="el9064x3hry0w"
                    fill="#FFF"
                    d="M370.76 283.78H374.40999999999997V287.96H370.76z"
                    className="animable"
                ></path>
                <path
                    style={{
                        WebkitTransformOrigin: 366.245,
                        MsTransformOrigin: 366.245,
                        transformOrigin: 366.245,
                    }}
                    id="elv5wji2d2bd"
                    fill="#FFF"
                    d="M364.42 290.23H368.07V294.41H364.42z"
                    className="animable"
                ></path>
                <path
                    style={{
                        WebkitTransformOrigin: 372.585,
                        MsTransformOrigin: 372.585,
                        transformOrigin: 372.585,
                    }}
                    id="elj2gmjfcbr2q"
                    fill="#FFF"
                    d="M370.76 290.23H374.40999999999997V294.41H370.76z"
                    className="animable"
                ></path>
                <path
                    style={{
                        WebkitTransformOrigin: 366.245,
                        MsTransformOrigin: 366.245,
                        transformOrigin: 366.245,
                    }}
                    id="elp0wn4hg0f5"
                    fill="#FFF"
                    d="M364.42 296.76H368.07V300.94H364.42z"
                    className="animable"
                ></path>
                <path
                    style={{
                        WebkitTransformOrigin: 372.585,
                        MsTransformOrigin: 372.585,
                        transformOrigin: 372.585,
                    }}
                    id="elk8b6ni0j1e"
                    fill="#FFF"
                    d="M370.76 296.76H374.40999999999997V300.94H370.76z"
                    className="animable"
                ></path>
                <path
                    style={{
                        WebkitTransformOrigin: 366.245,
                        MsTransformOrigin: 366.245,
                        transformOrigin: 366.245,
                    }}
                    id="el5o6s36p2dj4"
                    fill="#FFF"
                    d="M364.42 303.28H368.07V307.46H364.42z"
                    className="animable"
                ></path>
                <path
                    style={{
                        WebkitTransformOrigin: 372.585,
                        MsTransformOrigin: 372.585,
                        transformOrigin: 372.585,
                    }}
                    id="elqfjjk6unfmc"
                    fill="#FFF"
                    d="M370.76 303.28H374.40999999999997V307.46H370.76z"
                    className="animable"
                ></path>
                <path
                    style={{
                        WebkitTransformOrigin: 366.245,
                        MsTransformOrigin: 366.245,
                        transformOrigin: 366.245,
                    }}
                    id="elhtnns4bowi6"
                    fill="#FFF"
                    d="M364.42 309.8H368.07V313.98H364.42z"
                    className="animable"
                ></path>
                <path
                    style={{
                        WebkitTransformOrigin: 372.585,
                        MsTransformOrigin: 372.585,
                        transformOrigin: 372.585,
                    }}
                    id="elfynf9xcy9zu"
                    fill="#FFF"
                    d="M370.76 309.8H374.40999999999997V313.98H370.76z"
                    className="animable"
                ></path>
                <path
                    style={{
                        WebkitTransformOrigin: 366.245,
                        MsTransformOrigin: 366.245,
                        transformOrigin: 366.245,
                    }}
                    id="elodm15u5d5a"
                    fill="#FFF"
                    d="M364.42 316.32H368.07V320.5H364.42z"
                    className="animable"
                ></path>
                <path
                    style={{
                        WebkitTransformOrigin: 372.585,
                        MsTransformOrigin: 372.585,
                        transformOrigin: 372.585,
                    }}
                    id="elrf6iei1dz5s"
                    fill="#FFF"
                    d="M370.76 316.32H374.40999999999997V320.5H370.76z"
                    className="animable"
                ></path>
                <path
                    style={{
                        WebkitTransformOrigin: 455.495,
                        MsTransformOrigin: 455.495,
                        transformOrigin: 455.495,
                    }}
                    id="elvv6dtd6069a"
                    fill="#FFF"
                    d="M453.67 246.22H457.32V251.15H453.67z"
                    className="animable"
                ></path>
                <path
                    style={{
                        WebkitTransformOrigin: 461.825,
                        MsTransformOrigin: 461.825,
                        transformOrigin: 461.825,
                    }}
                    id="elba0rch1b9h"
                    fill="#FFF"
                    d="M460 246.22H463.65V251.15H460z"
                    className="animable"
                ></path>
                <path
                    style={{
                        WebkitTransformOrigin: 455.495,
                        MsTransformOrigin: 455.495,
                        transformOrigin: 455.495,
                    }}
                    id="el5h7udtltmce"
                    fill="#FFF"
                    d="M453.67 253.64H457.32V258.57H453.67z"
                    className="animable"
                ></path>
                <path
                    style={{
                        WebkitTransformOrigin: 461.825,
                        MsTransformOrigin: 461.825,
                        transformOrigin: 461.825,
                    }}
                    id="el2mwctr8a7oh"
                    fill="#FFF"
                    d="M460 253.64H463.65V258.57H460z"
                    className="animable"
                ></path>
                <path
                    style={{
                        WebkitTransformOrigin: 455.495,
                        MsTransformOrigin: 455.495,
                        transformOrigin: 455.495,
                    }}
                    id="elft9lefitd2q"
                    fill="#FFF"
                    d="M453.67 261.05H457.32V265.98H453.67z"
                    className="animable"
                ></path>
                <path
                    style={{
                        WebkitTransformOrigin: 461.825,
                        MsTransformOrigin: 461.825,
                        transformOrigin: 461.825,
                    }}
                    id="elt4ninf4uvb"
                    fill="#FFF"
                    d="M460 261.05H463.65V265.98H460z"
                    className="animable"
                ></path>
                <path
                    style={{
                        WebkitTransformOrigin: 455.495,
                        MsTransformOrigin: 455.495,
                        transformOrigin: 455.495,
                    }}
                    id="eltedl4404f7"
                    fill="#FFF"
                    d="M453.67 268.47H457.32V273.40000000000003H453.67z"
                    className="animable"
                ></path>
                <path
                    style={{
                        WebkitTransformOrigin: 461.825,
                        MsTransformOrigin: 461.825,
                        transformOrigin: 461.825,
                    }}
                    id="el41gesggzyk2"
                    fill="#FFF"
                    d="M460 268.47H463.65V273.40000000000003H460z"
                    className="animable"
                ></path>
                <path
                    style={{
                        WebkitTransformOrigin: 455.495,
                        MsTransformOrigin: 455.495,
                        transformOrigin: 455.495,
                    }}
                    id="el27liqnnmzdy"
                    fill="#FFF"
                    d="M453.67 276.07H457.32V281H453.67z"
                    className="animable"
                ></path>
                <path
                    style={{
                        WebkitTransformOrigin: 461.825,
                        MsTransformOrigin: 461.825,
                        transformOrigin: 461.825,
                    }}
                    id="elbq2gb1aercm"
                    fill="#FFF"
                    d="M460 276.07H463.65V281H460z"
                    className="animable"
                ></path>
                <path
                    style={{
                        WebkitTransformOrigin: 455.495,
                        MsTransformOrigin: 455.495,
                        transformOrigin: 455.495,
                    }}
                    id="el2y0owjpxgbs"
                    fill="#FFF"
                    d="M453.67 283.67H457.32V288.6H453.67z"
                    className="animable"
                ></path>
                <path
                    style={{
                        WebkitTransformOrigin: 461.825,
                        MsTransformOrigin: 461.825,
                        transformOrigin: 461.825,
                    }}
                    id="elx3qu4lkl2a"
                    fill="#FFF"
                    d="M460 283.67H463.65V288.6H460z"
                    className="animable"
                ></path>
                <path
                    style={{
                        WebkitTransformOrigin: 455.495,
                        MsTransformOrigin: 455.495,
                        transformOrigin: 455.495,
                    }}
                    id="el8ocoxmvnaig"
                    fill="#FFF"
                    d="M453.67 291.27H457.32V296.2H453.67z"
                    className="animable"
                ></path>
                <path
                    style={{
                        WebkitTransformOrigin: 461.825,
                        MsTransformOrigin: 461.825,
                        transformOrigin: 461.825,
                    }}
                    id="el5o273oqlujo"
                    fill="#FFF"
                    d="M460 291.27H463.65V296.2H460z"
                    className="animable"
                ></path>
                <path
                    style={{
                        WebkitTransformOrigin: 455.495,
                        MsTransformOrigin: 455.495,
                        transformOrigin: 455.495,
                    }}
                    id="elqzy8jadjyrr"
                    fill="#FFF"
                    d="M453.67 298.88H457.32V303.81H453.67z"
                    className="animable"
                ></path>
                <path
                    style={{
                        WebkitTransformOrigin: 461.825,
                        MsTransformOrigin: 461.825,
                        transformOrigin: 461.825,
                    }}
                    id="elzfuly74kdgj"
                    fill="#FFF"
                    d="M460 298.88H463.65V303.81H460z"
                    className="animable"
                ></path>
                <path
                    style={{
                        WebkitTransformOrigin: 455.495,
                        MsTransformOrigin: 455.495,
                        transformOrigin: 455.495,
                    }}
                    id="ela0pobamen6t"
                    fill="#FFF"
                    d="M453.67 306.56H457.32V311.49H453.67z"
                    className="animable"
                ></path>
                <path
                    style={{
                        WebkitTransformOrigin: 461.825,
                        MsTransformOrigin: 461.825,
                        transformOrigin: 461.825,
                    }}
                    id="elchbpdigje6p"
                    fill="#FFF"
                    d="M460 306.56H463.65V311.49H460z"
                    className="animable"
                ></path>
                <path
                    style={{
                        WebkitTransformOrigin: 455.495,
                        MsTransformOrigin: 455.495,
                        transformOrigin: 455.495,
                    }}
                    id="elbugii7lztq6"
                    fill="#FFF"
                    d="M453.67 314.24H457.32V319.17H453.67z"
                    className="animable"
                ></path>
                <path
                    style={{
                        WebkitTransformOrigin: 461.825,
                        MsTransformOrigin: 461.825,
                        transformOrigin: 461.825,
                    }}
                    id="elpbtvy5z0vx"
                    fill="#FFF"
                    d="M460 314.24H463.65V319.17H460z"
                    className="animable"
                ></path>
                <path
                    style={{
                        WebkitTransformOrigin: 455.495,
                        MsTransformOrigin: 455.495,
                        transformOrigin: 455.495,
                    }}
                    id="el2c8l3bx807c"
                    fill="#FFF"
                    d="M453.67 321.92H457.32V326.85H453.67z"
                    className="animable"
                ></path>
                <path
                    style={{
                        WebkitTransformOrigin: 461.825,
                        MsTransformOrigin: 461.825,
                        transformOrigin: 461.825,
                    }}
                    id="elurizc4d0cf"
                    fill="#FFF"
                    d="M460 321.92H463.65V326.85H460z"
                    className="animable"
                ></path>
                <path
                    style={{
                        WebkitTransformOrigin: 455.495,
                        MsTransformOrigin: 455.495,
                        transformOrigin: 455.495,
                    }}
                    id="elrxcriahht5"
                    fill="#FFF"
                    d="M453.67 329.6H457.32V334.53000000000003H453.67z"
                    className="animable"
                ></path>
                <path
                    style={{
                        WebkitTransformOrigin: 461.825,
                        MsTransformOrigin: 461.825,
                        transformOrigin: 461.825,
                    }}
                    id="el6ftsdvzs4x"
                    fill="#FFF"
                    d="M460 329.6H463.65V334.53000000000003H460z"
                    className="animable"
                ></path>
                <path
                    style={{
                        WebkitTransformOrigin: 379.795,
                        MsTransformOrigin: 379.795,
                        transformOrigin: 379.795,
                    }}
                    id="el4qggfprrlpd"
                    fill="#FFF"
                    d="M377.97 245.54H381.62V249.72H377.97z"
                    className="animable"
                ></path>
                <path
                    style={{
                        WebkitTransformOrigin: 386.125,
                        MsTransformOrigin: 386.125,
                        transformOrigin: 386.125,
                    }}
                    id="elirke4k4q1bo"
                    fill="#FFF"
                    d="M384.3 245.54H387.95V249.72H384.3z"
                    className="animable"
                ></path>
                <path
                    style={{
                        WebkitTransformOrigin: 379.795,
                        MsTransformOrigin: 379.795,
                        transformOrigin: 379.795,
                    }}
                    id="elys615xsrdgr"
                    fill="#FFF"
                    d="M377.97 239.08H381.62V243.26000000000002H377.97z"
                    className="animable"
                ></path>
                <path
                    style={{
                        WebkitTransformOrigin: 386.125,
                        MsTransformOrigin: 386.125,
                        transformOrigin: 386.125,
                    }}
                    id="el81tca9m69wh"
                    fill="#FFF"
                    d="M384.3 239.08H387.95V243.26000000000002H384.3z"
                    className="animable"
                ></path>
                <path
                    style={{
                        WebkitTransformOrigin: 379.795,
                        MsTransformOrigin: 379.795,
                        transformOrigin: 379.795,
                    }}
                    id="elk84zalqqa8"
                    fill="#FFF"
                    d="M377.97 232.63H381.62V236.81H377.97z"
                    className="animable"
                ></path>
                <path
                    style={{
                        WebkitTransformOrigin: 386.125,
                        MsTransformOrigin: 386.125,
                        transformOrigin: 386.125,
                    }}
                    id="el20s8tsa7rfr"
                    fill="#FFF"
                    d="M384.3 232.63H387.95V236.81H384.3z"
                    className="animable"
                ></path>
                <path
                    style={{
                        WebkitTransformOrigin: 379.795,
                        MsTransformOrigin: 379.795,
                        transformOrigin: 379.795,
                    }}
                    id="elph6ruk9bows"
                    fill="#FFF"
                    d="M377.97 251.84H381.62V256.02H377.97z"
                    className="animable"
                ></path>
                <path
                    style={{
                        WebkitTransformOrigin: 386.125,
                        MsTransformOrigin: 386.125,
                        transformOrigin: 386.125,
                    }}
                    id="elyem660ak9a"
                    fill="#FFF"
                    d="M384.3 251.84H387.95V256.02H384.3z"
                    className="animable"
                ></path>
                <path
                    style={{
                        WebkitTransformOrigin: 379.795,
                        MsTransformOrigin: 379.795,
                        transformOrigin: 379.795,
                    }}
                    id="elbvn37f5oql"
                    fill="#FFF"
                    d="M377.97 258.13H381.62V262.31H377.97z"
                    className="animable"
                ></path>
                <path
                    style={{
                        WebkitTransformOrigin: 386.125,
                        MsTransformOrigin: 386.125,
                        transformOrigin: 386.125,
                    }}
                    id="elkd89akba1ms"
                    fill="#FFF"
                    d="M384.3 258.13H387.95V262.31H384.3z"
                    className="animable"
                ></path>
                <path
                    style={{
                        WebkitTransformOrigin: 379.795,
                        MsTransformOrigin: 379.795,
                        transformOrigin: 379.795,
                    }}
                    id="elwyjdk9b23pk"
                    fill="#FFF"
                    d="M377.97 264.43H381.62V268.61H377.97z"
                    className="animable"
                ></path>
                <path
                    style={{
                        WebkitTransformOrigin: 386.125,
                        MsTransformOrigin: 386.125,
                        transformOrigin: 386.125,
                    }}
                    id="elwpaxh8x73x"
                    fill="#FFF"
                    d="M384.3 264.43H387.95V268.61H384.3z"
                    className="animable"
                ></path>
                <path
                    style={{
                        WebkitTransformOrigin: 379.795,
                        MsTransformOrigin: 379.795,
                        transformOrigin: 379.795,
                    }}
                    id="elwrpkdn4hiwq"
                    fill="#FFF"
                    d="M377.97 270.88H381.62V275.06H377.97z"
                    className="animable"
                ></path>
                <path
                    style={{
                        WebkitTransformOrigin: 386.125,
                        MsTransformOrigin: 386.125,
                        transformOrigin: 386.125,
                    }}
                    id="elwkig5dx9ogq"
                    fill="#FFF"
                    d="M384.3 270.88H387.95V275.06H384.3z"
                    className="animable"
                ></path>
                <path
                    style={{
                        WebkitTransformOrigin: 379.795,
                        MsTransformOrigin: 379.795,
                        transformOrigin: 379.795,
                    }}
                    id="elop2ymrh457l"
                    fill="#FFF"
                    d="M377.97 277.33H381.62V281.51H377.97z"
                    className="animable"
                ></path>
                <path
                    style={{
                        WebkitTransformOrigin: 386.125,
                        MsTransformOrigin: 386.125,
                        transformOrigin: 386.125,
                    }}
                    id="el0vllhv0aawt"
                    fill="#FFF"
                    d="M384.3 277.33H387.95V281.51H384.3z"
                    className="animable"
                ></path>
                <path
                    style={{
                        WebkitTransformOrigin: 379.795,
                        MsTransformOrigin: 379.795,
                        transformOrigin: 379.795,
                    }}
                    id="eli40umxxsa3n"
                    fill="#FFF"
                    d="M377.97 283.78H381.62V287.96H377.97z"
                    className="animable"
                ></path>
                <path
                    style={{
                        WebkitTransformOrigin: 386.125,
                        MsTransformOrigin: 386.125,
                        transformOrigin: 386.125,
                    }}
                    id="ely217lfwx5ab"
                    fill="#FFF"
                    d="M384.3 283.78H387.95V287.96H384.3z"
                    className="animable"
                ></path>
                <path
                    style={{
                        WebkitTransformOrigin: 379.795,
                        MsTransformOrigin: 379.795,
                        transformOrigin: 379.795,
                    }}
                    id="el64tqepq1he"
                    fill="#FFF"
                    d="M377.97 290.23H381.62V294.41H377.97z"
                    className="animable"
                ></path>
                <path
                    style={{
                        WebkitTransformOrigin: 386.125,
                        MsTransformOrigin: 386.125,
                        transformOrigin: 386.125,
                    }}
                    id="el1k7surresxy"
                    fill="#FFF"
                    d="M384.3 290.23H387.95V294.41H384.3z"
                    className="animable"
                ></path>
                <path
                    style={{
                        WebkitTransformOrigin: 379.795,
                        MsTransformOrigin: 379.795,
                        transformOrigin: 379.795,
                    }}
                    id="elpwtw78yee3"
                    fill="#FFF"
                    d="M377.97 296.76H381.62V300.94H377.97z"
                    className="animable"
                ></path>
                <path
                    style={{
                        WebkitTransformOrigin: 386.125,
                        MsTransformOrigin: 386.125,
                        transformOrigin: 386.125,
                    }}
                    id="el7hbl931i87"
                    fill="#FFF"
                    d="M384.3 296.76H387.95V300.94H384.3z"
                    className="animable"
                ></path>
                <path
                    style={{
                        WebkitTransformOrigin: 379.795,
                        MsTransformOrigin: 379.795,
                        transformOrigin: 379.795,
                    }}
                    id="elnccld7thw9"
                    fill="#FFF"
                    d="M377.97 303.28H381.62V307.46H377.97z"
                    className="animable"
                ></path>
                <path
                    style={{
                        WebkitTransformOrigin: 386.125,
                        MsTransformOrigin: 386.125,
                        transformOrigin: 386.125,
                    }}
                    id="elb9b7clknzyq"
                    fill="#FFF"
                    d="M384.3 303.28H387.95V307.46H384.3z"
                    className="animable"
                ></path>
                <path
                    style={{
                        WebkitTransformOrigin: 379.795,
                        MsTransformOrigin: 379.795,
                        transformOrigin: 379.795,
                    }}
                    id="elnkbs0cfplys"
                    fill="#FFF"
                    d="M377.97 309.8H381.62V313.98H377.97z"
                    className="animable"
                ></path>
                <path
                    style={{
                        WebkitTransformOrigin: 386.125,
                        MsTransformOrigin: 386.125,
                        transformOrigin: 386.125,
                    }}
                    id="elsvmomszrm"
                    fill="#FFF"
                    d="M384.3 309.8H387.95V313.98H384.3z"
                    className="animable"
                ></path>
                <path
                    style={{
                        WebkitTransformOrigin: 379.795,
                        MsTransformOrigin: 379.795,
                        transformOrigin: 379.795,
                    }}
                    id="el2xu6ah2ataj"
                    fill="#FFF"
                    d="M377.97 316.32H381.62V320.5H377.97z"
                    className="animable"
                ></path>
                <path
                    style={{
                        WebkitTransformOrigin: 386.125,
                        MsTransformOrigin: 386.125,
                        transformOrigin: 386.125,
                    }}
                    id="elnt44vnf13ws"
                    fill="#FFF"
                    d="M384.3 316.32H387.95V320.5H384.3z"
                    className="animable"
                ></path>
                <path
                    style={{
                        WebkitTransformOrigin: 125.5,
                        MsTransformOrigin: 125.5,
                        transformOrigin: 125.5,
                    }}
                    id="elgmwis9mutv7"
                    fill="#EBEBEB"
                    d="M215.68 357.32L35.32 357.32 35.32 255.83 56.92 255.83 56.92 282.07 62.48 282.07 62.48 267.22 89.95 267.22 89.95 290.74 93.59 290.74 93.59 235.54 117.46 235.54 117.46 250.69 129.7 250.69 129.7 269.54 133.41 269.54 133.41 257.69 155.11 257.69 155.11 293.06 161.46 293.06 161.46 264.31 181.31 264.31 181.31 242.46 194.8 242.46 210.41 242.53 210.41 285.19 215.67 285.19 215.68 357.32z"
                    className="animable"
                ></path>
                <path
                    style={{
                        WebkitTransformOrigin: 39.795,
                        MsTransformOrigin: 39.795,
                        transformOrigin: 39.795,
                    }}
                    id="elp59kdt7ehn"
                    fill="#FFF"
                    d="M37.97 259.13H41.62V264.15H37.97z"
                    className="animable"
                ></path>
                <path
                    style={{
                        WebkitTransformOrigin: 46.125,
                        MsTransformOrigin: 46.125,
                        transformOrigin: 46.125,
                    }}
                    id="elzm470esa9jm"
                    fill="#FFF"
                    d="M44.3 259.13H47.949999999999996V264.15H44.3z"
                    className="animable"
                ></path>
                <path
                    style={{
                        WebkitTransformOrigin: 52.465,
                        MsTransformOrigin: 52.465,
                        transformOrigin: 52.465,
                    }}
                    id="elmsuju7gkls"
                    fill="#FFF"
                    d="M50.64 259.13H54.29V264.15H50.64z"
                    className="animable"
                ></path>
                <path
                    style={{
                        WebkitTransformOrigin: 39.795,
                        MsTransformOrigin: 39.795,
                        transformOrigin: 39.795,
                    }}
                    id="el43x1vzux5xu"
                    fill="#FFF"
                    d="M37.97 266.68H41.62V271.7H37.97z"
                    className="animable"
                ></path>
                <path
                    style={{
                        WebkitTransformOrigin: 46.125,
                        MsTransformOrigin: 46.125,
                        transformOrigin: 46.125,
                    }}
                    id="eldrkx921hji5"
                    fill="#FFF"
                    d="M44.3 266.68H47.949999999999996V271.7H44.3z"
                    className="animable"
                ></path>
                <path
                    style={{
                        WebkitTransformOrigin: 52.465,
                        MsTransformOrigin: 52.465,
                        transformOrigin: 52.465,
                    }}
                    id="elotvzefi6i7"
                    fill="#FFF"
                    d="M50.64 266.68H54.29V271.7H50.64z"
                    className="animable"
                ></path>
                <path
                    style={{
                        WebkitTransformOrigin: 39.795,
                        MsTransformOrigin: 39.795,
                        transformOrigin: 39.795,
                    }}
                    id="elh54lzxdo0bv"
                    fill="#FFF"
                    d="M37.97 274.23H41.62V279.25H37.97z"
                    className="animable"
                ></path>
                <path
                    style={{
                        WebkitTransformOrigin: 46.125,
                        MsTransformOrigin: 46.125,
                        transformOrigin: 46.125,
                    }}
                    id="elxb2fpy0zii"
                    fill="#FFF"
                    d="M44.3 274.23H47.949999999999996V279.25H44.3z"
                    className="animable"
                ></path>
                <path
                    style={{
                        WebkitTransformOrigin: 52.465,
                        MsTransformOrigin: 52.465,
                        transformOrigin: 52.465,
                    }}
                    id="eltmdi3pl3d"
                    fill="#FFF"
                    d="M50.64 274.23H54.29V279.25H50.64z"
                    className="animable"
                ></path>
                <path
                    style={{
                        WebkitTransformOrigin: 39.795,
                        MsTransformOrigin: 39.795,
                        transformOrigin: 39.795,
                    }}
                    id="elz5b9xg3i83"
                    fill="#FFF"
                    d="M37.97 281.77H41.62V286.78999999999996H37.97z"
                    className="animable"
                ></path>
                <path
                    style={{
                        WebkitTransformOrigin: 46.125,
                        MsTransformOrigin: 46.125,
                        transformOrigin: 46.125,
                    }}
                    id="elegj5r97vzip"
                    fill="#FFF"
                    d="M44.3 281.77H47.949999999999996V286.78999999999996H44.3z"
                    className="animable"
                ></path>
                <path
                    style={{
                        WebkitTransformOrigin: 52.465,
                        MsTransformOrigin: 52.465,
                        transformOrigin: 52.465,
                    }}
                    id="elbrdgjioxhm5"
                    fill="#FFF"
                    d="M50.64 281.77H54.29V286.78999999999996H50.64z"
                    className="animable"
                ></path>
                <path
                    style={{
                        WebkitTransformOrigin: 166.335,
                        MsTransformOrigin: 166.335,
                        transformOrigin: 166.335,
                    }}
                    id="elkuk8qtwh6t"
                    fill="#FFF"
                    d="M164.51 267.89H168.16V272.07H164.51z"
                    className="animable"
                ></path>
                <path
                    style={{
                        WebkitTransformOrigin: 172.665,
                        MsTransformOrigin: 172.665,
                        transformOrigin: 172.665,
                    }}
                    id="elwspj8seuvtl"
                    fill="#FFF"
                    d="M170.84 267.89H174.49V272.07H170.84z"
                    className="animable"
                ></path>
                <path
                    style={{
                        WebkitTransformOrigin: 179.005,
                        MsTransformOrigin: 179.005,
                        transformOrigin: 179.005,
                    }}
                    id="elgtrbtgutpwv"
                    fill="#FFF"
                    d="M177.18 267.89H180.83V272.07H177.18z"
                    className="animable"
                ></path>
                <path
                    style={{
                        WebkitTransformOrigin: 166.335,
                        MsTransformOrigin: 166.335,
                        transformOrigin: 166.335,
                    }}
                    id="elq014s0wwg4s"
                    fill="#FFF"
                    d="M164.51 274.18H168.16V278.36H164.51z"
                    className="animable"
                ></path>
                <path
                    style={{
                        WebkitTransformOrigin: 172.665,
                        MsTransformOrigin: 172.665,
                        transformOrigin: 172.665,
                    }}
                    id="elfms810ee8m"
                    fill="#FFF"
                    d="M170.84 274.18H174.49V278.36H170.84z"
                    className="animable"
                ></path>
                <path
                    style={{
                        WebkitTransformOrigin: 179.005,
                        MsTransformOrigin: 179.005,
                        transformOrigin: 179.005,
                    }}
                    id="elgey1ikg20rf"
                    fill="#FFF"
                    d="M177.18 274.18H180.83V278.36H177.18z"
                    className="animable"
                ></path>
                <path
                    style={{
                        WebkitTransformOrigin: 166.335,
                        MsTransformOrigin: 166.335,
                        transformOrigin: 166.335,
                    }}
                    id="elwz5yk60kr5"
                    fill="#FFF"
                    d="M164.51 280.48H168.16V284.66H164.51z"
                    className="animable"
                ></path>
                <path
                    style={{
                        WebkitTransformOrigin: 172.665,
                        MsTransformOrigin: 172.665,
                        transformOrigin: 172.665,
                    }}
                    id="el27t7qycatbh"
                    fill="#FFF"
                    d="M170.84 280.48H174.49V284.66H170.84z"
                    className="animable"
                ></path>
                <path
                    style={{
                        WebkitTransformOrigin: 179.005,
                        MsTransformOrigin: 179.005,
                        transformOrigin: 179.005,
                    }}
                    id="elx4ggrm6sc8"
                    fill="#FFF"
                    d="M177.18 280.48H180.83V284.66H177.18z"
                    className="animable"
                ></path>
                <path
                    style={{
                        WebkitTransformOrigin: 166.335,
                        MsTransformOrigin: 166.335,
                        transformOrigin: 166.335,
                    }}
                    id="eleznz4cirgxq"
                    fill="#FFF"
                    d="M164.51 286.77H168.16V290.95H164.51z"
                    className="animable"
                ></path>
                <path
                    style={{
                        WebkitTransformOrigin: 172.665,
                        MsTransformOrigin: 172.665,
                        transformOrigin: 172.665,
                    }}
                    id="elq10yf6908vo"
                    fill="#FFF"
                    d="M170.84 286.77H174.49V290.95H170.84z"
                    className="animable"
                ></path>
                <path
                    style={{
                        WebkitTransformOrigin: 179.005,
                        MsTransformOrigin: 179.005,
                        transformOrigin: 179.005,
                    }}
                    id="eloxsoyyx3kr"
                    fill="#FFF"
                    d="M177.18 286.77H180.83V290.95H177.18z"
                    className="animable"
                ></path>
                <path
                    style={{
                        WebkitTransformOrigin: 166.335,
                        MsTransformOrigin: 166.335,
                        transformOrigin: 166.335,
                    }}
                    id="elr9sqrnq1aub"
                    fill="#FFF"
                    d="M164.51 293.23H168.16V297.41H164.51z"
                    className="animable"
                ></path>
                <path
                    style={{
                        WebkitTransformOrigin: 172.665,
                        MsTransformOrigin: 172.665,
                        transformOrigin: 172.665,
                    }}
                    id="eldzcg84eharm"
                    fill="#FFF"
                    d="M170.84 293.23H174.49V297.41H170.84z"
                    className="animable"
                ></path>
                <path
                    style={{
                        WebkitTransformOrigin: 179.005,
                        MsTransformOrigin: 179.005,
                        transformOrigin: 179.005,
                    }}
                    id="elx9misl64da"
                    fill="#FFF"
                    d="M177.18 293.23H180.83V297.41H177.18z"
                    className="animable"
                ></path>
                <path
                    style={{
                        WebkitTransformOrigin: 166.335,
                        MsTransformOrigin: 166.335,
                        transformOrigin: 166.335,
                    }}
                    id="elw6jhjsmd4q"
                    fill="#FFF"
                    d="M164.51 299.68H168.16V303.86H164.51z"
                    className="animable"
                ></path>
                <path
                    style={{
                        WebkitTransformOrigin: 172.665,
                        MsTransformOrigin: 172.665,
                        transformOrigin: 172.665,
                    }}
                    id="elzsjalm21l9i"
                    fill="#FFF"
                    d="M170.84 299.68H174.49V303.86H170.84z"
                    className="animable"
                ></path>
                <path
                    style={{
                        WebkitTransformOrigin: 179.005,
                        MsTransformOrigin: 179.005,
                        transformOrigin: 179.005,
                    }}
                    id="elpzqerjyatbg"
                    fill="#FFF"
                    d="M177.18 299.68H180.83V303.86H177.18z"
                    className="animable"
                ></path>
                <path
                    style={{
                        WebkitTransformOrigin: 166.335,
                        MsTransformOrigin: 166.335,
                        transformOrigin: 166.335,
                    }}
                    id="el6i5mieaqjm8"
                    fill="#FFF"
                    d="M164.51 306.13H168.16V310.31H164.51z"
                    className="animable"
                ></path>
                <path
                    style={{
                        WebkitTransformOrigin: 172.665,
                        MsTransformOrigin: 172.665,
                        transformOrigin: 172.665,
                    }}
                    id="el2pj09ihh8mv"
                    fill="#FFF"
                    d="M170.84 306.13H174.49V310.31H170.84z"
                    className="animable"
                ></path>
                <path
                    style={{
                        WebkitTransformOrigin: 179.005,
                        MsTransformOrigin: 179.005,
                        transformOrigin: 179.005,
                    }}
                    id="el30ak4uuiobn"
                    fill="#FFF"
                    d="M177.18 306.13H180.83V310.31H177.18z"
                    className="animable"
                ></path>
                <path
                    style={{
                        WebkitTransformOrigin: 166.335,
                        MsTransformOrigin: 166.335,
                        transformOrigin: 166.335,
                    }}
                    id="eljozq5ai8cxn"
                    fill="#FFF"
                    d="M164.51 312.58H168.16V316.76H164.51z"
                    className="animable"
                ></path>
                <path
                    style={{
                        WebkitTransformOrigin: 172.665,
                        MsTransformOrigin: 172.665,
                        transformOrigin: 172.665,
                    }}
                    id="elnovxch1ep2"
                    fill="#FFF"
                    d="M170.84 312.58H174.49V316.76H170.84z"
                    className="animable"
                ></path>
                <path
                    style={{
                        WebkitTransformOrigin: 179.005,
                        MsTransformOrigin: 179.005,
                        transformOrigin: 179.005,
                    }}
                    id="el2nw5z2mgya"
                    fill="#FFF"
                    d="M177.18 312.58H180.83V316.76H177.18z"
                    className="animable"
                ></path>
                <path
                    style={{
                        WebkitTransformOrigin: 76.12,
                        MsTransformOrigin: 76.12,
                        transformOrigin: 76.12,
                    }}
                    id="elpp3o9ybnsbe"
                    fill="#FFF"
                    d="M65.6 270.43H86.63999999999999V273.57H65.6z"
                    className="animable"
                ></path>
                <path
                    style={{
                        WebkitTransformOrigin: 76.12,
                        MsTransformOrigin: 76.12,
                        transformOrigin: 76.12,
                    }}
                    id="elx2trro1b33p"
                    fill="#FFF"
                    d="M65.6 276.52H86.63999999999999V279.65999999999997H65.6z"
                    className="animable"
                ></path>
                <path
                    style={{
                        WebkitTransformOrigin: 76.12,
                        MsTransformOrigin: 76.12,
                        transformOrigin: 76.12,
                    }}
                    id="elx3qx2mzf61r"
                    fill="#FFF"
                    d="M65.6 282.61H86.63999999999999V285.75H65.6z"
                    className="animable"
                ></path>
                <path
                    style={{
                        WebkitTransformOrigin: 76.12,
                        MsTransformOrigin: 76.12,
                        transformOrigin: 76.12,
                    }}
                    id="elbrtagziwg3k"
                    fill="#FFF"
                    d="M65.6 288.69H86.63999999999999V291.83H65.6z"
                    className="animable"
                ></path>
                <path
                    style={{
                        WebkitTransformOrigin: 76.12,
                        MsTransformOrigin: 76.12,
                        transformOrigin: 76.12,
                    }}
                    id="el7bxs7304f49"
                    fill="#FFF"
                    d="M65.6 294.78H86.63999999999999V297.91999999999996H65.6z"
                    className="animable"
                ></path>
                <path
                    style={{
                        WebkitTransformOrigin: 76.12,
                        MsTransformOrigin: 76.12,
                        transformOrigin: 76.12,
                    }}
                    id="elgvqrunns2qe"
                    fill="#FFF"
                    d="M65.6 300.87H86.63999999999999V304.01H65.6z"
                    className="animable"
                ></path>
                <path
                    style={{
                        WebkitTransformOrigin: 76.12,
                        MsTransformOrigin: 76.12,
                        transformOrigin: 76.12,
                    }}
                    id="el7k6z8rsedi8"
                    fill="#FFF"
                    d="M65.6 306.95H86.63999999999999V310.09H65.6z"
                    className="animable"
                ></path>
                <path
                    style={{
                        WebkitTransformOrigin: 76.12,
                        MsTransformOrigin: 76.12,
                        transformOrigin: 76.12,
                    }}
                    id="el9k3xunjkhts"
                    fill="#FFF"
                    d="M65.6 313.04H86.63999999999999V316.18H65.6z"
                    className="animable"
                ></path>
                <path
                    style={{
                        WebkitTransformOrigin: 143.01,
                        MsTransformOrigin: 143.01,
                        transformOrigin: 143.01,
                    }}
                    id="elplad617enpq"
                    fill="#FFF"
                    d="M139.76 260.53H146.26V263.55999999999995H139.76z"
                    className="animable"
                ></path>
                <path
                    style={{
                        WebkitTransformOrigin: 143.01,
                        MsTransformOrigin: 143.01,
                        transformOrigin: 143.01,
                    }}
                    id="elq6k00gre7y"
                    fill="#FFF"
                    d="M139.76 266.4H146.26V269.42999999999995H139.76z"
                    className="animable"
                ></path>
                <path
                    style={{
                        WebkitTransformOrigin: 143.01,
                        MsTransformOrigin: 143.01,
                        transformOrigin: 143.01,
                    }}
                    id="elk5fuk49mtdb"
                    fill="#FFF"
                    d="M139.76 272.28H146.26V275.30999999999995H139.76z"
                    className="animable"
                ></path>
                <path
                    style={{
                        WebkitTransformOrigin: 143.01,
                        MsTransformOrigin: 143.01,
                        transformOrigin: 143.01,
                    }}
                    id="elk96sh5y162"
                    fill="#FFF"
                    d="M139.76 278.16H146.26V281.19H139.76z"
                    className="animable"
                ></path>
                <path
                    style={{
                        WebkitTransformOrigin: 143.01,
                        MsTransformOrigin: 143.01,
                        transformOrigin: 143.01,
                    }}
                    id="elb8ihulcxb"
                    fill="#FFF"
                    d="M139.76 284.04H146.26V287.07H139.76z"
                    className="animable"
                ></path>
                <path
                    style={{
                        WebkitTransformOrigin: 143.01,
                        MsTransformOrigin: 143.01,
                        transformOrigin: 143.01,
                    }}
                    id="eluy2qp1ttxt"
                    fill="#FFF"
                    d="M139.76 289.92H146.26V292.95H139.76z"
                    className="animable"
                ></path>
                <path
                    style={{
                        WebkitTransformOrigin: 143.01,
                        MsTransformOrigin: 143.01,
                        transformOrigin: 143.01,
                    }}
                    id="elcyqesdjtxe"
                    fill="#FFF"
                    d="M139.76 295.79H146.26V298.82H139.76z"
                    className="animable"
                ></path>
                <path
                    style={{
                        WebkitTransformOrigin: 143.01,
                        MsTransformOrigin: 143.01,
                        transformOrigin: 143.01,
                    }}
                    id="ellmhnbwng4e7"
                    fill="#FFF"
                    d="M139.76 301.67H146.26V304.7H139.76z"
                    className="animable"
                ></path>
                <path
                    style={{
                        WebkitTransformOrigin: 151.85,
                        MsTransformOrigin: 151.85,
                        transformOrigin: 151.85,
                    }}
                    id="elhunzzrsx9tn"
                    fill="#FFF"
                    d="M148.6 260.53H155.1V263.55999999999995H148.6z"
                    className="animable"
                ></path>
                <path
                    style={{
                        WebkitTransformOrigin: 151.85,
                        MsTransformOrigin: 151.85,
                        transformOrigin: 151.85,
                    }}
                    id="elyysk52hqqa"
                    fill="#FFF"
                    d="M148.6 266.4H155.1V269.42999999999995H148.6z"
                    className="animable"
                ></path>
                <path
                    style={{
                        WebkitTransformOrigin: 151.85,
                        MsTransformOrigin: 151.85,
                        transformOrigin: 151.85,
                    }}
                    id="eljbiqauncjjh"
                    fill="#FFF"
                    d="M148.6 272.28H155.1V275.30999999999995H148.6z"
                    className="animable"
                ></path>
                <path
                    style={{
                        WebkitTransformOrigin: 151.85,
                        MsTransformOrigin: 151.85,
                        transformOrigin: 151.85,
                    }}
                    id="eloq6ls6k3hnc"
                    fill="#FFF"
                    d="M148.6 278.16H155.1V281.19H148.6z"
                    className="animable"
                ></path>
                <path
                    style={{
                        WebkitTransformOrigin: 151.85,
                        MsTransformOrigin: 151.85,
                        transformOrigin: 151.85,
                    }}
                    id="elcnbrr5lqgyf"
                    fill="#FFF"
                    d="M148.6 284.04H155.1V287.07H148.6z"
                    className="animable"
                ></path>
                <path
                    style={{
                        WebkitTransformOrigin: 151.85,
                        MsTransformOrigin: 151.85,
                        transformOrigin: 151.85,
                    }}
                    id="el0ysrdchy795"
                    fill="#FFF"
                    d="M148.6 289.92H155.1V292.95H148.6z"
                    className="animable"
                ></path>
                <path
                    style={{
                        WebkitTransformOrigin: 151.85,
                        MsTransformOrigin: 151.85,
                        transformOrigin: 151.85,
                    }}
                    id="elq72gjho5yim"
                    fill="#FFF"
                    d="M148.6 295.79H155.1V298.82H148.6z"
                    className="animable"
                ></path>
                <path
                    style={{
                        WebkitTransformOrigin: 151.85,
                        MsTransformOrigin: 151.85,
                        transformOrigin: 151.85,
                    }}
                    id="el7jfcp9cafwn"
                    fill="#FFF"
                    d="M148.6 301.67H155.1V304.7H148.6z"
                    className="animable"
                ></path>
                <path
                    style={{
                        WebkitTransformOrigin: 100.595,
                        MsTransformOrigin: 100.595,
                        transformOrigin: 100.595,
                    }}
                    id="elw1l6bz8ag3i"
                    fill="#FFF"
                    d="M97.21 238.72H103.97999999999999V243.5H97.21z"
                    className="animable"
                ></path>
                <path
                    style={{
                        WebkitTransformOrigin: 110.685,
                        MsTransformOrigin: 110.685,
                        transformOrigin: 110.685,
                    }}
                    id="elfoymiqawkio"
                    fill="#FFF"
                    d="M107.3 238.72H114.07V243.5H107.3z"
                    className="animable"
                ></path>
                <path
                    style={{
                        WebkitTransformOrigin: 100.595,
                        MsTransformOrigin: 100.595,
                        transformOrigin: 100.595,
                    }}
                    id="el1a908cr2mnn"
                    fill="#FFF"
                    d="M97.21 245.74H103.97999999999999V250.52H97.21z"
                    className="animable"
                ></path>
                <path
                    style={{
                        WebkitTransformOrigin: 110.685,
                        MsTransformOrigin: 110.685,
                        transformOrigin: 110.685,
                    }}
                    id="el4044b6db3v4"
                    fill="#FFF"
                    d="M107.3 245.74H114.07V250.52H107.3z"
                    className="animable"
                ></path>
                <path
                    style={{
                        WebkitTransformOrigin: 100.595,
                        MsTransformOrigin: 100.595,
                        transformOrigin: 100.595,
                    }}
                    id="el88pokskzm8o"
                    fill="#FFF"
                    d="M97.21 252.76H103.97999999999999V257.53999999999996H97.21z"
                    className="animable"
                ></path>
                <path
                    style={{
                        WebkitTransformOrigin: 110.685,
                        MsTransformOrigin: 110.685,
                        transformOrigin: 110.685,
                    }}
                    id="eldzzix1r8qit"
                    fill="#FFF"
                    d="M107.3 252.76H114.07V257.53999999999996H107.3z"
                    className="animable"
                ></path>
                <path
                    style={{
                        WebkitTransformOrigin: 100.595,
                        MsTransformOrigin: 100.595,
                        transformOrigin: 100.595,
                    }}
                    id="elju9nouw974"
                    fill="#FFF"
                    d="M97.21 259.77H103.97999999999999V264.54999999999995H97.21z"
                    className="animable"
                ></path>
                <path
                    style={{
                        WebkitTransformOrigin: 110.685,
                        MsTransformOrigin: 110.685,
                        transformOrigin: 110.685,
                    }}
                    id="el0rj9d7cpv5h8"
                    fill="#FFF"
                    d="M107.3 259.77H114.07V264.54999999999995H107.3z"
                    className="animable"
                ></path>
                <path
                    style={{
                        WebkitTransformOrigin: 100.595,
                        MsTransformOrigin: 100.595,
                        transformOrigin: 100.595,
                    }}
                    id="elcc5xiwvmysh"
                    fill="#FFF"
                    d="M97.21 266.79H103.97999999999999V271.57H97.21z"
                    className="animable"
                ></path>
                <path
                    style={{
                        WebkitTransformOrigin: 110.685,
                        MsTransformOrigin: 110.685,
                        transformOrigin: 110.685,
                    }}
                    id="elwuc4j9lte0d"
                    fill="#FFF"
                    d="M107.3 266.79H114.07V271.57H107.3z"
                    className="animable"
                ></path>
                <path
                    style={{
                        WebkitTransformOrigin: 100.595,
                        MsTransformOrigin: 100.595,
                        transformOrigin: 100.595,
                    }}
                    id="el8krpzj0tyk8"
                    fill="#FFF"
                    d="M97.21 273.81H103.97999999999999V278.59H97.21z"
                    className="animable"
                ></path>
                <path
                    style={{
                        WebkitTransformOrigin: 110.685,
                        MsTransformOrigin: 110.685,
                        transformOrigin: 110.685,
                    }}
                    id="elv1a4jd0vhxa"
                    fill="#FFF"
                    d="M107.3 273.81H114.07V278.59H107.3z"
                    className="animable"
                ></path>
                <path
                    style={{
                        WebkitTransformOrigin: 100.595,
                        MsTransformOrigin: 100.595,
                        transformOrigin: 100.595,
                    }}
                    id="elvm39ztvlbh"
                    fill="#FFF"
                    d="M97.21 280.83H103.97999999999999V285.60999999999996H97.21z"
                    className="animable"
                ></path>
                <path
                    style={{
                        WebkitTransformOrigin: 110.685,
                        MsTransformOrigin: 110.685,
                        transformOrigin: 110.685,
                    }}
                    id="elw8lseo9sv4"
                    fill="#FFF"
                    d="M107.3 280.83H114.07V285.60999999999996H107.3z"
                    className="animable"
                ></path>
                <path
                    style={{
                        WebkitTransformOrigin: 100.595,
                        MsTransformOrigin: 100.595,
                        transformOrigin: 100.595,
                    }}
                    id="elyxqwvbylc18"
                    fill="#FFF"
                    d="M97.21 287.84H103.97999999999999V292.61999999999995H97.21z"
                    className="animable"
                ></path>
                <path
                    style={{
                        WebkitTransformOrigin: 110.685,
                        MsTransformOrigin: 110.685,
                        transformOrigin: 110.685,
                    }}
                    id="elo8g6jn6t59b"
                    fill="#FFF"
                    d="M107.3 287.84H114.07V292.61999999999995H107.3z"
                    className="animable"
                ></path>
                <path
                    style={{
                        WebkitTransformOrigin: 123.195,
                        MsTransformOrigin: 123.195,
                        transformOrigin: 123.195,
                    }}
                    id="eliai8dl4ojw"
                    fill="#FFF"
                    d="M119.81 252.76H126.58V257.53999999999996H119.81z"
                    className="animable"
                ></path>
                <path
                    style={{
                        WebkitTransformOrigin: 123.195,
                        MsTransformOrigin: 123.195,
                        transformOrigin: 123.195,
                    }}
                    id="elivn4o3g9nrj"
                    fill="#FFF"
                    d="M119.81 259.77H126.58V264.54999999999995H119.81z"
                    className="animable"
                ></path>
                <path
                    style={{
                        WebkitTransformOrigin: 123.195,
                        MsTransformOrigin: 123.195,
                        transformOrigin: 123.195,
                    }}
                    id="el4qscfpagi0x"
                    fill="#FFF"
                    d="M119.81 266.79H126.58V271.57H119.81z"
                    className="animable"
                ></path>
                <path
                    style={{
                        WebkitTransformOrigin: 123.195,
                        MsTransformOrigin: 123.195,
                        transformOrigin: 123.195,
                    }}
                    id="eliysplnxikf"
                    fill="#FFF"
                    d="M119.81 273.81H126.58V278.59H119.81z"
                    className="animable"
                ></path>
                <path
                    style={{
                        WebkitTransformOrigin: 123.195,
                        MsTransformOrigin: 123.195,
                        transformOrigin: 123.195,
                    }}
                    id="elf7escrxf9q"
                    fill="#FFF"
                    d="M119.81 280.83H126.58V285.60999999999996H119.81z"
                    className="animable"
                ></path>
                <path
                    style={{
                        WebkitTransformOrigin: 123.195,
                        MsTransformOrigin: 123.195,
                        transformOrigin: 123.195,
                    }}
                    id="elc25ygtaxcot"
                    fill="#FFF"
                    d="M119.81 287.84H126.58V292.61999999999995H119.81z"
                    className="animable"
                ></path>
                <path
                    style={{
                        WebkitTransformOrigin: 100.595,
                        MsTransformOrigin: 100.595,
                        transformOrigin: 100.595,
                    }}
                    id="elqexsfkv4hsq"
                    fill="#FFF"
                    d="M97.21 294.78H103.97999999999999V299.55999999999995H97.21z"
                    className="animable"
                ></path>
                <path
                    style={{
                        WebkitTransformOrigin: 110.685,
                        MsTransformOrigin: 110.685,
                        transformOrigin: 110.685,
                    }}
                    id="elqye8q79nrp"
                    fill="#FFF"
                    d="M107.3 294.78H114.07V299.55999999999995H107.3z"
                    className="animable"
                ></path>
                <path
                    style={{
                        WebkitTransformOrigin: 123.195,
                        MsTransformOrigin: 123.195,
                        transformOrigin: 123.195,
                    }}
                    id="elf6468nt70ev"
                    fill="#FFF"
                    d="M119.81 294.78H126.58V299.55999999999995H119.81z"
                    className="animable"
                ></path>
                <path
                    style={{
                        WebkitTransformOrigin: 100.595,
                        MsTransformOrigin: 100.595,
                        transformOrigin: 100.595,
                    }}
                    id="el1ekbpgvvtpz"
                    fill="#FFF"
                    d="M97.21 301.71H103.97999999999999V306.48999999999995H97.21z"
                    className="animable"
                ></path>
                <path
                    style={{
                        WebkitTransformOrigin: 110.685,
                        MsTransformOrigin: 110.685,
                        transformOrigin: 110.685,
                    }}
                    id="elq82jdg2l1g"
                    fill="#FFF"
                    d="M107.3 301.71H114.07V306.48999999999995H107.3z"
                    className="animable"
                ></path>
                <path
                    style={{
                        WebkitTransformOrigin: 123.195,
                        MsTransformOrigin: 123.195,
                        transformOrigin: 123.195,
                    }}
                    id="elnn7qbj6lea"
                    fill="#FFF"
                    d="M119.81 301.71H126.58V306.48999999999995H119.81z"
                    className="animable"
                ></path>
                <path
                    style={{
                        WebkitTransformOrigin: 100.595,
                        MsTransformOrigin: 100.595,
                        transformOrigin: 100.595,
                    }}
                    id="elvznx6pu2wef"
                    fill="#FFF"
                    d="M97.21 308.65H103.97999999999999V313.42999999999995H97.21z"
                    className="animable"
                ></path>
                <path
                    style={{
                        WebkitTransformOrigin: 110.685,
                        MsTransformOrigin: 110.685,
                        transformOrigin: 110.685,
                    }}
                    id="elv1063l9o919"
                    fill="#FFF"
                    d="M107.3 308.65H114.07V313.42999999999995H107.3z"
                    className="animable"
                ></path>
                <path
                    style={{
                        WebkitTransformOrigin: 123.195,
                        MsTransformOrigin: 123.195,
                        transformOrigin: 123.195,
                    }}
                    id="elm3pii0ytckp"
                    fill="#FFF"
                    d="M119.81 308.65H126.58V313.42999999999995H119.81z"
                    className="animable"
                ></path>
                <path
                    style={{
                        WebkitTransformOrigin: 100.595,
                        MsTransformOrigin: 100.595,
                        transformOrigin: 100.595,
                    }}
                    id="el9ww7jt50tqc"
                    fill="#FFF"
                    d="M97.21 315.58H103.97999999999999V320.35999999999996H97.21z"
                    className="animable"
                ></path>
                <path
                    style={{
                        WebkitTransformOrigin: 110.685,
                        MsTransformOrigin: 110.685,
                        transformOrigin: 110.685,
                    }}
                    id="ellwnb2ba3gzm"
                    fill="#FFF"
                    d="M107.3 315.58H114.07V320.35999999999996H107.3z"
                    className="animable"
                ></path>
                <path
                    style={{
                        WebkitTransformOrigin: 123.195,
                        MsTransformOrigin: 123.195,
                        transformOrigin: 123.195,
                    }}
                    id="elzm8vwrtkr08"
                    fill="#FFF"
                    d="M119.81 315.58H126.58V320.35999999999996H119.81z"
                    className="animable"
                ></path>
                <path
                    style={{
                        WebkitTransformOrigin: 100.595,
                        MsTransformOrigin: 100.595,
                        transformOrigin: 100.595,
                    }}
                    id="el09b2nxx0jrxi"
                    fill="#FFF"
                    d="M97.21 322.52H103.97999999999999V327.29999999999995H97.21z"
                    className="animable"
                ></path>
                <path
                    style={{
                        WebkitTransformOrigin: 110.685,
                        MsTransformOrigin: 110.685,
                        transformOrigin: 110.685,
                    }}
                    id="ely7m9ni5xr3"
                    fill="#FFF"
                    d="M107.3 322.52H114.07V327.29999999999995H107.3z"
                    className="animable"
                ></path>
                <path
                    style={{
                        WebkitTransformOrigin: 123.195,
                        MsTransformOrigin: 123.195,
                        transformOrigin: 123.195,
                    }}
                    id="el6csrergzsb8"
                    fill="#FFF"
                    d="M119.81 322.52H126.58V327.29999999999995H119.81z"
                    className="animable"
                ></path>
                <path
                    style={{
                        WebkitTransformOrigin: 185.885,
                        MsTransformOrigin: 185.885,
                        transformOrigin: 185.885,
                    }}
                    id="eligqnn2vhao"
                    fill="#FFF"
                    d="M184.06 245.54H187.71V249.72H184.06z"
                    className="animable"
                ></path>
                <path
                    style={{
                        WebkitTransformOrigin: 192.225,
                        MsTransformOrigin: 192.225,
                        transformOrigin: 192.225,
                    }}
                    id="eljqd3plfuuml"
                    fill="#FFF"
                    d="M190.4 245.54H194.05V249.72H190.4z"
                    className="animable"
                ></path>
                <path
                    style={{
                        WebkitTransformOrigin: 185.885,
                        MsTransformOrigin: 185.885,
                        transformOrigin: 185.885,
                    }}
                    id="el4o154ggbd3"
                    fill="#FFF"
                    d="M184.06 251.84H187.71V256.02H184.06z"
                    className="animable"
                ></path>
                <path
                    style={{
                        WebkitTransformOrigin: 192.225,
                        MsTransformOrigin: 192.225,
                        transformOrigin: 192.225,
                    }}
                    id="eltnoim7gdho"
                    fill="#FFF"
                    d="M190.4 251.84H194.05V256.02H190.4z"
                    className="animable"
                ></path>
                <path
                    style={{
                        WebkitTransformOrigin: 185.885,
                        MsTransformOrigin: 185.885,
                        transformOrigin: 185.885,
                    }}
                    id="el856s8rtle1"
                    fill="#FFF"
                    d="M184.06 258.13H187.71V262.31H184.06z"
                    className="animable"
                ></path>
                <path
                    style={{
                        WebkitTransformOrigin: 192.225,
                        MsTransformOrigin: 192.225,
                        transformOrigin: 192.225,
                    }}
                    id="el49pj6hsa41j"
                    fill="#FFF"
                    d="M190.4 258.13H194.05V262.31H190.4z"
                    className="animable"
                ></path>
                <path
                    style={{
                        WebkitTransformOrigin: 185.885,
                        MsTransformOrigin: 185.885,
                        transformOrigin: 185.885,
                    }}
                    id="el89v56pnzbli"
                    fill="#FFF"
                    d="M184.06 264.43H187.71V268.61H184.06z"
                    className="animable"
                ></path>
                <path
                    style={{
                        WebkitTransformOrigin: 192.225,
                        MsTransformOrigin: 192.225,
                        transformOrigin: 192.225,
                    }}
                    id="elltp91pdwk5m"
                    fill="#FFF"
                    d="M190.4 264.43H194.05V268.61H190.4z"
                    className="animable"
                ></path>
                <path
                    style={{
                        WebkitTransformOrigin: 185.885,
                        MsTransformOrigin: 185.885,
                        transformOrigin: 185.885,
                    }}
                    id="elg28agkaxfs"
                    fill="#FFF"
                    d="M184.06 270.88H187.71V275.06H184.06z"
                    className="animable"
                ></path>
                <path
                    style={{
                        WebkitTransformOrigin: 192.225,
                        MsTransformOrigin: 192.225,
                        transformOrigin: 192.225,
                    }}
                    id="elndpbbog2xrl"
                    fill="#FFF"
                    d="M190.4 270.88H194.05V275.06H190.4z"
                    className="animable"
                ></path>
                <path
                    style={{
                        WebkitTransformOrigin: 185.885,
                        MsTransformOrigin: 185.885,
                        transformOrigin: 185.885,
                    }}
                    id="elxi782x5w7eh"
                    fill="#FFF"
                    d="M184.06 277.33H187.71V281.51H184.06z"
                    className="animable"
                ></path>
                <path
                    style={{
                        WebkitTransformOrigin: 192.225,
                        MsTransformOrigin: 192.225,
                        transformOrigin: 192.225,
                    }}
                    id="elgnluppulir5"
                    fill="#FFF"
                    d="M190.4 277.33H194.05V281.51H190.4z"
                    className="animable"
                ></path>
                <path
                    style={{
                        WebkitTransformOrigin: 185.885,
                        MsTransformOrigin: 185.885,
                        transformOrigin: 185.885,
                    }}
                    id="elvtbes2rhsj"
                    fill="#FFF"
                    d="M184.06 283.78H187.71V287.96H184.06z"
                    className="animable"
                ></path>
                <path
                    style={{
                        WebkitTransformOrigin: 192.225,
                        MsTransformOrigin: 192.225,
                        transformOrigin: 192.225,
                    }}
                    id="eloamqkpnfeqc"
                    fill="#FFF"
                    d="M190.4 283.78H194.05V287.96H190.4z"
                    className="animable"
                ></path>
                <path
                    style={{
                        WebkitTransformOrigin: 185.885,
                        MsTransformOrigin: 185.885,
                        transformOrigin: 185.885,
                    }}
                    id="elm2wjncs5fbe"
                    fill="#FFF"
                    d="M184.06 290.23H187.71V294.41H184.06z"
                    className="animable"
                ></path>
                <path
                    style={{
                        WebkitTransformOrigin: 192.225,
                        MsTransformOrigin: 192.225,
                        transformOrigin: 192.225,
                    }}
                    id="elgka4w2giyn"
                    fill="#FFF"
                    d="M190.4 290.23H194.05V294.41H190.4z"
                    className="animable"
                ></path>
                <path
                    style={{
                        WebkitTransformOrigin: 185.885,
                        MsTransformOrigin: 185.885,
                        transformOrigin: 185.885,
                    }}
                    id="elf5vxn7t11ae"
                    fill="#FFF"
                    d="M184.06 296.76H187.71V300.94H184.06z"
                    className="animable"
                ></path>
                <path
                    style={{
                        WebkitTransformOrigin: 192.225,
                        MsTransformOrigin: 192.225,
                        transformOrigin: 192.225,
                    }}
                    id="eleyn426r45t8"
                    fill="#FFF"
                    d="M190.4 296.76H194.05V300.94H190.4z"
                    className="animable"
                ></path>
                <path
                    style={{
                        WebkitTransformOrigin: 185.885,
                        MsTransformOrigin: 185.885,
                        transformOrigin: 185.885,
                    }}
                    id="elizlt3d6ul1s"
                    fill="#FFF"
                    d="M184.06 303.28H187.71V307.46H184.06z"
                    className="animable"
                ></path>
                <path
                    style={{
                        WebkitTransformOrigin: 192.225,
                        MsTransformOrigin: 192.225,
                        transformOrigin: 192.225,
                    }}
                    id="elo0tnu7585rf"
                    fill="#FFF"
                    d="M190.4 303.28H194.05V307.46H190.4z"
                    className="animable"
                ></path>
                <path
                    style={{
                        WebkitTransformOrigin: 185.885,
                        MsTransformOrigin: 185.885,
                        transformOrigin: 185.885,
                    }}
                    id="elvp80v13hdrm"
                    fill="#FFF"
                    d="M184.06 309.8H187.71V313.98H184.06z"
                    className="animable"
                ></path>
                <path
                    style={{
                        WebkitTransformOrigin: 192.225,
                        MsTransformOrigin: 192.225,
                        transformOrigin: 192.225,
                    }}
                    id="elujkukky528a"
                    fill="#FFF"
                    d="M190.4 309.8H194.05V313.98H190.4z"
                    className="animable"
                ></path>
                <path
                    style={{
                        WebkitTransformOrigin: 185.885,
                        MsTransformOrigin: 185.885,
                        transformOrigin: 185.885,
                    }}
                    id="elofxu99wpt9r"
                    fill="#FFF"
                    d="M184.06 316.32H187.71V320.5H184.06z"
                    className="animable"
                ></path>
                <path
                    style={{
                        WebkitTransformOrigin: 192.225,
                        MsTransformOrigin: 192.225,
                        transformOrigin: 192.225,
                    }}
                    id="el9g7cai8r4r"
                    fill="#FFF"
                    d="M190.4 316.32H194.05V320.5H190.4z"
                    className="animable"
                ></path>
                <path
                    style={{
                        WebkitTransformOrigin: 199.435,
                        MsTransformOrigin: 199.435,
                        transformOrigin: 199.435,
                    }}
                    id="elxze2vxqm1ad"
                    fill="#FFF"
                    d="M197.61 245.54H201.26000000000002V249.72H197.61z"
                    className="animable"
                ></path>
                <path
                    style={{
                        WebkitTransformOrigin: 205.775,
                        MsTransformOrigin: 205.775,
                        transformOrigin: 205.775,
                    }}
                    id="els27yh6npzjs"
                    fill="#FFF"
                    d="M203.95 245.54H207.6V249.72H203.95z"
                    className="animable"
                ></path>
                <path
                    style={{
                        WebkitTransformOrigin: 199.435,
                        MsTransformOrigin: 199.435,
                        transformOrigin: 199.435,
                    }}
                    id="elq4keo4noenj"
                    fill="#FFF"
                    d="M197.61 251.84H201.26000000000002V256.02H197.61z"
                    className="animable"
                ></path>
                <path
                    style={{
                        WebkitTransformOrigin: 205.775,
                        MsTransformOrigin: 205.775,
                        transformOrigin: 205.775,
                    }}
                    id="el0e6bqtn78mpl"
                    fill="#FFF"
                    d="M203.95 251.84H207.6V256.02H203.95z"
                    className="animable"
                ></path>
                <path
                    style={{
                        WebkitTransformOrigin: 199.435,
                        MsTransformOrigin: 199.435,
                        transformOrigin: 199.435,
                    }}
                    id="el7crdrg8pcxc"
                    fill="#FFF"
                    d="M197.61 258.13H201.26000000000002V262.31H197.61z"
                    className="animable"
                ></path>
                <path
                    style={{
                        WebkitTransformOrigin: 205.775,
                        MsTransformOrigin: 205.775,
                        transformOrigin: 205.775,
                    }}
                    id="el2n4ljl472bk"
                    fill="#FFF"
                    d="M203.95 258.13H207.6V262.31H203.95z"
                    className="animable"
                ></path>
                <path
                    style={{
                        WebkitTransformOrigin: 199.435,
                        MsTransformOrigin: 199.435,
                        transformOrigin: 199.435,
                    }}
                    id="ellih2eltsdbn"
                    fill="#FFF"
                    d="M197.61 264.43H201.26000000000002V268.61H197.61z"
                    className="animable"
                ></path>
                <path
                    style={{
                        WebkitTransformOrigin: 205.775,
                        MsTransformOrigin: 205.775,
                        transformOrigin: 205.775,
                    }}
                    id="elvy52ggwh3o"
                    fill="#FFF"
                    d="M203.95 264.43H207.6V268.61H203.95z"
                    className="animable"
                ></path>
                <path
                    style={{
                        WebkitTransformOrigin: 199.435,
                        MsTransformOrigin: 199.435,
                        transformOrigin: 199.435,
                    }}
                    id="elc1vbr8nzak"
                    fill="#FFF"
                    d="M197.61 270.88H201.26000000000002V275.06H197.61z"
                    className="animable"
                ></path>
                <path
                    style={{
                        WebkitTransformOrigin: 205.775,
                        MsTransformOrigin: 205.775,
                        transformOrigin: 205.775,
                    }}
                    id="elt36txmnzhl"
                    fill="#FFF"
                    d="M203.95 270.88H207.6V275.06H203.95z"
                    className="animable"
                ></path>
                <path
                    style={{
                        WebkitTransformOrigin: 199.435,
                        MsTransformOrigin: 199.435,
                        transformOrigin: 199.435,
                    }}
                    id="elbgv3kg73wni"
                    fill="#FFF"
                    d="M197.61 277.33H201.26000000000002V281.51H197.61z"
                    className="animable"
                ></path>
                <path
                    style={{
                        WebkitTransformOrigin: 205.775,
                        MsTransformOrigin: 205.775,
                        transformOrigin: 205.775,
                    }}
                    id="el4qego4kn1o8"
                    fill="#FFF"
                    d="M203.95 277.33H207.6V281.51H203.95z"
                    className="animable"
                ></path>
                <path
                    style={{
                        WebkitTransformOrigin: 199.435,
                        MsTransformOrigin: 199.435,
                        transformOrigin: 199.435,
                    }}
                    id="el78vguyfjkme"
                    fill="#FFF"
                    d="M197.61 283.78H201.26000000000002V287.96H197.61z"
                    className="animable"
                ></path>
                <path
                    style={{
                        WebkitTransformOrigin: 205.775,
                        MsTransformOrigin: 205.775,
                        transformOrigin: 205.775,
                    }}
                    id="el3iagd5onaoo"
                    fill="#FFF"
                    d="M203.95 283.78H207.6V287.96H203.95z"
                    className="animable"
                ></path>
                <path
                    style={{
                        WebkitTransformOrigin: 199.435,
                        MsTransformOrigin: 199.435,
                        transformOrigin: 199.435,
                    }}
                    id="elctoyy6i9tym"
                    fill="#FFF"
                    d="M197.61 290.23H201.26000000000002V294.41H197.61z"
                    className="animable"
                ></path>
                <path
                    style={{
                        WebkitTransformOrigin: 205.775,
                        MsTransformOrigin: 205.775,
                        transformOrigin: 205.775,
                    }}
                    id="el2mxxbrm0uqt"
                    fill="#FFF"
                    d="M203.95 290.23H207.6V294.41H203.95z"
                    className="animable"
                ></path>
                <path
                    style={{
                        WebkitTransformOrigin: 199.435,
                        MsTransformOrigin: 199.435,
                        transformOrigin: 199.435,
                    }}
                    id="elby9pcso9eye"
                    fill="#FFF"
                    d="M197.61 296.76H201.26000000000002V300.94H197.61z"
                    className="animable"
                ></path>
                <path
                    style={{
                        WebkitTransformOrigin: 205.775,
                        MsTransformOrigin: 205.775,
                        transformOrigin: 205.775,
                    }}
                    id="el4m7i9hhp59n"
                    fill="#FFF"
                    d="M203.95 296.76H207.6V300.94H203.95z"
                    className="animable"
                ></path>
                <path
                    style={{
                        WebkitTransformOrigin: 199.435,
                        MsTransformOrigin: 199.435,
                        transformOrigin: 199.435,
                    }}
                    id="elyefjd6dmrr"
                    fill="#FFF"
                    d="M197.61 303.28H201.26000000000002V307.46H197.61z"
                    className="animable"
                ></path>
                <path
                    style={{
                        WebkitTransformOrigin: 205.775,
                        MsTransformOrigin: 205.775,
                        transformOrigin: 205.775,
                    }}
                    id="ele250xppwzrs"
                    fill="#FFF"
                    d="M203.95 303.28H207.6V307.46H203.95z"
                    className="animable"
                ></path>
                <path
                    style={{
                        WebkitTransformOrigin: 199.435,
                        MsTransformOrigin: 199.435,
                        transformOrigin: 199.435,
                    }}
                    id="el4ywybeuqnec"
                    fill="#FFF"
                    d="M197.61 309.8H201.26000000000002V313.98H197.61z"
                    className="animable"
                ></path>
                <path
                    style={{
                        WebkitTransformOrigin: 205.775,
                        MsTransformOrigin: 205.775,
                        transformOrigin: 205.775,
                    }}
                    id="ela91j6mdqmcq"
                    fill="#FFF"
                    d="M203.95 309.8H207.6V313.98H203.95z"
                    className="animable"
                ></path>
                <path
                    style={{
                        WebkitTransformOrigin: 199.435,
                        MsTransformOrigin: 199.435,
                        transformOrigin: 199.435,
                    }}
                    id="el3vgtrp29x0s"
                    fill="#FFF"
                    d="M197.61 316.32H201.26000000000002V320.5H197.61z"
                    className="animable"
                ></path>
                <path
                    style={{
                        WebkitTransformOrigin: 205.775,
                        MsTransformOrigin: 205.775,
                        transformOrigin: 205.775,
                    }}
                    id="elji69snxbqe"
                    fill="#FFF"
                    d="M203.95 316.32H207.6V320.5H203.95z"
                    className="animable"
                ></path>
                <path
                    d="M405.72 233.9S371.53 310 427.47 310s19.68-78.72 19.68-78.72 13-5.7-2.07-48.17-21.73-42.47-28-42.47c-7.88 0-15.22 18.39-9.84 46.61.03.03-19.65 35.25-1.52 46.65z"
                    style={{
                        WebkitTransformOrigin: 427.041,
                        MsTransformOrigin: 427.041,
                        transformOrigin: 427.041,
                    }}
                    id="elyaxs6gxin1"
                    fill="#EBEBEB"
                    className="animable"
                ></path>
                <path
                    style={{
                        WebkitTransformOrigin: 427.045,
                        MsTransformOrigin: 427.045,
                        transformOrigin: 427.045,
                    }}
                    id="elc9e9lvx055h"
                    fill="#A6A6A6"
                    d="M432.44 360.53L427.04 245.83 421.65 360.53 432.44 360.53z"
                    className="animable"
                ></path>
                <path
                    style={{
                        WebkitTransformOrigin: 419.075,
                        MsTransformOrigin: 419.075,
                        transformOrigin: 419.075,
                    }}
                    id="eleusvvl41uq"
                    fill="#A6A6A6"
                    d="M429.24 293.92L408.91 270.17 424.85 297.06 429.24 293.92z"
                    className="animable"
                ></path>
                <path
                    d="M84.69 256.82s28 62.36-17.82 62.36-16.12-64.48-16.12-64.48-10.6-4.66 1.7-39.45 17.8-34.78 22.91-34.78c6.45 0 12.46 15.06 8 38.18.05 0 16.17 28.84 1.33 38.17z"
                    style={{
                        WebkitTransformOrigin: 67.2222,
                        MsTransformOrigin: 67.2222,
                        transformOrigin: 67.2222,
                    }}
                    id="el3cju2oer67d"
                    fill="#DBDBDB"
                    className="animable"
                ></path>
                <path
                    style={{
                        WebkitTransformOrigin: 67.225,
                        MsTransformOrigin: 67.225,
                        transformOrigin: 67.225,
                    }}
                    id="elekqkmo032dl"
                    fill="#A6A6A6"
                    d="M62.81 360.53L67.22 266.6 71.64 360.53 62.81 360.53z"
                    className="animable"
                ></path>
                <path
                    style={{
                        WebkitTransformOrigin: 73.755,
                        MsTransformOrigin: 73.755,
                        transformOrigin: 73.755,
                    }}
                    id="elmsemcq26cf"
                    fill="#A6A6A6"
                    d="M65.43 305.98L82.08 286.53 69.02 308.55 65.43 305.98z"
                    className="animable"
                ></path>
                <path
                    d="M399.64 242.08s15.95-6.33 28.61 15.7c13 22.59-3.29 37.48-16.2 28.62 0 0-10.13 21.26-30.39 16.2s-23.55-21.27-23.55-21.27-18 1.27-18.48-12.15 12.66-25.32 12.66-25.32.76-33.43 22.53-33.17 24.82 31.39 24.82 31.39z"
                    style={{
                        WebkitTransformOrigin: 386.321,
                        MsTransformOrigin: 386.321,
                        transformOrigin: 386.321,
                    }}
                    id="el7acarnpdlop"
                    fill="#DBDBDB"
                    className="animable"
                ></path>
                <path
                    style={{
                        WebkitTransformOrigin: 380.27,
                        MsTransformOrigin: 380.27,
                        transformOrigin: 380.27,
                    }}
                    id="elyn07oi1m3q9"
                    fill="#A6A6A6"
                    d="M384.83 360.53L380.27 261.07 375.71 360.53 384.83 360.53z"
                    className="animable"
                ></path>
                <path
                    style={{
                        WebkitTransformOrigin: 386.955,
                        MsTransformOrigin: 386.955,
                        transformOrigin: 386.955,
                    }}
                    id="ela0uta4ett8o"
                    fill="#A6A6A6"
                    d="M382.22 297.25L395.6 270.55 378.31 294.91 382.22 297.25z"
                    className="animable"
                ></path>
                <path
                    d="M446.11 113.66H338.44s10.68-29.16 27.11-29.16 21.57 20.12 21.57 20.12 11.09-10.93 20.2-10.93 17 14.45 17 14.45 12.89-7.77 21.79 5.52z"
                    style={{
                        WebkitTransformOrigin: 392.275,
                        MsTransformOrigin: 392.275,
                        transformOrigin: 392.275,
                    }}
                    id="eltv94hpmo9i"
                    fill="#EBEBEB"
                    className="animable"
                ></path>
                <path
                    d="M317.34 147.3H267s11.27-16.3 20.12-16.3 11.48 10.86 11.48 10.86 6.25-5.06 12-4.16 6.74 9.6 6.74 9.6z"
                    style={{
                        WebkitTransformOrigin: 292.17,
                        MsTransformOrigin: 292.17,
                        transformOrigin: 292.17,
                    }}
                    id="eliihel43l3ac"
                    fill="#EBEBEB"
                    className="animable"
                    opacity="0.5"
                ></path>
                <path
                    d="M151.9 131.45h-98s13.42-30 27.78-31.83 21.22 22.26 21.22 22.26 9.26-8.53 16.54-8.53 12.91 11.24 12.91 11.24 14.55-7.7 19.55 6.86z"
                    style={{
                        WebkitTransformOrigin: 102.9,
                        MsTransformOrigin: 102.9,
                        transformOrigin: 102.9,
                    }}
                    id="elvfy5ld8679h"
                    fill="#EBEBEB"
                    className="animable"
                ></path>
                <path
                    d="M245.05 76.84h-66.21s11.37-21.71 23.71-22 18.82 16.43 18.82 16.43 19.19-15.43 23.68 5.57z"
                    style={{
                        WebkitTransformOrigin: 211.945,
                        MsTransformOrigin: 211.945,
                        transformOrigin: 211.945,
                    }}
                    id="el9d3b5lghztw"
                    fill="#EBEBEB"
                    className="animable"
                    opacity="0.5"
                ></path>
            </g>
            <g
                id="freepik--Shadow--inject-3"
                style={{
                    WebkitTransformOrigin: 250,
                    MsTransformOrigin: 250,
                    transformOrigin: 250,
                }}
                className="animable animator-hidden"
            >
                <ellipse
                    cx="250"
                    cy="446.9"
                    fill="#EBEBEB"
                    rx="170.02"
                    ry="14.57"
                    style={{
                        WebkitTransformOrigin: 250,
                        MsTransformOrigin: 250,
                        transformOrigin: 250,
                    }}
                    id="elcp13sdclaok"
                    className="animable"
                ></ellipse>
            </g>
            <g
                id="freepik--Plants--inject-3"
                style={{
                    WebkitTransformOrigin: 250,
                    MsTransformOrigin: 250,
                    transformOrigin: 250,
                }}
                className="animable animator-hidden"
            >
                <path
                    d="M140.93 357.32h-57s.13-8.48 5.73-10.73a7.65 7.65 0 018.94 2.14s2.28-15.6 12.72-15.6 15.44 13.46 15.44 13.46 2.34-4.07 8.13-3.31 6.04 14.04 6.04 14.04z"
                    style={{
                        WebkitTransformOrigin: 112.43,
                        MsTransformOrigin: 112.43,
                        transformOrigin: 112.43,
                    }}
                    id="el8y642qduc4"
                    fill="#455A64"
                    className="animable"
                ></path>
                <path
                    d="M458.4 357.32s-1.75-8.15-5.24-9.41-5.61 2.41-5.61 2.41-.82-7.79-5.77-10.12-8.66 2.44-8.66 2.44-3.8-9.89-10.58-9.89-10.2 14.47-10.2 14.47-4-4.31-8.11-1.88-4.1 12-4.1 12z"
                    style={{
                        WebkitTransformOrigin: 429.265,
                        MsTransformOrigin: 429.265,
                        transformOrigin: 429.265,
                    }}
                    id="el88432s7qfqc"
                    fill="#37474F"
                    className="animable"
                ></path>
                <path
                    d="M94.15 357.32H41.6s1.73-7.41 5.22-7.93 5.1 2.91 5.1 2.91-.16-10.33 6.35-12.09 12 7.36 12 7.36 4.08-5.2 9.64-4.87 7 8.94 7 8.94 5.01-2.99 7.24 5.68z"
                    style={{
                        WebkitTransformOrigin: 67.875,
                        MsTransformOrigin: 67.875,
                        transformOrigin: 67.875,
                    }}
                    id="elqk5fck528n"
                    fill="#37474F"
                    className="animable"
                ></path>
                <path
                    d="M403.9 357.32h-32.29s.07-4.8 3.25-6.08a4.33 4.33 0 015.06 1.21s1.29-8.83 7.21-8.83 8.74 7.62 8.74 7.62a4.54 4.54 0 014.61-1.87c3.29.42 3.42 7.95 3.42 7.95z"
                    style={{
                        WebkitTransformOrigin: 387.755,
                        MsTransformOrigin: 387.755,
                        transformOrigin: 387.755,
                    }}
                    id="elpeyg1be6ut8"
                    fill="#455A64"
                    className="animable"
                ></path>
            </g>
            <g
                id="freepik--Floor--inject-3"
                style={{
                    WebkitTransformOrigin: 250,
                    MsTransformOrigin: 250,
                    transformOrigin: 250,
                }}
                className="animable animator-hidden"
            >
                <path
                    style={{
                        WebkitTransformOrigin: 250,
                        MsTransformOrigin: 250,
                        transformOrigin: 250,
                    }}
                    id="elaiv5w343f7e"
                    fill="#A6A6A6"
                    d="M34.18 357.32H465.82V365.90999999999997H34.18z"
                    className="animable"
                ></path>
                <path
                    d="M56.39 357.32a18.7 18.7 0 010 8.59 18.7 18.7 0 010-8.59z"
                    style={{
                        WebkitTransformOrigin: 56.39,
                        MsTransformOrigin: 56.39,
                        transformOrigin: 56.39,
                    }}
                    id="elnz107b8duml"
                    fill="#263238"
                    className="animable"
                ></path>
                <path
                    d="M82.2 357.32a18.7 18.7 0 010 8.59 18.7 18.7 0 010-8.59z"
                    style={{
                        WebkitTransformOrigin: 82.2,
                        MsTransformOrigin: 82.2,
                        transformOrigin: 82.2,
                    }}
                    id="eltvzl1bue0oa"
                    fill="#263238"
                    className="animable"
                ></path>
                <path
                    d="M108 357.32a18.7 18.7 0 010 8.59 18.7 18.7 0 010-8.59z"
                    style={{
                        WebkitTransformOrigin: 108,
                        MsTransformOrigin: 108,
                        transformOrigin: 108,
                    }}
                    id="elm79dbmjs2k"
                    fill="#263238"
                    className="animable"
                ></path>
                <path
                    d="M133.83 357.32a18.7 18.7 0 010 8.59 18.7 18.7 0 010-8.59z"
                    style={{
                        WebkitTransformOrigin: 133.83,
                        MsTransformOrigin: 133.83,
                        transformOrigin: 133.83,
                    }}
                    id="elgbih3fschvd"
                    fill="#263238"
                    className="animable"
                ></path>
                <path
                    d="M159.65 357.32a18.7 18.7 0 010 8.59 18.7 18.7 0 010-8.59z"
                    style={{
                        WebkitTransformOrigin: 159.65,
                        MsTransformOrigin: 159.65,
                        transformOrigin: 159.65,
                    }}
                    id="elrc9nmyv83lp"
                    fill="#263238"
                    className="animable"
                ></path>
                <path
                    d="M185.46 357.32a18.7 18.7 0 010 8.59 18.7 18.7 0 010-8.59z"
                    style={{
                        WebkitTransformOrigin: 185.46,
                        MsTransformOrigin: 185.46,
                        transformOrigin: 185.46,
                    }}
                    id="elqqc3vsijcqd"
                    fill="#263238"
                    className="animable"
                ></path>
                <path
                    d="M211.28 357.32a18.7 18.7 0 010 8.59 18.7 18.7 0 010-8.59z"
                    style={{
                        WebkitTransformOrigin: 211.28,
                        MsTransformOrigin: 211.28,
                        transformOrigin: 211.28,
                    }}
                    id="elgezohpwyvqo"
                    fill="#263238"
                    className="animable"
                ></path>
                <path
                    d="M237.09 357.32a18.7 18.7 0 010 8.59 18.7 18.7 0 010-8.59z"
                    style={{
                        WebkitTransformOrigin: 237.09,
                        MsTransformOrigin: 237.09,
                        transformOrigin: 237.09,
                    }}
                    id="elr877hs7ya3"
                    fill="#263238"
                    className="animable"
                ></path>
                <path
                    d="M262.91 357.32a18.7 18.7 0 010 8.59 18.7 18.7 0 010-8.59z"
                    style={{
                        WebkitTransformOrigin: 262.91,
                        MsTransformOrigin: 262.91,
                        transformOrigin: 262.91,
                    }}
                    id="el6c3fit72qwm"
                    fill="#263238"
                    className="animable"
                ></path>
                <path
                    d="M288.72 357.32a18.7 18.7 0 010 8.59 18.7 18.7 0 010-8.59z"
                    style={{
                        WebkitTransformOrigin: 288.72,
                        MsTransformOrigin: 288.72,
                        transformOrigin: 288.72,
                    }}
                    id="el3an44sgnih4"
                    fill="#263238"
                    className="animable"
                ></path>
                <path
                    d="M314.54 357.32a18.7 18.7 0 010 8.59 18.7 18.7 0 010-8.59z"
                    style={{
                        WebkitTransformOrigin: 314.54,
                        MsTransformOrigin: 314.54,
                        transformOrigin: 314.54,
                    }}
                    id="el8s4j74m5n1s"
                    fill="#263238"
                    className="animable"
                ></path>
                <path
                    d="M340.35 357.32a18.7 18.7 0 010 8.59 18.7 18.7 0 010-8.59z"
                    style={{
                        WebkitTransformOrigin: 340.35,
                        MsTransformOrigin: 340.35,
                        transformOrigin: 340.35,
                    }}
                    id="ellmja0jcic5p"
                    fill="#263238"
                    className="animable"
                ></path>
                <path
                    d="M366.17 357.32a18.7 18.7 0 010 8.59 18.7 18.7 0 010-8.59z"
                    style={{
                        WebkitTransformOrigin: 366.17,
                        MsTransformOrigin: 366.17,
                        transformOrigin: 366.17,
                    }}
                    id="elvl9piv8qvij"
                    fill="#263238"
                    className="animable"
                ></path>
                <path
                    d="M392 357.32a18.7 18.7 0 010 8.59 18.7 18.7 0 010-8.59z"
                    style={{
                        WebkitTransformOrigin: 392,
                        MsTransformOrigin: 392,
                        transformOrigin: 392,
                    }}
                    id="el8kfddbgq1da"
                    fill="#263238"
                    className="animable"
                ></path>
                <path
                    d="M417.8 357.32a18.7 18.7 0 010 8.59 18.7 18.7 0 010-8.59z"
                    style={{
                        WebkitTransformOrigin: 417.8,
                        MsTransformOrigin: 417.8,
                        transformOrigin: 417.8,
                    }}
                    id="el4wwdig43168"
                    fill="#263238"
                    className="animable"
                ></path>
                <path
                    d="M443.61 357.32a18.7 18.7 0 010 8.59 18.7 18.7 0 010-8.59z"
                    style={{
                        WebkitTransformOrigin: 443.61,
                        MsTransformOrigin: 443.61,
                        transformOrigin: 443.61,
                    }}
                    id="el1v47zxapcj2"
                    fill="#263238"
                    className="animable"
                ></path>
                <path
                    style={{
                        WebkitTransformOrigin: 250,
                        MsTransformOrigin: 250,
                        transformOrigin: 250,
                    }}
                    id="el9522mtp6f7s"
                    fill="#263238"
                    d="M33.36 365.91L87.52 365.67 141.68 365.58 250 365.41 358.32 365.58 412.48 365.67 466.64 365.91 412.48 366.16 358.32 366.24 250 366.41 141.68 366.24 87.52 366.15 33.36 365.91z"
                    className="animable"
                ></path>
            </g>
            <g
                id="freepik--speech-bubble--inject-3"
                style={{
                    WebkitTransformOrigin: 312.495,
                    MsTransformOrigin: 312.495,
                    transformOrigin: 312.495,
                }}
                className="animable"
            >
                <path
                    d="M339.55 79.86a25.58 25.58 0 01-40.84 20.55l-12.54 3.2 5.22-11.68a25.59 25.59 0 1148.16-12.07z"
                    style={{
                        WebkitTransformOrigin: 312.86,
                        MsTransformOrigin: 312.86,
                        transformOrigin: 312.86,
                    }}
                    id="elr2jz2e7c8qn"
                    fill="#FFF"
                    className="animable"
                ></path>
                <path
                    d="M339.55 79.86a25.73 25.73 0 01-3.79 13.44 27.44 27.44 0 01-2 2.9 29.39 29.39 0 01-2.44 2.55 25.37 25.37 0 01-5.79 4.06 25.77 25.77 0 01-6.68 2.35 24.68 24.68 0 01-3.53.44 23.5 23.5 0 01-3.56 0 25.84 25.84 0 01-13.24-4.91l.3.06-12.54 3.25-.88.23.37-.83L291 91.74v.42a25.84 25.84 0 1148.59-12.3zm0 0a25.33 25.33 0 00-31.75-24.54 25.13 25.13 0 00-10.08 5.25 26.59 26.59 0 00-6.72 9.11 25.28 25.28 0 00.85 22l.11.22-.09.21-5.26 11.66-.51-.61 12.56-3.12h.17l.13.1a25.21 25.21 0 0012.85 5 21.52 21.52 0 003.5.06 24.51 24.51 0 0010.13-2.58 23.75 23.75 0 005.79-3.95 26.61 26.61 0 002.45-2.53 27.16 27.16 0 002-2.87 25.63 25.63 0 003.87-13.41z"
                    style={{
                        WebkitTransformOrigin: 312.495,
                        MsTransformOrigin: 312.495,
                        transformOrigin: 312.495,
                    }}
                    id="elt17knrx52i"
                    fill="#263238"
                    className="animable"
                ></path>
                <path
                    d="M325.29 84.2A35.12 35.12 0 01314 94a35.12 35.12 0 01-11.25-9.76c-4-5.24-3.76-13.45 1.9-15.22C311 67 314 76.44 314 76.44s3.16-9.44 9.39-7.44c5.61 1.81 5.86 10 1.9 15.2z"
                    style={{
                        WebkitTransformOrigin: 314.004,
                        MsTransformOrigin: 314.004,
                        transformOrigin: 314.004,
                    }}
                    id="elw7au8n5arba"
                    fill="#FFC727"
                    className="animable"
                ></path>
                <path
                    style={{
                        WebkitTransformOrigin: 314.045,
                        MsTransformOrigin: 314.045,
                        transformOrigin: 314.045,
                    }}
                    id="elbhuztqgut7"
                    fill="#FFF"
                    d="M309.62 89.82L307.91 82.71 306.8 85.21 299.9 85.21 299.9 84.41 306.27 84.41 308.14 80.22 309.47 85.76 311.35 74.28 313.01 83.36 314.33 79.57 316.4 85.78 316.86 84.41 328.19 84.41 328.19 85.21 317.44 85.21 316.4 88.31 314.31 82.06 312.77 86.51 311.4 78.97 309.62 89.82z"
                    className="animable"
                ></path>
            </g>
            <g
                id="freepik--Character--inject-3"
                style={{
                    WebkitTransformOrigin: 249.999,
                    MsTransformOrigin: 249.999,
                    transformOrigin: 249.999,
                }}
                className="animable animator-active"
            >
                <path
                    d="M244.79 152.45c.41 25.86 6.58 55.09 14.88 55.73 11.18.87 35.78-5.65 48-12.09 3.59-1.89-11.69-29.52-15.53-27.5-4.64 2.44-23.72 8.1-24.24 8.19-1.1.21-7.57-22.15-12.36-31.73-5.54-11.18-10.93-4.68-10.75 7.4z"
                    style={{
                        WebkitTransformOrigin: 276.499,
                        MsTransformOrigin: 276.499,
                        transformOrigin: 276.499,
                    }}
                    id="eluaeke1knded"
                    fill="#AD6359"
                    className="animable"
                ></path>
                <path
                    d="M325.5 185.5c-4.58 4.95-17.92 11.4-21.73 11.72s-15.54-22-13.91-25.56 18.36-19 25.17-20.06 15.22 28.77 10.47 33.9z"
                    style={{
                        WebkitTransformOrigin: 308.297,
                        MsTransformOrigin: 308.297,
                        transformOrigin: 308.297,
                    }}
                    id="elwegc6roewb"
                    fill="#AD6359"
                    className="animable"
                ></path>
                <path
                    style={{
                        WebkitTransformOrigin: 304.295,
                        MsTransformOrigin: 304.295,
                        transformOrigin: 304.295,
                    }}
                    id="elg4237nsqsj9"
                    fill="#DBDBDB"
                    d="M301.54 137.1H307.05V143.44H301.54z"
                    className="animable"
                    transform="rotate(-11.63)"
                ></path>
                <path
                    style={{
                        WebkitTransformOrigin: 305.25,
                        MsTransformOrigin: 305.25,
                        transformOrigin: 305.25,
                    }}
                    id="elsnjpb88emy"
                    fill="#C7C7C7"
                    d="M300.76 141.29H309.74V148.53H300.76z"
                    className="animable"
                    transform="rotate(-11.63)"
                ></path>
                <path
                    d="M318.24 155.07c1.32 6.42 6.68 32.43 6.68 32.43L304 191.81l-6.67-32.44c-1.33-6.41 1.69-12.2 8.11-13.52s11.48 2.8 12.8 9.22z"
                    style={{
                        WebkitTransformOrigin: 310.971,
                        MsTransformOrigin: 310.971,
                        transformOrigin: 310.971,
                    }}
                    id="elz8g24a2cq3i"
                    fill="#FFC727"
                    className="animable"
                ></path>
                <path
                    d="M318.24 155.07c1.32 6.42 6.68 32.43 6.68 32.43L304 191.81l-6.67-32.44c-1.33-6.41 1.69-12.2 8.11-13.52s11.48 2.8 12.8 9.22z"
                    style={{
                        WebkitTransformOrigin: 310.971,
                        MsTransformOrigin: 310.971,
                        transformOrigin: 310.971,
                    }}
                    id="elubb875ago9g"
                    className="animable"
                    opacity="0.1"
                ></path>
                <path
                    d="M304.81 148.5a.85.85 0 01-.65 1 .83.83 0 01-.33-1.63.82.82 0 01.98.63z"
                    style={{
                        WebkitTransformOrigin: 303.995,
                        MsTransformOrigin: 303.995,
                        transformOrigin: 303.995,
                    }}
                    id="elos3qmw4gio"
                    fill="#FFF"
                    className="animable"
                ></path>
                <path
                    d="M316.71 153.7a.83.83 0 11-1.63.33.82.82 0 01.64-1 .85.85 0 01.99.67z"
                    style={{
                        WebkitTransformOrigin: 315.891,
                        MsTransformOrigin: 315.891,
                        transformOrigin: 315.891,
                    }}
                    id="elzdnezbfr8cr"
                    fill="#FFF"
                    className="animable"
                ></path>
                <path
                    d="M307.62 156.42a.83.83 0 11-1-.65.85.85 0 011 .65z"
                    style={{
                        WebkitTransformOrigin: 306.805,
                        MsTransformOrigin: 306.805,
                        transformOrigin: 306.805,
                    }}
                    id="el7owezk62nac"
                    fill="#FFF"
                    className="animable"
                ></path>
                <path
                    d="M310 164a.83.83 0 11-1.63.33.82.82 0 01.64-1 .85.85 0 01.99.67z"
                    style={{
                        WebkitTransformOrigin: 309.182,
                        MsTransformOrigin: 309.182,
                        transformOrigin: 309.182,
                    }}
                    id="elh7etew8d0zh"
                    fill="#FFF"
                    className="animable"
                ></path>
                <path
                    d="M303.29 173.91a.83.83 0 11-1-.65.83.83 0 011 .65z"
                    style={{
                        WebkitTransformOrigin: 302.475,
                        MsTransformOrigin: 302.475,
                        transformOrigin: 302.475,
                    }}
                    id="el1wvpp7bhe3z"
                    fill="#FFF"
                    className="animable"
                ></path>
                <path
                    d="M301 166.84a.82.82 0 01-.64 1 .85.85 0 01-1-.65.83.83 0 011.63-.33z"
                    style={{
                        WebkitTransformOrigin: 300.183,
                        MsTransformOrigin: 300.183,
                        transformOrigin: 300.183,
                    }}
                    id="eljxcx0hlugy"
                    fill="#FFF"
                    className="animable"
                ></path>
                <path
                    d="M299.61 159.12a.83.83 0 11-1-.65.85.85 0 011 .65z"
                    style={{
                        WebkitTransformOrigin: 298.795,
                        MsTransformOrigin: 298.795,
                        transformOrigin: 298.795,
                    }}
                    id="eljr42d7v863k"
                    fill="#FFF"
                    className="animable"
                ></path>
                <path
                    d="M302.17 153.7a.82.82 0 01-.64 1 .85.85 0 01-1-.65.83.83 0 111.63-.33z"
                    style={{
                        WebkitTransformOrigin: 301.353,
                        MsTransformOrigin: 301.353,
                        transformOrigin: 301.353,
                    }}
                    id="elw2nxv3i67v"
                    fill="#FFF"
                    className="animable"
                ></path>
                <path
                    d="M304.65 161.61a.83.83 0 01-.64 1 .85.85 0 01-1-.65.83.83 0 011.63-.33z"
                    style={{
                        WebkitTransformOrigin: 303.832,
                        MsTransformOrigin: 303.832,
                        transformOrigin: 303.832,
                    }}
                    id="el0wmcvweq1szo"
                    fill="#FFF"
                    className="animable"
                ></path>
                <path
                    d="M310.82 151.17a.85.85 0 01-.65 1 .83.83 0 01-1-.65.82.82 0 01.64-1 .83.83 0 011.01.65z"
                    style={{
                        WebkitTransformOrigin: 309.992,
                        MsTransformOrigin: 309.992,
                        transformOrigin: 309.992,
                    }}
                    id="elsgfgfm2y9fl"
                    fill="#FFF"
                    className="animable"
                ></path>
                <path
                    d="M313.43 158.88a.83.83 0 01-.65 1 .82.82 0 01-1-.64.85.85 0 01.65-1 .83.83 0 011 .64z"
                    style={{
                        WebkitTransformOrigin: 312.607,
                        MsTransformOrigin: 312.607,
                        transformOrigin: 312.607,
                    }}
                    id="elrw8pcdktfe"
                    fill="#FFF"
                    className="animable"
                ></path>
                <path
                    d="M306.65 169.1a.83.83 0 11-1-.65.83.83 0 011 .65z"
                    style={{
                        WebkitTransformOrigin: 305.835,
                        MsTransformOrigin: 305.835,
                        transformOrigin: 305.835,
                    }}
                    id="el6o5agr88h7b"
                    fill="#FFF"
                    className="animable"
                ></path>
                <path
                    d="M316.75 173.73a.82.82 0 01-.64 1 .85.85 0 01-1-.65.83.83 0 111.63-.33z"
                    style={{
                        WebkitTransformOrigin: 315.933,
                        MsTransformOrigin: 315.933,
                        transformOrigin: 315.933,
                    }}
                    id="elgerxunwse5v"
                    fill="#FFF"
                    className="animable"
                ></path>
                <path
                    d="M317.89 179.88a.82.82 0 01-.64 1 .83.83 0 01-1-.64.85.85 0 01.65-1 .83.83 0 01.99.64z"
                    style={{
                        WebkitTransformOrigin: 317.073,
                        MsTransformOrigin: 317.073,
                        transformOrigin: 317.073,
                    }}
                    id="elg4n7civslmo"
                    fill="#FFF"
                    className="animable"
                ></path>
                <path
                    d="M308.49 175.58a.82.82 0 01-.64 1 .85.85 0 01-1-.65.83.83 0 011.63-.33z"
                    style={{
                        WebkitTransformOrigin: 307.673,
                        MsTransformOrigin: 307.673,
                        transformOrigin: 307.673,
                    }}
                    id="eli6dc5u2xhn"
                    fill="#FFF"
                    className="animable"
                ></path>
                <path
                    d="M306.49 185.94a.85.85 0 01-.65 1 .83.83 0 11.65-1z"
                    style={{
                        WebkitTransformOrigin: 305.679,
                        MsTransformOrigin: 305.679,
                        transformOrigin: 305.679,
                    }}
                    id="el1jintbme0gh"
                    fill="#FFF"
                    className="animable"
                ></path>
                <path
                    d="M312 171a.83.83 0 11-1-.64.82.82 0 011 .64z"
                    style={{
                        WebkitTransformOrigin: 311.187,
                        MsTransformOrigin: 311.187,
                        transformOrigin: 311.187,
                    }}
                    id="elssl8dg5regf"
                    fill="#FFF"
                    className="animable"
                ></path>
                <path
                    d="M313.42 177.64a.83.83 0 01-.65 1 .82.82 0 01-1-.64.85.85 0 01.65-1 .83.83 0 011 .64z"
                    style={{
                        WebkitTransformOrigin: 312.597,
                        MsTransformOrigin: 312.597,
                        transformOrigin: 312.597,
                    }}
                    id="el2j30i7ziovf"
                    fill="#FFF"
                    className="animable"
                ></path>
                <path
                    d="M314.78 184.24a.83.83 0 11-1-.65.83.83 0 011 .65z"
                    style={{
                        WebkitTransformOrigin: 313.965,
                        MsTransformOrigin: 313.965,
                        transformOrigin: 313.965,
                    }}
                    id="el2vts3shh3gv"
                    fill="#FFF"
                    className="animable"
                ></path>
                <path
                    d="M319.55 186.44a.83.83 0 01-.65 1 .82.82 0 01-1-.64.85.85 0 01.65-1 .83.83 0 011 .64z"
                    style={{
                        WebkitTransformOrigin: 318.727,
                        MsTransformOrigin: 318.727,
                        transformOrigin: 318.727,
                    }}
                    id="elx112pc2c8aj"
                    fill="#FFF"
                    className="animable"
                ></path>
                <path
                    d="M305.11 179.75a.85.85 0 01-.65 1 .83.83 0 01-.33-1.63.82.82 0 01.98.63z"
                    style={{
                        WebkitTransformOrigin: 304.295,
                        MsTransformOrigin: 304.295,
                        transformOrigin: 304.295,
                    }}
                    id="elx1eepp3p0se"
                    fill="#FFF"
                    className="animable"
                ></path>
                <path
                    d="M309.79 181.85a.83.83 0 11-1.63.33.82.82 0 01.64-1 .85.85 0 01.99.67z"
                    style={{
                        WebkitTransformOrigin: 308.972,
                        MsTransformOrigin: 308.972,
                        transformOrigin: 308.972,
                    }}
                    id="elpd6wc6kswi"
                    fill="#FFF"
                    className="animable"
                ></path>
                <path
                    d="M322.8 182.59a.83.83 0 11-1-.65.83.83 0 011 .65z"
                    style={{
                        WebkitTransformOrigin: 321.985,
                        MsTransformOrigin: 321.985,
                        transformOrigin: 321.985,
                    }}
                    id="el9tp5c4ng7rn"
                    fill="#FFF"
                    className="animable"
                ></path>
                <path
                    d="M311.29 188.21a.83.83 0 01-.65 1 .82.82 0 01-1-.64.83.83 0 01.64-1 .85.85 0 011.01.64z"
                    style={{
                        WebkitTransformOrigin: 310.465,
                        MsTransformOrigin: 310.465,
                        transformOrigin: 310.465,
                    }}
                    id="el2zavuspa1oo"
                    fill="#FFF"
                    className="animable"
                ></path>
                <path
                    d="M320.25 169.57a.83.83 0 11-1-.65.83.83 0 011 .65z"
                    style={{
                        WebkitTransformOrigin: 319.435,
                        MsTransformOrigin: 319.435,
                        transformOrigin: 319.435,
                    }}
                    id="el75syio626lg"
                    fill="#FFF"
                    className="animable"
                ></path>
                <path
                    d="M321.57 176a.85.85 0 01-.65 1 .83.83 0 01-1-.65.82.82 0 01.64-1 .83.83 0 011.01.65z"
                    style={{
                        WebkitTransformOrigin: 320.742,
                        MsTransformOrigin: 320.742,
                        transformOrigin: 320.742,
                    }}
                    id="el8inxqbibjsi"
                    fill="#FFF"
                    className="animable"
                ></path>
                <path
                    d="M315.55 167a.83.83 0 01-.65 1 .82.82 0 01-1-.64.85.85 0 01.65-1 .83.83 0 011 .64z"
                    style={{
                        WebkitTransformOrigin: 314.727,
                        MsTransformOrigin: 314.727,
                        transformOrigin: 314.727,
                    }}
                    id="elxep77yyt6eh"
                    fill="#FFF"
                    className="animable"
                ></path>
                <path
                    d="M319.12 162a.83.83 0 01-1.62.34.83.83 0 111.62-.34z"
                    style={{
                        WebkitTransformOrigin: 318.297,
                        MsTransformOrigin: 318.297,
                        transformOrigin: 318.297,
                    }}
                    id="el5p2uepvkxs"
                    fill="#FFF"
                    className="animable"
                ></path>
                <path
                    d="M304.87 155.27c-.72 3.5 3.81 2.35 3.81 2.35s-6.13 3.23-5.48 7.13 6.08 2.17 6.47 2.06c-.33.17-5.88 3.29-4.83 7.14 1.11 4 6.29 1.47 6.62 1.31-.25.26-3.54 3.75-2.08 6.49 2.53 4.71 11.19-4 15.52-3.82 0 0 .54 7.36.6 7.57 0 0 8.42-8.11 8.32-13.95a3.75 3.75 0 00-2.93-3.65s3-2.31 2.27-5.37c-.39-1.57-3.24-2.35-3.24-2.35s2.33-2 1.51-4.63c-.66-2.14-3.43-2.55-3.43-2.55s1.36-2.91 0-4.88c-2.76-4.02-21.58-.34-23.13 7.15z"
                    style={{
                        WebkitTransformOrigin: 318.486,
                        MsTransformOrigin: 318.486,
                        transformOrigin: 318.486,
                    }}
                    id="el8acplmycv5w"
                    fill="#AD6359"
                    className="animable"
                ></path>
                <path
                    d="M327.59 152.82c.07 0 .07-.1 0-.1a33.21 33.21 0 00-19.16 4.94c-.18.11-.06.46.16.36a68.1 68.1 0 0119-5.2z"
                    style={{
                        WebkitTransformOrigin: 317.993,
                        MsTransformOrigin: 317.993,
                        transformOrigin: 317.993,
                    }}
                    id="eled14oqu3ith"
                    fill="#263238"
                    className="animable"
                ></path>
                <path
                    d="M330 160.13c.08 0 .06-.14 0-.14-6.68-.27-14.76 1.82-20.56 6.69-.2.16-.21.51 0 .34 4.13-2.95 14.41-6.23 20.56-6.89z"
                    style={{
                        WebkitTransformOrigin: 319.67,
                        MsTransformOrigin: 319.67,
                        transformOrigin: 319.67,
                    }}
                    id="elufgup1aetgr"
                    fill="#263238"
                    className="animable"
                ></path>
                <path
                    d="M311.17 175.74c5.75-4.46 12.35-6.62 19.45-7.87.09 0 .06-.14 0-.15-3.64-.33-8.08.93-11.45 2.21a19.14 19.14 0 00-8.13 5.61c-.11.1.01.29.13.2z"
                    style={{
                        WebkitTransformOrigin: 320.837,
                        MsTransformOrigin: 320.837,
                        transformOrigin: 320.837,
                    }}
                    id="el21heuuycqw5i"
                    fill="#263238"
                    className="animable"
                ></path>
                <path
                    d="M308.68 157.62a22.15 22.15 0 00-3.13 2.48 7.27 7.27 0 00-2.11 3.18 2.42 2.42 0 00.16 1.72 3.58 3.58 0 001.19 1.31 4.28 4.28 0 003.47.28l2.8-.86-2.33 1.79a11.92 11.92 0 00-2.69 2.8 4.25 4.25 0 00-.82 3.41 2.44 2.44 0 002.36 2 7.42 7.42 0 003.59-.95l3.09-1.59-2.38 2.5a11.49 11.49 0 00-1.71 2.32 4.62 4.62 0 00-.66 2.59 2.2 2.2 0 001.38 1.87 4.86 4.86 0 002.73-.15 38 38 0 005.54-2.35c.92-.44 1.84-.88 2.78-1.28a7.3 7.3 0 013-.73 7.83 7.83 0 00-2.84 1c-.9.46-1.78 1-2.66 1.47a31.93 31.93 0 01-5.54 2.7 5.66 5.66 0 01-3.26.29 3.13 3.13 0 01-2.15-2.68 5.72 5.72 0 01.74-3.24 12.53 12.53 0 011.86-2.61l.7.91a8.41 8.41 0 01-4.23 1.16 3.54 3.54 0 01-2.25-.86 3.94 3.94 0 01-1.19-2.05 5.37 5.37 0 011-4.31 12.78 12.78 0 013-3l.46.92a5.07 5.07 0 01-4.27-.57 4.23 4.23 0 01-1.38-1.7 3 3 0 01-.12-2.2 7.49 7.49 0 012.49-3.27 17.68 17.68 0 013.38-2.3z"
                    style={{
                        WebkitTransformOrigin: 313.798,
                        MsTransformOrigin: 313.798,
                        transformOrigin: 313.798,
                    }}
                    id="elzjkd6119kgf"
                    fill="#263238"
                    className="animable"
                ></path>
                <path
                    d="M289.36 170.3s8.74-27.25 17.72-18.62c2.67 2.57 10.56 16.29 7.84 17.93-3.52 2.12-7.89-3-10.11-6.64 0 0-1.67 9.69-8.76 10.54a7.11 7.11 0 01-6.69-3.21z"
                    style={{
                        WebkitTransformOrigin: 302.421,
                        MsTransformOrigin: 302.421,
                        transformOrigin: 302.421,
                    }}
                    id="elpn8o90p2lwl"
                    fill="#AD6359"
                    className="animable"
                ></path>
                <path
                    d="M306.29 151a15 15 0 013.06 3.11 33.89 33.89 0 012.43 3.64 50.17 50.17 0 013.82 7.91 7.7 7.7 0 01.51 2.27 3.19 3.19 0 01-.19 1.31 2.44 2.44 0 01-.38.64 2.32 2.32 0 01-.55.46 3.71 3.71 0 01-2.52.43 6.86 6.86 0 01-2.22-.87 13.39 13.39 0 01-3.35-3 30 30 0 01-2.53-3.64l.94-.19a17.88 17.88 0 01-1 3.21 16.92 16.92 0 01-1.57 2.94 11.16 11.16 0 01-2.26 2.44 8.61 8.61 0 01-2.9 1.53 9.73 9.73 0 002.67-1.8 12.16 12.16 0 001.95-2.51 16.46 16.46 0 001.34-2.89 17 17 0 00.76-3.07l.19-1.29.75 1.1a31.89 31.89 0 002.54 3.42 12.9 12.9 0 003.08 2.75c1.13.66 2.54 1 3.49.45.88-.44.58-2 .16-3.3a41.48 41.48 0 00-1.56-4c-.58-1.32-1.17-2.63-1.83-3.91a27.8 27.8 0 00-4.83-7.14z"
                    style={{
                        WebkitTransformOrigin: 306.848,
                        MsTransformOrigin: 306.848,
                        transformOrigin: 306.848,
                    }}
                    id="elrqpfxmn88t"
                    fill="#263238"
                    className="animable"
                ></path>
                <path
                    d="M131.61 444.22c-1-.14-18.52-1.76-19.83-3.31-13.19-15.68-29.4-54.24-29.58-56.09-.13-1.16 17.72-12.06 17.81-12.13 1.91-1.59 37.17-25.29 37.17-25.29l26.7 35.81-36.43 23.88-.6.45a113.4 113.4 0 001.69 19.9c.26 1.34 5.09 7.65 5.31 8.76.75 3.69-.85 8.21-2.24 8.02z"
                    style={{
                        WebkitTransformOrigin: 123.04,
                        MsTransformOrigin: 123.04,
                        transformOrigin: 123.04,
                    }}
                    id="elxmz6ppv353k"
                    fill="#AD6359"
                    className="animable"
                ></path>
                <path
                    d="M131.62 444.21c-1-.14-18.53-1.75-19.84-3.31-13.19-15.67-29.4-54.23-29.58-56.09-.13-1.16 17.71-12 17.81-12.12.47-.39 3-2.13 6.47-4.53l26.16 35.53-5.2 3.41-.59.44a113.54 113.54 0 001.69 19.91c.26 1.34 5.09 7.64 5.31 8.75.75 3.68-.85 8.21-2.23 8.01z"
                    style={{
                        WebkitTransformOrigin: 108.12,
                        MsTransformOrigin: 108.12,
                        transformOrigin: 108.12,
                    }}
                    id="elof8lm658x4"
                    fill="#EBEBEB"
                    className="animable"
                ></path>
                <path
                    d="M132.25 444.58c-.61.3-19.8-2.27-20.91-3.59-10.55-12.64-30.19-54.51-30.15-56.73 0-1.16 9.68-7.7 20.08-12.89l25.82 36.26s.1 12.8 1.58 19.86c.34 1.61 5.14 7.43 5.48 8.61 1.05 3.64-1.01 8.04-1.9 8.48z"
                    style={{
                        WebkitTransformOrigin: 107.816,
                        MsTransformOrigin: 107.816,
                        transformOrigin: 107.816,
                    }}
                    id="elpel6anuuxna"
                    fill="#FFC727"
                    className="animable"
                ></path>
                <path
                    d="M132.23 434.34a11.67 11.67 0 00-8.53-2.83 9.93 9.93 0 00-6.88 3.44c0 .06.07.16.13.11 3.89-3.47 10.62-3.81 15.17-.59a.09.09 0 00.11-.13z"
                    style={{
                        WebkitTransformOrigin: 124.531,
                        MsTransformOrigin: 124.531,
                        transformOrigin: 124.531,
                    }}
                    id="el4rrjix21xek"
                    fill="#263238"
                    className="animable"
                ></path>
                <path
                    d="M98.21 383.39c-2.65-4.37-9.45-.25-6.79 4.13s9.45.25 6.79-4.13z"
                    style={{
                        WebkitTransformOrigin: 94.8153,
                        MsTransformOrigin: 94.8153,
                        transformOrigin: 94.8153,
                    }}
                    id="ellvpgvaixs0k"
                    fill="#263238"
                    className="animable"
                ></path>
                <path
                    d="M140.53 410.24c.55-2.95-2.51-4.57-4.92-4.85a24 24 0 00-9.79 1.11 13.29 13.29 0 00-1.3.46l-.54.22-.13.08-.44.19a.13.13 0 000 .2.56.56 0 00-.15.61.4.4 0 00.22.28c1 .48 2 1 3 1.52.17.1.33.18.51.27a24.77 24.77 0 007.62 2.85c2.24.35 5.39-.18 5.92-2.94zm-11.17-.16c-.85-.39-1.7-.81-2.54-1.2s-1.43-.68-2.16-1l-.3-.13-.28-.12.28-.06c.62-.11 1.23-.21 1.84-.33a44.13 44.13 0 015-.66 25.42 25.42 0 013.49-.06c1.79.14 7.3 2.19 3.18 5-2.21 1.54-6.42-.52-8.51-1.44z"
                    style={{
                        WebkitTransformOrigin: 131.91,
                        MsTransformOrigin: 131.91,
                        transformOrigin: 131.91,
                    }}
                    id="el7usvap7nhpk"
                    fill="#263238"
                    className="animable"
                ></path>
                <path
                    d="M124.12 408.19c.07 0 .14 0 .18-.09a2.33 2.33 0 00.06-.35.23.23 0 000-.14 2.74 2.74 0 00.12-.61 17.55 17.55 0 00.29-2.28 24.1 24.1 0 00-1-8.94c-.76-2.32-3-5-5.75-3.83a2.49 2.49 0 00-.28.15c-2.32 1.18-2.15 4.23-1.37 6.34a23 23 0 003.69 6c1 1.29 2.11 2.55 3.12 3.82a.56.56 0 00.94-.07zm-3.6-5.36c-1.39-1.86-4.23-5.57-3.19-8.1a3.77 3.77 0 011.06-1.57c1.84-1.44 4.06 2.23 4.48 3.67a26.7 26.7 0 01.69 3.43c.12.87.2 1.74.24 2.63.1 1.43.12 2.85.18 4.29a2.44 2.44 0 000 .27 1 1 0 00-.15-.21c-.13-.19-.25-.37-.39-.55-.94-1.3-1.96-2.56-2.92-3.86z"
                    style={{
                        WebkitTransformOrigin: 120.344,
                        MsTransformOrigin: 120.344,
                        transformOrigin: 120.344,
                    }}
                    id="elcg2d7w6k13q"
                    fill="#263238"
                    className="animable"
                ></path>
                <path
                    d="M128.15 413c-2.17-4-6.14-7.83-10.78-8.49-.18 0-.21.23-.09.31a63.15 63.15 0 0110.42 8.52.28.28 0 00.45-.34z"
                    style={{
                        WebkitTransformOrigin: 122.708,
                        MsTransformOrigin: 122.708,
                        transformOrigin: 122.708,
                    }}
                    id="elscoh4cwbii"
                    fill="#263238"
                    className="animable"
                ></path>
                <path
                    d="M128.67 416.76c-2.17-4-6.15-7.84-10.78-8.49-.18 0-.21.23-.09.31a63.47 63.47 0 0110.42 8.52.28.28 0 00.45-.34z"
                    style={{
                        WebkitTransformOrigin: 123.228,
                        MsTransformOrigin: 123.228,
                        transformOrigin: 123.228,
                    }}
                    id="eltzc5fz5pqpf"
                    fill="#263238"
                    className="animable"
                ></path>
                <path
                    d="M129.19 420.5c-2.17-4-6.15-7.84-10.78-8.49-.18 0-.22.23-.09.31a63 63 0 0110.41 8.52.29.29 0 00.46-.34z"
                    style={{
                        WebkitTransformOrigin: 123.733,
                        MsTransformOrigin: 123.733,
                        transformOrigin: 123.733,
                    }}
                    id="elug21xjnexmb"
                    fill="#263238"
                    className="animable"
                ></path>
                <path
                    d="M129.7 424.24c-2.16-4-6.14-7.84-10.78-8.49-.18 0-.21.23-.08.31a63 63 0 0110.41 8.52.28.28 0 00.45-.34z"
                    style={{
                        WebkitTransformOrigin: 124.259,
                        MsTransformOrigin: 124.259,
                        transformOrigin: 124.259,
                    }}
                    id="elq4yausl1w2g"
                    fill="#263238"
                    className="animable"
                ></path>
                <path
                    d="M132.78 442c-5.16-1.45-10.56-2.1-15.77-3.37l-1.82-.44a2.77 2.77 0 01-1.78-.82 29.13 29.13 0 01-1.93-3.35q-1.94-3.44-3.85-6.87-7.71-13.81-15.07-27.81c-1.39-2.63-2.8-5.25-4.2-7.88s-3-5.18-4.38-7.81c0-.09-.2 0-.15.07 1.15 2.45 2.15 5 3.35 7.4s2.39 4.86 3.63 7.27q3.69 7.19 7.53 14.28t7.77 14.15l4 7.08c.65 1.14 1.29 2.28 2 3.42a3.23 3.23 0 002.23 1.76c6 1.46 12.28 2.19 18.36 3.36.22.03.3-.39.08-.44z"
                    style={{
                        WebkitTransformOrigin: 108.366,
                        MsTransformOrigin: 108.366,
                        transformOrigin: 108.366,
                    }}
                    id="eluk0j6ncqio"
                    fill="#263238"
                    className="animable"
                ></path>
                <path
                    d="M260.05 193.84s3.73 31.89-2.85 39.15c0 0-22.3 78-28.11 95.43-6.39 19.15-83.93 67.14-83.93 67.14l-26-36s17.6-14.76 35.45-28c12.92-9.57 28.07-15.95 28.17-16.69 1.32-10.33 24.24-75.38 23.61-77.14-7.27-20.11 4.49-43.92 4.49-43.92z"
                    style={{
                        WebkitTransformOrigin: 190.132,
                        MsTransformOrigin: 190.132,
                        transformOrigin: 190.132,
                    }}
                    id="elt19xmek652p"
                    fill="#263238"
                    className="animable"
                ></path>
                <path
                    d="M127.52 368.16c9.65-9.69 60.93-47.52 60.93-47.52l.47-.43.23-.22.11-.1v-.05c0-.08 0-.06.12-.19l.24-.47a11.08 11.08 0 00.49-1c1.32-2.95 2.45-6.2 3.56-9.39s2.16-6.44 3.21-9.68c2.07-6.48 4.16-13 6.16-19.49l12.09-39.12.06 1a35.55 35.55 0 01-1.48-6.12l-.23-1.53-.15-1.52c-.13-1-.16-2-.22-3.06a68.4 68.4 0 01.59-12.15c.33-2 .62-4 1.09-6s1-3.91 1.52-5.84c.25-1 .59-1.92.92-2.87l1-2.83c.74-1.87 1.54-3.7 2.31-5.55-.58 1.92-1.2 3.81-1.74 5.73s-1.05 3.84-1.42 5.79-.8 3.89-1 5.85-.49 3.92-.68 5.88a73.25 73.25 0 00.1 11.76c.11 1 .19 1.94.36 2.9l.22 1.44.29 1.43a33.81 33.81 0 001.52 5.44l.21.51-.15.54c-1.75 6.61-3.53 13.2-5.41 19.77s-3.73 13.15-5.72 19.69-4 13.1-6.1 19.6c-1.07 3.25-2.16 6.5-3.31 9.74s-2.28 6.46-3.78 9.74c-.21.42-.37.81-.64 1.26l-.37.66a8.12 8.12 0 01-.64.83l-.18.21-.12.11-.24.22-.48.42s-53.15 35.96-63.74 44.61z"
                    style={{
                        WebkitTransformOrigin: 174.03,
                        MsTransformOrigin: 174.03,
                        transformOrigin: 174.03,
                    }}
                    id="elbc5zdnz5qz6"
                    fill="#455A64"
                    className="animable"
                ></path>
                <path
                    d="M257.2 233s-6.59 23.05-13.54 46.89l-37.3-42.13v-.11c-7.19-20.08 4.53-43.8 4.53-43.8h51s1.88 31.88-4.69 39.15z"
                    style={{
                        WebkitTransformOrigin: 233.083,
                        MsTransformOrigin: 233.083,
                        transformOrigin: 233.083,
                    }}
                    id="elkkol8tcu5c"
                    fill="#37474F"
                    className="animable"
                ></path>
                <path
                    d="M418.31 418.76c-1.57 2-58.89 38.93-62 38.94-1.17 0-29.41-52.16-29.41-52.16l36-20.17 17.19 30.35s27.5-4.21 30.89-3.88 8.9 4.96 7.33 6.92z"
                    style={{
                        WebkitTransformOrigin: 372.743,
                        MsTransformOrigin: 372.743,
                        transformOrigin: 372.743,
                    }}
                    id="elyuifwwwcsgb"
                    fill="#AD6359"
                    className="animable"
                ></path>
                <path
                    d="M418.3 418.76c-1.56 2-58.89 38.93-62 38.95-.56 0-7.86-11.64-14.36-24.67l35.7-23.24 2.47 5.92s27.49-4.21 30.88-3.88 8.89 4.97 7.31 6.92z"
                    style={{
                        WebkitTransformOrigin: 380.259,
                        MsTransformOrigin: 380.259,
                        transformOrigin: 380.259,
                    }}
                    id="elm7tjjuggmr"
                    fill="#EBEBEB"
                    className="animable"
                ></path>
                <path
                    d="M418.53 418.57c-1.56 1.95-59 39.64-62.11 39.67-1.17 0-6.27-9.76-11.77-20l35.82-23s27.45-3.87 30.81-3.54 8.82 4.91 7.25 6.87z"
                    style={{
                        WebkitTransformOrigin: 381.729,
                        MsTransformOrigin: 381.729,
                        transformOrigin: 381.729,
                    }}
                    id="elvsac55krsce"
                    fill="#FFC727"
                    className="animable"
                ></path>
                <path
                    d="M416.24 416.81c-9.71 5.94-48.34 30.81-57.5 37.41-.07.06 0 .11 0 .06 9.76-5.67 48.19-30.85 57.56-37.31.24-.14.15-.28-.06-.16z"
                    style={{
                        WebkitTransformOrigin: 387.576,
                        MsTransformOrigin: 387.576,
                        transformOrigin: 387.576,
                    }}
                    id="eld4fyo0cfxr"
                    fill="#263238"
                    className="animable"
                ></path>
                <path
                    d="M406.29 412a9.16 9.16 0 00-4.22 6 8.84 8.84 0 001.16 6.48c0 .06.14 0 .11-.07a10.59 10.59 0 013-12.32c.1-.03.03-.09-.05-.09z"
                    style={{
                        WebkitTransformOrigin: 404.138,
                        MsTransformOrigin: 404.138,
                        transformOrigin: 404.138,
                    }}
                    id="el0nb3nbkgbno"
                    fill="#263238"
                    className="animable"
                ></path>
                <path
                    d="M384.09 414.24c-3.86 2.42-7.43 6.63-7.79 11.3 0 .18.25.2.32.06a62.84 62.84 0 017.84-10.93.29.29 0 00-.37-.43z"
                    style={{
                        WebkitTransformOrigin: 380.41,
                        MsTransformOrigin: 380.41,
                        transformOrigin: 380.41,
                    }}
                    id="el9fbyx1blp3g"
                    fill="#263238"
                    className="animable"
                ></path>
                <path
                    d="M387.79 413.48c-3.86 2.42-7.43 6.64-7.79 11.3 0 .18.24.2.32.07a62.92 62.92 0 017.84-10.94.29.29 0 00-.37-.43z"
                    style={{
                        WebkitTransformOrigin: 384.11,
                        MsTransformOrigin: 384.11,
                        transformOrigin: 384.11,
                    }}
                    id="eldlpmlestder"
                    fill="#263238"
                    className="animable"
                ></path>
                <path
                    d="M391.49 412.73c-3.87 2.42-7.43 6.63-7.79 11.3 0 .18.24.19.32.06a62.84 62.84 0 017.84-10.93.29.29 0 00-.37-.43z"
                    style={{
                        WebkitTransformOrigin: 387.81,
                        MsTransformOrigin: 387.81,
                        transformOrigin: 387.81,
                    }}
                    id="elubjdthnwlui"
                    fill="#263238"
                    className="animable"
                ></path>
                <path
                    d="M395.19 412c-3.87 2.42-7.43 6.64-7.79 11.3 0 .18.24.2.32.07a62.92 62.92 0 017.84-10.94.29.29 0 00-.37-.43z"
                    style={{
                        WebkitTransformOrigin: 391.51,
                        MsTransformOrigin: 391.51,
                        transformOrigin: 391.51,
                    }}
                    id="eljxwrm6tqkye"
                    fill="#263238"
                    className="animable"
                ></path>
                <path
                    d="M355.32 443.57c-4.29 2.78 0 9.45 4.32 6.66s-.03-9.45-4.32-6.66z"
                    style={{
                        WebkitTransformOrigin: 357.484,
                        MsTransformOrigin: 357.484,
                        transformOrigin: 357.484,
                    }}
                    id="elfxwptpu771e"
                    fill="#263238"
                    className="animable"
                ></path>
                <path
                    d="M381.64 401.35c-3-.36-4.41 2.8-4.53 5.23a24.15 24.15 0 001.73 9.69c.17.44.35.86.54 1.27.1.17.18.35.26.52l.08.13.22.42a.12.12 0 00.19 0 .59.59 0 00.63.12.43.43 0 00.26-.24c.41-1 .87-2 1.32-3.06.09-.17.16-.34.25-.52a24.68 24.68 0 002.35-7.79c.21-2.3-.49-5.43-3.3-5.77zm.55 11.16c-.34.88-.7 1.75-1 2.61s-.58 1.48-.86 2.22c0 .1-.08.22-.11.31l-.1.28c0-.09-.05-.18-.07-.28-.14-.61-.28-1.21-.44-1.82a43.18 43.18 0 01-1-4.94 27 27 0 01-.27-3.48c0-1.8 1.72-7.42 4.78-3.5 1.65 2.09-.12 6.46-.93 8.6z"
                    style={{
                        WebkitTransformOrigin: 381.037,
                        MsTransformOrigin: 381.037,
                        transformOrigin: 381.037,
                    }}
                    id="el0w4ckzvspoor"
                    fill="#263238"
                    className="animable"
                ></path>
                <path
                    d="M380.63 417.86a.12.12 0 00-.09-.17 1.59 1.59 0 00-.36 0 .16.16 0 00-.14 0 2.7 2.7 0 00-.62-.08 14.67 14.67 0 00-2.29-.14 23.8 23.8 0 00-8.85 1.56c-2.27.91-4.77 3.29-3.46 6 .05.1.11.19.16.28 1.33 2.24 4.36 1.87 6.42 1a23.12 23.12 0 005.8-4.07c1.22-1.11 2.4-2.27 3.61-3.35a.55.55 0 00-.18-1.03zm-5.11 3.94c-1.77 1.5-5.29 4.57-7.88 3.69a3.73 3.73 0 01-1.63-.95c-1.56-1.75 2-4.2 3.38-4.71a25.73 25.73 0 013.37-.9c.86-.19 1.73-.31 2.6-.42 1.43-.18 2.85-.29 4.28-.45h.27a1.63 1.63 0 00-.2.17l-.53.42c-1.18 1.01-2.42 2.1-3.66 3.15z"
                    style={{
                        WebkitTransformOrigin: 372.78,
                        MsTransformOrigin: 372.78,
                        transformOrigin: 372.78,
                    }}
                    id="elyahonr25vvg"
                    fill="#263238"
                    className="animable"
                ></path>
                <path
                    d="M365.81 390.39L330 411.18s-21.81-25.38-31.13-54.78c-6.08-19.2-9.86-36.41-10.64-37.71-2.58-4.31-64.3-63.69-78.64-79.31-18.87-20.54.15-45.57.15-45.57h52.12l.26 18.8c4.11 6.63 62 68.17 67.22 83.52 9.06 26.87 36.47 94.26 36.47 94.26z"
                    style={{
                        WebkitTransformOrigin: 283.526,
                        MsTransformOrigin: 283.526,
                        transformOrigin: 283.526,
                    }}
                    id="elw9p24mskwv"
                    fill="#263238"
                    className="animable"
                ></path>
                <path
                    d="M261.67 262.22l-10.29 19.14-9.78-9.65L252.77 251z"
                    style={{
                        WebkitTransformOrigin: 251.635,
                        MsTransformOrigin: 251.635,
                        transformOrigin: 251.635,
                    }}
                    id="elssvdxoabxp"
                    fill="#FFC727"
                    className="animable"
                ></path>
                <path
                    d="M266.94 268.87l-9.8 18.2c-.76-.76-1.53-1.53-2.31-2.29l10-18.59z"
                    style={{
                        WebkitTransformOrigin: 260.885,
                        MsTransformOrigin: 260.885,
                        transformOrigin: 260.885,
                    }}
                    id="el6cssi258nbn"
                    fill="#FFC727"
                    className="animable"
                ></path>
                <path
                    d="M251.28 203.72a44.1 44.1 0 015.72 4.08 44.76 44.76 0 015.1 4.84 43.1 43.1 0 01-5.74-4.07 44.86 44.86 0 01-5.08-4.85z"
                    style={{
                        WebkitTransformOrigin: 256.69,
                        MsTransformOrigin: 256.69,
                        transformOrigin: 256.69,
                    }}
                    id="elx3moexzdhjm"
                    fill="#37474F"
                    className="animable"
                ></path>
                <path
                    d="M263.41 185.6l-1.56 8.24h-51s8-41.79 26.65-54.19c9.34-6.2 17.86-3.48 22 3.47 1.05 1.76 11.72 20.2 11.42 29-.28 7.88-7.51 13.48-7.51 13.48z"
                    style={{
                        WebkitTransformOrigin: 240.888,
                        MsTransformOrigin: 240.888,
                        transformOrigin: 240.888,
                    }}
                    id="elgzwtfz8f0g8"
                    fill="#AD6359"
                    className="animable"
                ></path>
                <path
                    d="M270.93 172.17c-.27 7.31-6.66 12.72-7.45 13.37l-.05.05-49.71-3.35c1.16-4.06 2.66-8.83 4.57-13.74a90.08 90.08 0 0014.79-.67c2.17-.41 7-7.63 13.18-6.9s12.36 6.9 12.36 6.9a20 20 0 0110.1-5.83c1.36 3.8 2.28 7.44 2.21 10.17z"
                    style={{
                        WebkitTransformOrigin: 242.327,
                        MsTransformOrigin: 242.327,
                        transformOrigin: 242.327,
                    }}
                    id="eloa5kjmd9hm"
                    fill="#263238"
                    className="animable"
                ></path>
                <path
                    d="M259.51 143.12a13.53 13.53 0 00-.87-1.31c1.47 4.26 4.25 13.66.67 14.59-3.93 1-13.82-14.34-16.88-19.25a22.18 22.18 0 00-4.92 2.5c-13.6 9-22.37 32.35-26.12 44.39l51.53 4.8.48-3.24s7.24-5.64 7.53-13.43c.3-8.85-10.37-27.29-11.42-29.05zm-19.43 20.45c-2.64 5.71-8.65 10.13-12.65 9.59-3.27-.44-4.8-4.89-3.5-10.55C226.17 153 235.7 138.71 240 139c5 .43 4.46 15.05.08 24.57z"
                    style={{
                        WebkitTransformOrigin: 241.163,
                        MsTransformOrigin: 241.163,
                        transformOrigin: 241.163,
                    }}
                    id="el6xjs03stl7e"
                    fill="#FFC727"
                    className="animable"
                ></path>
                <path
                    d="M246.92 178.67a48.34 48.34 0 018.2 2.16c1.32.5 2.62 1.07 3.92 1.68.64.3 1.29.58 1.95.85a7.9 7.9 0 002.1.62c.07 0 .06.12 0 .12a11.86 11.86 0 01-2.06-.18 12.24 12.24 0 01-1.94-.56c-1.35-.5-2.68-1.06-4-1.57-2.72-1-5.43-2-8.2-2.84-.15-.04-.1-.3.03-.28z"
                    style={{
                        WebkitTransformOrigin: 254.968,
                        MsTransformOrigin: 254.968,
                        transformOrigin: 254.968,
                    }}
                    id="eljqnzhgytucm"
                    fill="#263238"
                    className="animable"
                ></path>
                <path
                    d="M239.84 181.23a50.38 50.38 0 016.95 0 33.6 33.6 0 013.45.46c.54.1 1.09.21 1.63.33s1.18.35 1.78.47c.07 0 .06.13 0 .12-.56-.09-1.15-.07-1.72-.12l-1.73-.16-3.46-.31c-2.3-.24-4.6-.45-6.91-.59-.14.02-.11-.19.01-.2z"
                    style={{
                        WebkitTransformOrigin: 246.718,
                        MsTransformOrigin: 246.718,
                        transformOrigin: 246.718,
                    }}
                    id="elzn3qcragl8r"
                    fill="#263238"
                    className="animable"
                ></path>
                <path
                    d="M274.92 95.37a1.55 1.55 0 01-1.14 2.8 1.64 1.64 0 010 .66 1.71 1.71 0 01-1.9 1.31 1.46 1.46 0 010 .55 1.56 1.56 0 01-2.74.62 1.56 1.56 0 01-1.84 1 1.53 1.53 0 01-.59-.28.59.59 0 010 .14 1.56 1.56 0 01-3 .24 1.53 1.53 0 01-1.75.86 1.56 1.56 0 01-1.15-1.08 1.74 1.74 0 01-1.13.12 1.69 1.69 0 01-1.07-.78 1.74 1.74 0 01-1.29.22 1.7 1.7 0 01-1.32-1.55 1.57 1.57 0 01-.61 0 1.55 1.55 0 01-1.2-1.66 1.77 1.77 0 01-.76 0 1.71 1.71 0 01-1.28-2.06 2 2 0 01.21-.52.89.89 0 01-.27 0 1.56 1.56 0 01-1.17-1.86 1.54 1.54 0 01.41-.74 1.72 1.72 0 01-1.13-2 1.66 1.66 0 01.46-.82 1.73 1.73 0 01-.73-1.82 1.7 1.7 0 011-1.17 1.54 1.54 0 01.53-2.24 1.73 1.73 0 01-.36-1.48 1.71 1.71 0 011.54-1.32 1.58 1.58 0 010-.75 1.55 1.55 0 011.66-1.2 1.5 1.5 0 010-.73 1.72 1.72 0 012.06-1.29 1.49 1.49 0 01.37.14 1.26 1.26 0 010-.27 1.7 1.7 0 012.05-1.28 1.28 1.28 0 01.4.15 1.65 1.65 0 010-.49 1.71 1.71 0 012.06-1.28 1.69 1.69 0 011.09.8 1.68 1.68 0 011.61-.49 1.72 1.72 0 011.05.73 1.56 1.56 0 011.72-.78 1.58 1.58 0 011.13 1 1.55 1.55 0 012.48 1.09 1.71 1.71 0 011.36-.27 1.73 1.73 0 011.32 1.68 1.38 1.38 0 01.57 0 1.56 1.56 0 01.94 2.38h.1a1.71 1.71 0 011.28 2.06 1.83 1.83 0 01-.18.46 1.12 1.12 0 01.41 0 1.55 1.55 0 01.47 2.83 1.56 1.56 0 01.48 1.51 1.6 1.6 0 01-.68 1 1.71 1.71 0 01-.66 3.18 1.52 1.52 0 01.42 1.45 1.56 1.56 0 01-1.23 1.23z"
                    style={{
                        WebkitTransformOrigin: 263.607,
                        MsTransformOrigin: 263.607,
                        transformOrigin: 263.607,
                    }}
                    id="el101yb4h8edh8"
                    fill="#263238"
                    className="animable"
                ></path>
                <path
                    d="M248.33 107.32a1.55 1.55 0 01-1.14 2.8 1.64 1.64 0 010 .66 1.71 1.71 0 01-1.9 1.31 1.46 1.46 0 010 .55 1.56 1.56 0 01-2.74.62 1.55 1.55 0 01-1.83 1 1.47 1.47 0 01-.59-.28v.14a1.55 1.55 0 01-1.87 1.16 1.56 1.56 0 01-1.09-.92 1.53 1.53 0 01-1.75.86 1.57 1.57 0 01-1.15-1.08 1.71 1.71 0 01-2.2-.66 1.74 1.74 0 01-1.29.22 1.7 1.7 0 01-1.32-1.58 1.57 1.57 0 01-.61 0 1.55 1.55 0 01-1.2-1.66 1.77 1.77 0 01-.76 0 1.71 1.71 0 01-1.28-2.06 1.6 1.6 0 01.22-.52.92.92 0 01-.28 0 1.55 1.55 0 01-1.16-1.86 1.52 1.52 0 01.4-.75 1.7 1.7 0 01-1.13-2 1.66 1.66 0 01.46-.82 1.74 1.74 0 01-.73-1.83 1.71 1.71 0 011-1.16 1.52 1.52 0 01-.24-1.23 1.54 1.54 0 01.77-1A1.71 1.71 0 01226 94.4a1.58 1.58 0 010-.75 1.55 1.55 0 011.66-1.2 1.64 1.64 0 010-.73 1.72 1.72 0 012.06-1.29 1.49 1.49 0 01.37.14 1.26 1.26 0 010-.27 1.7 1.7 0 012-1.28 1.28 1.28 0 01.4.15 1.65 1.65 0 010-.49 1.72 1.72 0 012.06-1.28 1.64 1.64 0 011.09.8 1.69 1.69 0 011.62-.49 1.73 1.73 0 011 .73 1.56 1.56 0 011.72-.78 1.58 1.58 0 011.13 1 1.56 1.56 0 012.49 1.09 1.7 1.7 0 011.36-.27 1.73 1.73 0 011.33 1.72 1.38 1.38 0 01.57 0 1.56 1.56 0 01.94 2.38h.1a1.72 1.72 0 011.28 2.07 1.3 1.3 0 01-.18.46 1.2 1.2 0 01.42 0A1.55 1.55 0 01250 99a1.55 1.55 0 01-.2 2.47 1.72 1.72 0 01.81 1.88 1.7 1.7 0 01-1.47 1.31 1.52 1.52 0 01.42 1.45 1.55 1.55 0 01-1.23 1.21z"
                    style={{
                        WebkitTransformOrigin: 237.068,
                        MsTransformOrigin: 237.068,
                        transformOrigin: 237.068,
                    }}
                    id="elxpw45ggg6bk"
                    fill="#263238"
                    className="animable"
                ></path>
                <path
                    d="M246.24 137.77c.19 1.14 5.06 8.72 10.18 8.81 1.66 0 2.21-9.07 2.21-9.07l.19-.66 2.7-9.27-10.59-7.79-1.54-1s-.57 2.59-1.25 5.81c0 .15-.06.3-.1.46l-.12.53c-.1.48-.21 1-.31 1.46s-.15.74-.21 1.13-.14.78-.22 1.17a63.14 63.14 0 00-.94 8.42z"
                    style={{
                        WebkitTransformOrigin: 253.88,
                        MsTransformOrigin: 253.88,
                        transformOrigin: 253.88,
                    }}
                    id="elz9l3zwzt2im"
                    fill="#AD6359"
                    className="animable"
                ></path>
                <path
                    d="M248.14 124.56a19.36 19.36 0 0010.68 12.29l2.7-9.27-10.59-7.79-1.54-1s-.57 2.55-1.25 5.77z"
                    style={{
                        WebkitTransformOrigin: 254.83,
                        MsTransformOrigin: 254.83,
                        transformOrigin: 254.83,
                    }}
                    id="el5oduobuok55"
                    fill="#263238"
                    className="animable"
                ></path>
                <path
                    d="M276.5 112.51c1.65 16.86-8.26 19.75-11.89 20.1-3.3.32-14.58 1-18-15.61s3.94-22.7 11.45-23.83 16.79 2.48 18.44 19.34z"
                    style={{
                        WebkitTransformOrigin: 261.216,
                        MsTransformOrigin: 261.216,
                        transformOrigin: 261.216,
                    }}
                    id="elpiswofnmtjr"
                    fill="#AD6359"
                    className="animable"
                ></path>
                <path
                    d="M273.29 109.77a11.11 11.11 0 01-1.16-.12 2 2 0 01-1.16-.36.6.6 0 01-.07-.73 1.49 1.49 0 011.43-.5 2.27 2.27 0 011.45.64.64.64 0 01-.49 1.07z"
                    style={{
                        WebkitTransformOrigin: 272.377,
                        MsTransformOrigin: 272.377,
                        transformOrigin: 272.377,
                    }}
                    id="elem3us44u40o"
                    fill="#263238"
                    className="animable"
                ></path>
                <path
                    d="M262.22 110.93a11 11 0 001.12-.32 1.93 1.93 0 001.09-.55.59.59 0 00-.06-.73 1.48 1.48 0 00-1.49-.25 2.23 2.23 0 00-1.32.88.64.64 0 00.66.97z"
                    style={{
                        WebkitTransformOrigin: 262.995,
                        MsTransformOrigin: 262.995,
                        transformOrigin: 262.995,
                    }}
                    id="elmojtsu7f62r"
                    fill="#263238"
                    className="animable"
                ></path>
                <path
                    d="M264.82 114.23s-.08.06-.08.11c.18 1.14.19 2.48-.8 3v.07c1.26-.36 1.23-2.13.88-3.18z"
                    style={{
                        WebkitTransformOrigin: 264.484,
                        MsTransformOrigin: 264.484,
                        transformOrigin: 264.484,
                    }}
                    id="elfwg79nn6ssr"
                    fill="#263238"
                    className="animable"
                ></path>
                <path
                    d="M263.65 113.16c-1.86.08-1.55 3.79.17 3.71s1.39-3.78-.17-3.71z"
                    style={{
                        WebkitTransformOrigin: 263.679,
                        MsTransformOrigin: 263.679,
                        transformOrigin: 263.679,
                    }}
                    id="elxh1ugq4rxpb"
                    fill="#263238"
                    className="animable"
                ></path>
                <path
                    d="M262.94 113.41c-.31.24-.59.64-1 .71s-.82-.28-1.15-.63c0 0-.06 0-.06.05.05.71.38 1.42 1.15 1.46s1.15-.63 1.27-1.36c.04-.14-.09-.32-.21-.23z"
                    style={{
                        WebkitTransformOrigin: 261.944,
                        MsTransformOrigin: 261.944,
                        transformOrigin: 261.944,
                    }}
                    id="elzl3p6owp1nl"
                    fill="#263238"
                    className="animable"
                ></path>
                <path
                    d="M271.1 113.3s.1 0 .1.1c.05 1.15.3 2.46 1.37 2.77v.06c-1.29-.08-1.57-1.83-1.47-2.93z"
                    style={{
                        WebkitTransformOrigin: 271.826,
                        MsTransformOrigin: 271.826,
                        transformOrigin: 271.826,
                    }}
                    id="el0yrncwj5kbxo"
                    fill="#263238"
                    className="animable"
                ></path>
                <path
                    d="M272 112c1.84-.28 2.26 3.42.56 3.67s-2.06-3.41-.56-3.67z"
                    style={{
                        WebkitTransformOrigin: 272.344,
                        MsTransformOrigin: 272.344,
                        transformOrigin: 272.344,
                    }}
                    id="elugxre1dyemp"
                    fill="#263238"
                    className="animable"
                ></path>
                <path
                    d="M272.84 112.18c.31.16.64.49 1 .47s.66-.45.87-.87h.06c.1.7 0 1.46-.71 1.68s-1.13-.36-1.38-1c-.04-.13.03-.35.16-.28z"
                    style={{
                        WebkitTransformOrigin: 273.736,
                        MsTransformOrigin: 273.736,
                        transformOrigin: 273.736,
                    }}
                    id="elvcadyn7h5ds"
                    fill="#263238"
                    className="animable"
                ></path>
                <path
                    d="M265.62 123.31c.29.26.58.62 1 .64a3 3 0 001.23-.34v.06a1.56 1.56 0 01-1.44.68 1.23 1.23 0 01-.95-1c.03-.05.12-.08.16-.04z"
                    style={{
                        WebkitTransformOrigin: 266.655,
                        MsTransformOrigin: 266.655,
                        transformOrigin: 266.655,
                    }}
                    id="el723tf9w3w9l"
                    fill="#263238"
                    className="animable"
                ></path>
                <path
                    d="M270 118.7s.4 1.69.48 2.5c0 .08-.19.13-.46.18a3.86 3.86 0 01-3.87-1.08c-.07-.08 0-.17.11-.13a5.74 5.74 0 003.6.68c0-.23-.78-2.88-.65-2.9a7.23 7.23 0 011.88.24c-.73-3.55-2-7-2.7-10.5a.11.11 0 01.21-.07 59.42 59.42 0 013.4 11.18c.09.46-1.63.01-2-.1z"
                    style={{
                        WebkitTransformOrigin: 269.063,
                        MsTransformOrigin: 269.063,
                        transformOrigin: 269.063,
                    }}
                    id="elw3dp69fkais"
                    fill="#263238"
                    className="animable"
                ></path>
                <path
                    d="M269.2 121.24a3.91 3.91 0 01-1.47 1.32 1.7 1.7 0 01-1.05.14c-.79-.14-.86-.84-.76-1.46a3.86 3.86 0 01.27-.94 5.08 5.08 0 003.01.94z"
                    style={{
                        WebkitTransformOrigin: 267.542,
                        MsTransformOrigin: 267.542,
                        transformOrigin: 267.542,
                    }}
                    id="elihfn6xodmr"
                    fill="#263238"
                    className="animable"
                ></path>
                <path
                    d="M267.73 122.56a1.7 1.7 0 01-1.05.14c-.79-.14-.86-.84-.76-1.46a1.81 1.81 0 011.81 1.32z"
                    style={{
                        WebkitTransformOrigin: 266.807,
                        MsTransformOrigin: 266.807,
                        transformOrigin: 266.807,
                    }}
                    id="elzer1a7yj05q"
                    fill="#FF9CBD"
                    className="animable"
                ></path>
                <path
                    d="M263.39 96.82a7.53 7.53 0 01.1 4.44c-1.56 3.95-6.23 3.27-7.7 4.89s-.65 4.41-1.89 5.92c-1.71 2.09-3.06.77-3.28 2.12s-.79 5.6-2.44 6.25S236.86 93.69 258.91 93c0 0 3-1.61 7.78.59 6.8 3.13 8.52 11.7 8.52 11.7a5.47 5.47 0 01-2.15-.8c-1.13-.86-1.7-1.53-2.52-2.08-1.29-.87-2-.14-3.33-1-2.04-1.23-3.82-4.59-3.82-4.59z"
                    style={{
                        WebkitTransformOrigin: 259.932,
                        MsTransformOrigin: 259.932,
                        transformOrigin: 259.932,
                    }}
                    id="elwa43jtfgrc"
                    fill="#263238"
                    className="animable"
                ></path>
                <path
                    d="M263.12 95.9a14.79 14.79 0 01.88 3.32 6.14 6.14 0 01-.24 3.3 4.61 4.61 0 01-2.39 2.14c-1 .46-2.16.65-3.25 1.18a3.39 3.39 0 00-1.4 1.21 4.68 4.68 0 00-.53 1.73 6 6 0 01-1 3.13 3.79 3.79 0 01-2.86 1.45c-1.11.08-2.35-.15-3.4.41 1.09-.47 2.25-.16 3.41-.15a4.14 4.14 0 003.18-1.44 6.13 6.13 0 001.17-3.32 4.15 4.15 0 01.48-1.54 2.89 2.89 0 011.2-1c1-.49 2.14-.71 3.22-1.21a6.17 6.17 0 001.51-1 3.61 3.61 0 001.06-1.46 6.56 6.56 0 00.12-3.51 14.94 14.94 0 00-1.16-3.24z"
                    style={{
                        WebkitTransformOrigin: 256.697,
                        MsTransformOrigin: 256.697,
                        transformOrigin: 256.697,
                    }}
                    id="elmj1c8ggro8g"
                    fill="#263238"
                    className="animable"
                ></path>
                <path
                    d="M263.61 96.87a12.69 12.69 0 001.28 3 5.73 5.73 0 002.38 2.27 8.4 8.4 0 001.56.48 2.63 2.63 0 011.24.71 8 8 0 001.05 1.23 3.57 3.57 0 001.46.72 3.93 3.93 0 01-1.27-.91 7.77 7.77 0 01-.9-1.29 2.79 2.79 0 00-1.43-1 8.56 8.56 0 01-1.48-.48 6 6 0 01-2.24-2 23 23 0 01-1.65-2.73z"
                    style={{
                        WebkitTransformOrigin: 268.095,
                        MsTransformOrigin: 268.095,
                        transformOrigin: 268.095,
                    }}
                    id="elgal72853ag7"
                    fill="#263238"
                    className="animable"
                ></path>
                <path
                    d="M250 117.8s-3.5-5.42-5.95-4.19 0 9 2.83 10.14a2.87 2.87 0 003.89-1.4z"
                    style={{
                        WebkitTransformOrigin: 246.907,
                        MsTransformOrigin: 246.907,
                        transformOrigin: 246.907,
                    }}
                    id="eld1hfsz4uj34"
                    fill="#AD6359"
                    className="animable"
                ></path>
                <path
                    d="M244.82 115.81v.08c1.94.87 2.91 2.74 3.57 4.66A1.59 1.59 0 00246 120a.06.06 0 00.05.11 1.76 1.76 0 011.91.69 8 8 0 01.87 1.6c.09.18.41.1.35-.11.16-2.53-1.66-6.12-4.36-6.48z"
                    style={{
                        WebkitTransformOrigin: 247.005,
                        MsTransformOrigin: 247.005,
                        transformOrigin: 247.005,
                    }}
                    id="el1pgba06d515"
                    fill="#263238"
                    className="animable"
                ></path>
                <path
                    d="M232.94 139c-25.69 5-48.85 22.47-50.62 30.08-2.54 10.92 15.28 40.87 21 52.58 1.77 3.65 25-18.6 23.08-22.49-1.83-3.72-16.23-24.5-16.22-25 0-1.13 22.54-19.42 28.3-24 5.12-4.07 8.43-13.86-5.54-11.17z"
                    style={{
                        WebkitTransformOrigin: 212.484,
                        MsTransformOrigin: 212.484,
                        transformOrigin: 212.484,
                    }}
                    id="elwxfd48crxeg"
                    fill="#AD6359"
                    className="animable"
                ></path>
                <path
                    d="M253.41 228c-1.32 3.09-4.78 2.74-4.78 2.74a7.19 7.19 0 01-.94 4.79c-1.64 2.45-6.21 1-6.21 1a8.8 8.8 0 01-1.79 4.89c-1.8 2.19-6.63 1.34-6.63 1.34a7.75 7.75 0 01-4.28 3.31c-4.81 1.28-10.36-3.91-13.92-7.18s-10.61-15.86-12.2-18.12l20.75-23s20.14 10.09 23.79 14.52c3.07 3.71 7.69 12.25 6.21 15.71z"
                    style={{
                        WebkitTransformOrigin: 228.177,
                        MsTransformOrigin: 228.177,
                        transformOrigin: 228.177,
                    }}
                    id="el44upft3f7fz"
                    fill="#AD6359"
                    className="animable"
                ></path>
                <path
                    d="M248.81 230.24c-2.32-4.75-5.91-8.56-9.27-12.57-.08-.09-.22 0-.14.12 3.39 3.94 6 8.51 9.19 12.59a.13.13 0 00.22-.14z"
                    style={{
                        WebkitTransformOrigin: 244.103,
                        MsTransformOrigin: 244.103,
                        transformOrigin: 244.103,
                    }}
                    id="elgfmzrhjpdv7"
                    fill="#263238"
                    className="animable"
                ></path>
                <path
                    d="M241.68 236.13a14.09 14.09 0 00-2.26-2.8 145.462 145.462 0 00-8.19-8.06c-.09-.08-.22 0-.14.11 1.79 1.81 3.56 3.65 5.27 5.54q1.29 1.41 2.55 2.85a16.19 16.19 0 002.51 2.59.18.18 0 00.26-.23z"
                    style={{
                        WebkitTransformOrigin: 236.382,
                        MsTransformOrigin: 236.382,
                        transformOrigin: 236.382,
                    }}
                    id="el4aho4v4krod"
                    fill="#263238"
                    className="animable"
                ></path>
                <path
                    d="M233 242.66a9.82 9.82 0 00-2.18-2.42l-2.5-2.44c-1.65-1.64-3.31-3.28-4.91-5-.08-.08-.22 0-.14.12 1.62 1.73 3.21 3.48 4.84 5.2.8.84 1.61 1.68 2.42 2.51a8.94 8.94 0 002.39 2.08c.08.06.08-.01.08-.05z"
                    style={{
                        WebkitTransformOrigin: 228.123,
                        MsTransformOrigin: 228.123,
                        transformOrigin: 228.123,
                    }}
                    id="elfdmh5g6q2xn"
                    fill="#263238"
                    className="animable"
                ></path>
                <path
                    d="M219.32 187.18c-.83-1.18-1.68-2.36-2.53-3.53a43.59 43.59 0 00-3-3.51h-.06c.8 1.22 1.51 2.48 2.37 3.67s1.73 2.43 2.56 3.67c1.63 2.45 3.39 4.81 5 7.25 0 .08.19 0 .15-.08a83.85 83.85 0 00-4.49-7.47z"
                    style={{
                        WebkitTransformOrigin: 218.77,
                        MsTransformOrigin: 218.77,
                        transformOrigin: 218.77,
                    }}
                    id="eld07qfr45388"
                    fill="#263238"
                    className="animable"
                ></path>
                <path
                    d="M232.24 156.17l-1.22.64q2.53-2.15 4.89-4.49v-.07c-2 1.5-4 3-6 4.63-.92.76-1.86 1.5-2.75 2.29s-1.95 1.82-3 2.65c-.12.1 0 .3.14.2 1-.8 2.06-1.49 3.09-2.25s1.75-1.37 2.61-2.08c.31-.13.63-.26.93-.42.49-.26 1-.53 1.45-.83.94-.59 1.86-1.21 2.79-1.82-.97.55-1.94 1.06-2.93 1.55z"
                    style={{
                        WebkitTransformOrigin: 230.01,
                        MsTransformOrigin: 230.01,
                        transformOrigin: 230.01,
                    }}
                    id="ele5rj7ofy425"
                    fill="#263238"
                    className="animable"
                ></path>
                <path
                    d="M261.85 193.84a13.17 13.17 0 01.95-5 13.17 13.17 0 01-.95 5z"
                    style={{
                        WebkitTransformOrigin: 262.325,
                        MsTransformOrigin: 262.325,
                        transformOrigin: 262.325,
                    }}
                    id="elyq8twh2h0in"
                    fill="#263238"
                    className="animable"
                ></path>
            </g>
            <defs>
                <filter id="active" height="200%">
                    <feMorphology
                        in="SourceAlpha"
                        operator="dilate"
                        radius="2"
                        result="DILATED"
                    ></feMorphology>
                    <feFlood
                        floodColor="#32DFEC"
                        floodOpacity="1"
                        result="PINK"
                    ></feFlood>
                    <feComposite
                        in="PINK"
                        in2="DILATED"
                        operator="in"
                        result="OUTLINE"
                    ></feComposite>
                    <feMerge>
                        <feMergeNode in="OUTLINE"></feMergeNode>
                        <feMergeNode in="SourceGraphic"></feMergeNode>
                    </feMerge>
                </filter>
                <filter id="hover" height="200%">
                    <feMorphology
                        in="SourceAlpha"
                        operator="dilate"
                        radius="2"
                        result="DILATED"
                    ></feMorphology>
                    <feFlood floodColor="red" floodOpacity="0.5" result="PINK"></feFlood>
                    <feComposite
                        in="PINK"
                        in2="DILATED"
                        operator="in"
                        result="OUTLINE"
                    ></feComposite>
                    <feMerge>
                        <feMergeNode in="OUTLINE"></feMergeNode>
                        <feMergeNode in="SourceGraphic"></feMergeNode>
                    </feMerge>
                    <feColorMatrix values="0 0 0 0 0 0 1 0 0 0 0 0 0 0 0 0 0 0 1 0"></feColorMatrix>
                </filter>
            </defs>
        </svg>
    );
}
