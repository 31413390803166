import Nurse from "./components/Nurse";
import Medical01 from "./components/Medical01";
import Medical02 from "./components/Medical02";
import Running from "./components/Running";
import Hospital from "./components/Hospital";
import {Button} from "@mui/material";
import {useNavigate} from "react-router-dom";
import {user, hasLoggedUser} from "../../storage/RegisterStorage";
import "./styles.scss";

export default function Welcome() {
    const navigate = useNavigate();

    let animations = [
        <Medical01/>,
        <Medical02/>,
        <Nurse/>,
        <Running/>,
        <Hospital/>,
    ];

    let img = animations[Math.floor(Math.random() * animations.length)];

    return (
        <div className={"page-welcome"}>
            <p>
                {hasLoggedUser() && <>Oi <span className={"name"}>{user().name}</span></>}
                <small><br/>Estamos felizes com você no <br/></small>
            </p>
            <p>
                <strong>Pé da Bete</strong>
            </p>

            {hasLoggedUser() === null && <div align={"center"}>
                <Button onClick={() => navigate('/cadastro')} color={"error"} variant={"contained"}>Ainda não tenho conta</Button>
            </div>}
            <br/>

            <div className={"img"}>{img}</div>

        </div>
    )
}